import CampanhaService from './CampanhaService';

const campanhaService = new CampanhaService();

export default class IconeService {

  constructor() {
    this.icone = campanhaService.iconeCorrente();
    this.finalIcone = []
  }
  getIcones() {
    return this.icone;
  }

  load() {
    this.init(this.icone)
  }

  init(icones) {
    let finalIconeObj = []
    let dinamicasFinal = []
    if (icones && icones.icons) {

      for (let i of icones.icons) {
        let objetoFinal = {}
        objetoFinal["type"] = i.dinamica
        switch (i.dinamica) {
        case 'VIDEO':
          objetoFinal["route"] = "video"
          objetoFinal["imageAlt"] = "Atividade video"
          if (i.status === true) {
            objetoFinal["enabledIcon"] = i.url
          } else if (i.status === false) {
            objetoFinal["disabledIcon"] = i.url
          }
          break
        case 'FACT_OR_FAKE':
          objetoFinal["route"] = "fatoFake"
          objetoFinal["imageAlt"] = "Atividade fato ou fake"
          if (i.status === true) {
            objetoFinal["enabledIcon"] = i.url
          } else if (i.status === false) {
            objetoFinal["disabledIcon"] = i.url
          }
          break
        case 'NEW_HABIT':
          objetoFinal["route"] = "newHabit"
          objetoFinal["imageAlt"] = "Atividade Novos Hábitos"
          if (i.status === true) {
            objetoFinal["enabledIcon"] = i.url
          } else if (i.status === false) {
            objetoFinal["disabledIcon"] = i.url
          }
          break
        case 'GAME':
          objetoFinal["route"] = "game"
          objetoFinal["imageAlt"] = "Atividade jogo"
          if (i.status === true) {
            objetoFinal["enabledIcon"] = i.url
          } else if (i.status === false) {
            objetoFinal["disabledIcon"] = i.url
          }
          break
        case 'EXTRA':
          objetoFinal["route"] = "extra"
          objetoFinal["imageAlt"] = "Atividade extra"
          if (i.status === true) {
            objetoFinal["enabledIcon"] = i.url
          } else if (i.status === false) {
            objetoFinal["disabledIcon"] = i.url
          }
          break
        case 'MURAL':
          objetoFinal["route"] = "mural"
          objetoFinal["imageAlt"] = "Atividade mural"
          if (i.status === true) {
            objetoFinal["enabledIcon"] = i.url
          } else if (i.status === false) {
            objetoFinal["disabledIcon"] = i.url
          }
          break
        default:
          console.error("Tipo de dinamica inválida ")
        }
        finalIconeObj.push(objetoFinal)
      }

      let verificaSeJaExiste = []
      for (let i = 0; i < finalIconeObj.length; i++) {

        let typeFirst = finalIconeObj[i].type
        if (verificaSeJaExiste.includes(typeFirst)) {
          continue;
        }
        for (let f = i + 1; f < finalIconeObj.length; f++) {

          let typeSecond = finalIconeObj[f].type

          if (typeFirst === typeSecond) {
            verificaSeJaExiste.push(typeFirst)
            let nObj = { ...finalIconeObj[i], ...finalIconeObj[f] }
            dinamicasFinal.push(nObj)

          }
        }
      }
    }
    this.finalIcone = dinamicasFinal;
  }
}
