import React, { Component } from "react";
import { connect } from 'react-redux';
import GameAgenda from "../../pages/gamer/GameAgenda";
import AtividadeEntity from "../DiaSemanaAgenda/AtividadeEntity";
import activitiesConfig from "../config/activities.json";
import AgendaHeader from './AgendaHeader';
import AtividadeDia from "./AtividadeDia";
import DataAgenda from "./DataAgenda";
import { AgendaDiaStyle, AtividadeDiaStyle } from './style';

class DiaSemanaAgenda extends Component {
  desabilitarButton = false;
  numeroItensPorLinha = 4;
  state = {
    habilitado: false,
    dia: "",
    fatoFake: null,
    video: null,
    game: null,
    extra: null,
    mural: null,
    diaTextual: null,
    novoHabito: null,
    mesTextualVisivel: null,
    mesTextualLeitorTela: null,
    dayOfWeekComplete: null,
    openCloseDynamics: true,
    nome: "",
    games: [],
  };

  constructor(props) {

    super(props);
    let data = new Date(props.dia.day);
    if (props.evento && props.evento.startDate) {
      let dataInicio = props.evento.startDate.split("/")
      let horasInicio = dataInicio[2].split(" ")
      dataInicio[2] = horasInicio[0]
      horasInicio = horasInicio[1].split(":")
    }
    let dataTextual = props.dia.day.replace("00:00:00", "").replace(" ", "");
    let componentesData = dataTextual.split("-");
    let dayOfWeek = props.dia.dayOfWeek

    const dayOfWeekComplete = props.i18n.getNomeDiaSemana(dayOfWeek)

    let mesTextualVisivel = props.i18n.getNomeMesAbreviado(componentesData[1])
    let mesTextualLeitorTela = props.i18n.getNomeMesInteiro(componentesData[1])
    dataTextual = componentesData[2]
    if (isNaN(data.getTime())) {
      data = new Date(props.dia.day.replace("-", "/").replace("-", "/"));
    }
    let diaExibir =
      (data.getDate() < 10 ? "0" + data.getDate() : data.getDate()) +
      "/" +
      (data.getMonth() < 10 ? "0" + data.getMonth() : data.getMonth());

    let enabled = props.diaEnabled
    let sortedActivities = this.sortActivities(

      props.dia.activities,
      enabled,
      dataTextual,
      mesTextualVisivel
    );
    for (let i = 0;i < sortedActivities.length;i++) {
      let element = sortedActivities[i];
      let primeiro = i === 0;
      let ultimo = i === sortedActivities.length - 1;
      let cssPrimeiro = primeiro ? " card-atividades__primeiro" : "";
      let cssUltimo = ultimo ? " card-atividades__ultimo" : "";
      let cssDesabilitado =
        "card-atividades__disabled" + cssPrimeiro + cssUltimo;

      element.disabledCss = cssDesabilitado;

    }

    let activities = []
    activities = sortedActivities

    for (let atividade of activities) {
      let nomeAtividade = "";

      switch (atividade.type) {
      case 'VIDEO':
        nomeAtividade = props.i18n.message("dinamica.video.nome", "Vídeo")
        break;
      case 'FACT_OR_FAKE':
        nomeAtividade = props.i18n.message("dinamica.fatofake.nome", "Fato ou Fake?")
        break;
      case 'NEW_HABIT':
        nomeAtividade = props.i18n.message("dinamica.novoshabitos.nome", "Novos Hábitos")
        break;
      case 'GAME':
        nomeAtividade = props.i18n.message("dinamica.game.nome", "Game")
        break;
      case 'EXTRA':
        nomeAtividade = props.i18n.message("dinamica.extra.nome", "Extra")
        break;
      case 'MURAL':
        nomeAtividade = props.i18n.message("dinamica.mural.nome", "Mural")
        break
      default:
        console.error("Tipo de dinamica inválida " + this.props.type)
        throw new Error("Dinamica inválida " + this.props.type)
      }
      atividade.nome = nomeAtividade;
    }
    activities = activities.map((a) => new AtividadeEntity(a));
    let games = activities.filter((a) => a.type === "GAME");
    activities = activities.filter((a) => a.type !== "GAME");


    this.state = {
      habilitado: enabled,
      dia: diaExibir,
      diaTextual: dataTextual,
      activities: activities,
      games: games,
      mesTextualVisivel: mesTextualVisivel,
      mesTextualLeitorTela: mesTextualLeitorTela,
      dayOfWeekComplete: dayOfWeekComplete,
    };
    this.desabilitarButton = this.props.desabilitarButton;
    this.numeroItensPorLinha = this.props.numeroItensPorLinha || 4;
  }



  sortActivities = (activities, enabled, dia, mesTextual) => {
    let sortedActivities = [];

    if (this.props.icone.finalIcone.length > 0) {
      activities.forEach((atividade) => {

        let act = this.props.icone.finalIcone.find((a) => a.type === atividade.type);
        if (act) {
          act = this.prepareActivity(
            atividade,
            act.type,
            act.route,
            act.disabledIcon,
            act.enabledIcon,
            act.imageAlt,
            enabled,
            dia,
            mesTextual
          );
          sortedActivities.push(act);
        }
      });
    } else {
      activities.forEach((atividade) => {
        let act = activitiesConfig.find((a) => a.type === atividade.type);
        if (act) {
          act = this.prepareActivity(
            atividade,
            act.type,
            act.route,
            act.disabledIcon,
            act.enabledIcon,
            act.imageAlt,
            enabled,
            dia,
            mesTextual
          );
          sortedActivities.push(act);
        }
      });
    }

    return sortedActivities;
  };


  prepareActivity = (
    element,
    name,
    route,
    disabledIcon,
    enabledIcon,
    imageAlt,
    enabled,
    dia,
  ) => {
    element.disabledIcon = disabledIcon;
    element.enabledIcon = enabledIcon;
    element.imageAlt = imageAlt;
    element.enabled = enabled;
    element.size = this.activitySize();
    element.navegar = () =>
      this.props.navegar(
        route,
        this.props.dia.scheduleCode,
        element.uuid,
        this.urlTrofeu(name),
        element.presentation,
        element.presentationCover,
        element.name,
        element.title,
        element.enabledIcon
      );
    element.key = dia + name;
    return element;
  };

  urlTrofeu = (tipo) => {
    let atividades = this.props.dia.activities;
    for (let atividade of atividades) {
      if (tipo === atividade.type) {
        return atividade.trophyEnabled;
      }
    }
  };

  activityChunks = (activities, chunks) => {
    let final = [];
    let n = activities.length;
    for (let i = 0;i < n;i += chunks) {
      final = [...final, activities.slice(i, i + chunks)];
    }
    return final;
  }

  activitySize = () => {
    let length = this.props.dia.activities.length;
    return { width: 100 / length + "%" };
  };

  daySize = () => {
    let length = this.props.dia.activities.length;
    return { width: 20.5 * length + "%" };
  };

  renderDays() {
    for (let i = 0;i < this.state.activities.length;i++) { }
  }

  minSpanSize() {
    let length = this.props.dia.activities.length;
    if (!length || length < 4) {
      let estilos = { display: 'flex', flexDirection: 'column' }
      if (length === 0) {
        estilos.marginBottom = '1vh'
      }
      return estilos
    } else {
      return {}
    }
  }

  openClose = () => {
    this.setState({ openCloseDynamics: !this.state.openCloseDynamics })
  }



  atividadesRender = (items, key, index, desabilitarButton) => {
    let numeroItensPorLinha = this.numeroItensPorLinha;
    let primeiroOuNao = 20
    return (
      <AtividadeDiaStyle
        tamanho={primeiroOuNao * items.length}
      >
        <div
          key={key}
          className={`${this.state.habilitado ? "styled-atividades-dia" : "styled-atividades-dia atividades-dia__disabled"}`}
        >
          {
            items.map(function (el, indice) {
              return (
                <AtividadeDia
                  key={indice}
                  chave={indice}
                  navegar={el.navegar}
                  habilitado={el.enabled && !desabilitarButton}
                  imagemHabilitado={el.enabledIcon}
                  imagemDesabilitado={el.disabledIcon}
                  executado={el.executed}
                  size={{ width: 100 /numeroItensPorLinha  + "%" }}
                  type={el.nome}
                />
              );
            })
          }

        </div>


      </AtividadeDiaStyle>
    )
  }

  render() {
    let totalDeAtividadesExecutadas = 0
    let totalDeAtividades = 0
    for (let atv of this.state.activities) {
      totalDeAtividades++
      if (atv.executed === true) {
        totalDeAtividadesExecutadas++
      }
    }
    let porcentagemAtividadesFeitas = totalDeAtividadesExecutadas * 100 / totalDeAtividades

    let foiTodasAsAtividadesExecutadas = totalDeAtividadesExecutadas === this.state.activities.length
      && totalDeAtividadesExecutadas !== 0 && this.state.activities.length !== 0 ? true : false

    let nomeInteresse = this.props.dia.interest != null ? this.props.dia.interest.name : ""
    let finalActivities = []
    if (this.props.dia.subtitles.length > 0) {
      porcentagemAtividadesFeitas = totalDeAtividadesExecutadas * 100 / this.state.activities.filter(a => a.subtitle.uuid).length
      let subtitles = [...this.props.dia.subtitles];
      let atividadesComAgendasComSubTitulo = subtitles.map((subtitle) => {

        let activitiBySubtitle = {
          subtitle: subtitle,
          activities: this.state.activities.filter((activity) => activity.subtitle.uuid === subtitle.uuid)
        }
        return activitiBySubtitle

      });

      let atividadesComSubTitulos = () => {
        return (
          <div>
            {atividadesComAgendasComSubTitulo.map((activityBySubtitle) => {
              let activitiesResults = this.activityChunks(activityBySubtitle.activities, this.numeroItensPorLinha);
              return (
                <div key={activityBySubtitle.subtitle.subtitle} className="subtitle-content">
                  <h5 className="subtitulo-agenda div-overflow">
                    {activityBySubtitle.subtitle.subtitle}
                  </h5>
                  <div className="">
                    {activitiesResults.map((items, key, index) => (
                      <div key={index}>
                        {this.atividadesRender(items, key, index, this.desabilitarButton)}
                      </div>
                    ))}
                  </div>
                </div>
              );
            })}
          </div>
        );
      }

      finalActivities = () => {
        return (
          <div className="">
            {atividadesComSubTitulos()}
            <div className="agenda-game" >
              <GameAgenda
                games={this.state.games.filter(a => a.subtitle.uuid)}
                atividades={this.state.activities.filter(a => a.subtitle.uuid)}
              ></GameAgenda>
            </div>
          </div>

        )
      }



    } else {
      let activitiesResults = this.activityChunks(this.state.activities, this.numeroItensPorLinha);

      let activities = activitiesResults.map((items, key, index) => {
        return (

          <div className="" key={index}>
            {this.atividadesRender(items, key, index, this.desabilitarButton)}
          </div>
        )
      }

      )
      finalActivities = () => {
        return (
          <div className="">
            {activities}
            <div className="agenda-game" >
              <GameAgenda
                games={this.state.games}
                atividades={this.state.activities}
              ></GameAgenda>
            </div>
          </div>

        )
      }
    }


    if (this.state.openCloseDynamics === undefined) {
      this.setState({ openCloseDynamics: true })
    }
    return (

      <div className="dia-container" style={this.minSpanSize()} key={this.state.dia}>

        <AgendaDiaStyle
          openCloseDynamics={this.state.openCloseDynamics}
          foiTodasAsAtividadesExecutadas={foiTodasAsAtividadesExecutadas}
          habilitado={this.state.habilitado}
        >
          <div className="container-dia-agenda" style={{ display: 'flex' }}>

            <DataAgenda
              foiTodasAsAtividadesExecutadas={foiTodasAsAtividadesExecutadas}
              habilitado={this.state.habilitado}
              dayOfWeekComplete={this.state.dayOfWeekComplete}
              diaTextual={this.state.diaTextual}
              mesTextualVisivel={this.state.mesTextualVisivel}
              mesTextualLeitorTela={this.state.mesTextualLeitorTela}
            />

            <AgendaHeader
              habilitado={this.state.habilitado}
              nome={this.props.dia.nome}
              nomeInteresse={nomeInteresse}
              dayOfWeekComplete={this.state.dayOfWeekComplete}
              openClose={this.openClose}
              openCloseDynamics={this.state.openCloseDynamics}
              fechar={this.props.i18n.message("geral.agendaDiaBtnFechar", "Fechar")}
              verMais={this.props.i18n.message("geral.agendaDiaBtnVerMais", "Ver mais")}
              aguarde={this.props.i18n.message("geral.aguarde", "Aguarde")}
              concluido={this.props.i18n.message("geral.porcentagem.concluido", "Concluído")}
              porcentagemAtividadesFeitas={isNaN(porcentagemAtividadesFeitas.toFixed(0)) ? 0 : porcentagemAtividadesFeitas.toFixed(0)}
            />
          </div>

          {

            this.state.openCloseDynamics === true || this.state.openCloseDynamics === undefined ?
              (
                <div
                  className="final-atividades"
                >
                  {finalActivities()}

                </div>
              ) : ""
          }
        </AgendaDiaStyle>
      </div>
    );
  }
}

const mapStateToProps = function (state) {
  return {
    i18n: state.i18n,
    icone: state.icone
  }
}

export default connect(mapStateToProps)(DiaSemanaAgenda)
