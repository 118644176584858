import React from "react";
import { ButtonInteresseEscolherStyle } from "./style";



function BotaoInteressesEscolher(props) {
  return (
    <ButtonInteresseEscolherStyle 
      onClick={() => props.funcao(props.interesse, "add")}
    >
      <div>
        <span>{props.nome}</span>
      </div>
               
    </ButtonInteresseEscolherStyle>
            
       
  )
}

export default BotaoInteressesEscolher