import React from "react";

import CampanhaService from "../services/CampanhaService";
import CustomRegisterCampaign from "./LoginRegister/CustomRegisterCampaign";
import RegisterCampaign from "./LoginRegister/RegisterCampaign";

function RegisterSelector(props){
  const campanhaService = new CampanhaService();

  const campanhaCorrente = campanhaService.campanhaCorrente();

  const classeDivForm = campanhaCorrente.restrictEligibility ? '' : '';

  return (
    <div>            
      <div className={`${classeDivForm}`}>
        {campanhaCorrente.tipoInscricao !== 'CUSTOMIZADO' ? (
          <RegisterCampaign
            callbackLogin={props.callbackLogin}
            campanha={campanhaCorrente}
            modoLogin={props.modoLogin}
          />
        ) : 
          <CustomRegisterCampaign
            callbackLogin={props.callbackLogin}
            campanha={campanhaCorrente}
            modoLogin={props.modoLogin}
          />}
      </div>
    </div>
  )
}

export default RegisterSelector;