import React, { Component } from "react";
import { connect } from "react-redux";

class PontuacaoUsuario extends Component {
   mensagemUsuarioUm = "";
   mensagemUsuarioDois = "";
   


   componentDidMount(){
     this.mensagemUsuarioUm = this.props.i18n.message("ranking.game.naoParticipouUm", "Você ainda não pontuou no game !");
     this.mensagemUsuarioDois = this.props.i18n.message("ranking.game.naoParticipouDois","Clique em concluir ao finalizar um game para registrar sua pontuação");
   }


   render() {
     return(
       <>
         {this.props.pontuacaoUsuario?.rating !== null && this.props.pontuacaoUsuario?.rating > 0 ? (
           <div className="participante-info">
             <div className="content-one">
               {!this.props.pontuacaoUsuario?.position
                 ?''
                 :<div className="participante-info-posicao"> {this.props.pontuacaoUsuario?.position} </div>
                            
               }
               <div className="participante-info-name">
                 {this.props.pontuacaoUsuario?.name}
                 <span className="participante-info-pts">{this.props.pontuacaoUsuario?.rating} pts</span>  
               </div>
             </div>
             <div className='participante-unidade'>
               <div className={this.props.isParticipante?"participante-info-unidade-isparticipante" :"participante-info-unidade"}>{this.props.pontuacaoUsuario?.unit}</div>
             </div>
           </div>):(<div className="participante-info" >
           <span className="participante-info-pts-msg">{this.mensagemUsuarioUm}</span>
           <br></br>
           {this.mensagemUsuario}
           <span className="participante-info-pts-msg2">{this.mensagemUsuarioDois}</span>
         </div>)}
       </>

     )
   }
}


const mapStateToProps = function (state) {
  return {
    i18n: state.i18n
  }
}

export default connect(mapStateToProps)(PontuacaoUsuario);