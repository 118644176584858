
import styled from "styled-components";
export const GameRankingsListStyled = styled.div`

    .participante{
        display: flex;
        align-items: center;
        justify-content: start;
    }

    .participante:hover{
        border-top:1px solid ${props => { return props.theme.fundoSecundario || `#533888` }};
        border-bottom:1px solid ${props => { return props.theme.fundoSecundario || `#533888` }};
    }

    .participante-content, .participante{
        border: 1px solid transparent;
    }



    .participante-icon{
       margin-left: 2em;
       display: flex;
       align-items: center;
    }

    .participante-info{
        margin-left: 2em;
    }

    .participante-unidade{
        display: flex;
        font-size: 0.9em;
    }
    
    .participante-info-name{
        font-weight: 500;
    }
    .participante-info-pts{
        margin-left: 1em;
        font-size: 0.9em;
        color: #67CE67;
        font-weight: 700;

    }
    .participante-info-pts-msg{
        font-size: 0.9em;
        color: #67CE67;
        font-weight: 700;
    }
    .participante-info-pts-msg2{
        font-size: 0.9em;
        font-weight: 500;
    }
    .outros-participante{
        margin-top: 2em;
    }
    .content-one{
        display: flex;
    }
    .participante-info-posicao{
        margin-right: 2em;
    }

    .participante-info-unidade{
        margin-left: 3.6em;
    }
    .participante-info-unidade-isparticipante{
        margin-left: 0;
    }
    .info-position{
        margin-left: 2.3em;
        margin-bottom: 1em;
    }

    .isDesktop{
        display: none;
    }
    .info-pontuacao{
        font-size:0.8em;
        font-weight: bold;
    }


    @media screen and (min-width: 700px) {
        .scroll{
        overflow:auto;
        max-height: 50vh;
            ::-webkit-scrollbar {
                width: 5px;
            }

            ::-webkit-scrollbar-track {
                box-shadow: inset 0 0 5px  ;
                border-radius: 10px;
            }
            
            ::-webkit-scrollbar-thumb {
                background: ${props => { return props.theme.fundoSecundario }};
                border-radius: 10px;
            }

            ::-webkit-scrollbar-thumb:hover {
                background: ${props => { return props.theme.fundoSecundario || `#533888` }}; 
            }
        } 
        .isDesktop{
            display: block;
        }
        .isMobile{
            display: none;
        }
        .rankingList{
            margin-top: 1.0em;
        }
        .dinamica-title-desktop-margin{
            margin-top: 0.8em;
        }

        .margin-adjust{
            text-align: left;
            margin-left: 2em;
        }
        
        
    }

`