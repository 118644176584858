import axios from "axios";
import instance from './Api';
import UsuarioService from "./UsuarioService";



export default class CampanhaService {
  inscrever(request, callback) {
    axios
      .post(this.host() + "/api/v1/campaign/public/register", request)
      .then((response) => {
        if (response.data.authorization) {
          const usuarioService = new UsuarioService();
          usuarioService.setUsuarioCorrente(response.data.authorization);
        }
        callback(null, response.data);
      })
      .catch((error) => {
        callback(error, null);
      });
  }

  definirCampanha(codigo, callback) {

    let usuarioService = new UsuarioService();
    axios
      .get(this.host() + "/api/v1/campaign/public/" + codigo)
      .then((response) => {
        const { data } = response;

        window.localStorage.setItem("campanha", JSON.stringify(data));
        window.localStorage.setItem("template", JSON.stringify(data.template));
        window.localStorage.setItem("icone", JSON.stringify(data.icons));
        window.localStorage.removeItem("agenda");
        window.localStorage.removeItem("validade-cache-agenda");
        let usuarioCorrente = usuarioService.usuarioCorrente();
        if (
          usuarioCorrente &&
					usuarioCorrente.campaignCode &&
					usuarioCorrente.campaignCode !== codigo
        ) {
          usuarioService.logout(() => { });
        }
        callback(null, data);
      })
      .catch((error) => {
        window.localStorage.removeItem("campanha");
        window.localStorage.removeItem("template");
        window.localStorage.removeItem("agenda");
        window.localStorage.removeItem("validade-cache-agenda");
        callback(error, null);
      });
  }

  contadorCampanha(callback) {
    let newDate = new Date();
    window.localStorage.setItem("date-request-time", newDate)
    if (this.timeToNextRequestValido(newDate)) {
      callback(null, this.obterCheckStartDaCampanha())
    } else {
      instance
        .get(this.host() + "/api/v1/campaign/" + this.campanhaCorrente().uuid + "/checkStart")
        .then((response) => {
          window.localStorage.setItem(
            "check-start-campanha",
            JSON.stringify(response.data.days)
          );

          window.localStorage.setItem(
            "time-to-next-request",
            newDate.getTime() + response.data.timeToNextRequest
          );
          callback(null, response.data.days)
        })
        .catch((error) => {
          this.removeCheckStartETimeToNextRequestCache();
          callback(error, null)
        })
    }
  }

  removeCheckStartETimeToNextRequestCache() {
    window.localStorage.removeItem("check-start-campanha");
    window.localStorage.removeItem("time-to-next-request");
    window.localStorage.removeItem("date-request-time");
  }

  obterCheckStartDaCampanha() {
    let checkStart = window.localStorage.getItem("check-start-campanha")
    if (checkStart) {
      return JSON.parse(checkStart);
    } else {
      return null
    }
  }

  timeToNextRequestValido(newDate) {
    let timeToNextRequest = window.localStorage.getItem("time-to-next-request")
    if (timeToNextRequest) {
      return parseInt(timeToNextRequest) > newDate.getTime()
    } else {
      return false;
    }
  }

  obterCampanhas(callback) {
    axios
      .get(this.host() + '/api/v1/campaign/public/public-campaigns')
      .then((response) => {
        callback(null, response.data);
      })
      .catch((err) => {
        callback(err, null);
      })
  }

  validarRecaptcha(token, callback) {
    const request = { response: token };
    axios
      .post(this.host() + "/api/v1/captcha/verify", request)
      .then((response) => {
        callback(null, response.data);
      })
      .catch((error) => {
        callback(error, null);
      });
  }

  campanhaCorrente() {
    let valorStorage = window.localStorage.getItem("campanha");
    if (valorStorage) {
      return JSON.parse(valorStorage);
    } else {
      return null;
    }
  }
  templateCorrente() {
    let campanha = this.campanhaCorrente();
    if (campanha) {
      return campanha.template;
    }
  }
  iconeCorrente() {
    let campanha = this.campanhaCorrente();
    if (campanha) {
      return campanha.icons;
    }
  }
  i18nCorrente() {
    let campanha = this.campanhaCorrente();
    if (campanha) {
      return campanha.messages;
    }
  }

  gestoresCampanha(){
    let campanha = this.campanhaCorrente();
    if (campanha) {
      return campanha.gestores;
    }
  }

  removeLocalStorageSeCampanhasForemDiferentes(code) {
    let campanha = this.campanhaCorrente();
    if (campanha && campanha.code !== code) {
      return this.clearAll()
    }
    return null
  }

  isDesktopModel(){
    return window.screen.width > 700
  }

  /*
		Remove todas as chaves presentes no localstorage do sistema
	*/
  clearAll() {
    window.localStorage.clear()
  }

  host() {
    return process.env.REACT_APP_HOST_API;
  }
}
