import React from "react";
import { useStore, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import CampanhaService from "../../services/CampanhaService";
import UsuarioService from "../../services/UsuarioService";
import Image from "../imageLogo/Image";
import InfosTextuais from "./InfosTextuais";
import { CampanhaLogosStyle } from "./style";

const usuarioService = new UsuarioService();
const campanhaService = new CampanhaService();

function LogosCampanha({
  modo
}) {
  const i18n = useStore().getState().i18n;
  const usuario = useSelector((state) => state.usuario);
  let usuarioLogado = usuarioService.usuarioCorrente() && usuarioService.usuarioCorrente().token != null ? true : false;
  let campanha = campanhaService.campanhaCorrente();
  let logoCentro = "";
  let logoCentroDesktop = "";
  let campanhaName = "";
  let title = "";
  let tipoInscricao = "";
  let titleVisible = true;
  if(campanha){
    // #region DESKTOP
    if(!campanha.topLeftDesktop) {
      logoCentroDesktop = ""
    } else {
      logoCentroDesktop = campanha.topLeftDesktop
    }
    // #endregion DESKTOP
    if (!campanha.logoLeft) {
      logoCentro = ""
    } else {
      logoCentro = campanha.logoLeft;
    }
    if(!campanha.title){
      title = ""
    } else {
      title = campanha.title
    }
    if(!campanha.name) {
      campanhaName = ""
    } else {
      campanhaName = campanha.name
    }
    if(!campanha.tipoInscricao) {
      tipoInscricao = ""
    } else {
      tipoInscricao = campanha.tipoInscricao
    }

    if(campanha.titleVisible){
      titleVisible = true
    }else{
      titleVisible = false
    }
  }
  let location = useLocation()
	
  return (
    <>
      {
        (location.pathname === '/atividades'
			|| location.pathname === '/'
			|| location.pathname === '/trophy'
			|| location.pathname === '/interests'
			|| location.pathname === '/home' 
			|| location.pathname === "/resetPassword"
			|| location.pathname === "/passwordReset") 
			&& !usuarioLogado && usuario === false 
          ?
          (
            <header className={usuarioLogado && usuario === true ? `weex-container weex-cabecalho` : `weex-container`}>
              <CampanhaLogosStyle>
                <div className="campanha_logosTopo">
                  {
                    (logoCentro || logoCentroDesktop) && !usuarioLogado && usuario === false ?
                      (
                        <div style={{marginTop: tipoInscricao.toUpperCase() === 'EMAIL' && modo === 'register' ? '15px' : '100px', marginBottom: '15px'}}>
                          <Image
                            className="campanha_logosTopo__centro"
                            src={logoCentro}
                            alt={i18n.message("alt.logo.principal.mobile", "Logo principal da empresa")}

                            classNameDesktop={usuarioLogado ? "campanha_logosTopo__centro" : "campanha_logosTopo__centro__notLogged"}
                            srcDesktop={logoCentroDesktop}
                            altDesktop={i18n.message("alt.logo.principal.desktop", "Logo principal da empresa")}
                          />
                        </div>
                      ) : ""
                  }

                  <InfosTextuais 
                    campanhaName={campanhaName} 
                    title={title} 
                    titleVisible={titleVisible}
                  />		
                </div>
              </CampanhaLogosStyle>
            </header>
          ) : ""
      }
    </>
  );

}

export default LogosCampanha;
