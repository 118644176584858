import styled from "styled-components";

export const ComentariosCurtidasStyle = styled.div`

display: flex;
justify-content: space-between;
padding: 0.5em;
margin-top: 0.5em;

.btn-comentario{
    background: white;
    border: none;
    cursor: pointer;
}

.btn-hidden{
    background-color: transparent;
    border: none;
}

.pointer{
    cursor: pointer;
}

.curtida{
    display: flex;
}

.values{
    text-align: center;
    width: fit-content;
    height: fit-content;
}

.comentarios{
    border-radius: 10px;
    padding: 0.2em;
    font-size: smaller;
}

#input-like{
    display: none;
}

[id^="input-like"] {
  display: none;
}

`

export const HeaderPublicacao = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
padding: 0.5em;
padding-left: 0;

.pointer{
    cursor: pointer;
}
.button{
    background: none;
    border: none;
}

.dropdown-menu{
    top: auto;
    left: -10em !important;
}

.nome-time{
    text-align: left !important;
}

.titulo-container{
    display: flex;
    align-items: baseline;
}

.titulo-container__nome-participante{
    text-align: left;
}
`

export const ConteudoPublicacao = styled.div`

.mural_text{
    margin-left: 0;
    padding-left: 0;
}
.imagem-postagem{
    margin-top: 1em
}
p{
    text-align: left;
    margin-top: 0.5em;
    margin-bottom: 0.2em;
}

.pointer{
    cursor: pointer;
}

.atualizar-card {
    border: none;
    background-color: transparent
}

.margin-imagem-erro{
    margin-top: 3em;
    margin-bottom: 3em;
    display: flex;
    p {
        width: 80%;
    }
    div{
        margin: auto;
    }
}

.image{
    border-radius: 10px;
}

.text{
    width: 100%;
}

.text a{
    color: blue
}
`

export const QuebraEntreComentarios = styled.hr`

`
export const PublicacaoMuralStyled = styled.div`
    .publicacao-mural-content{
        text-align: center;
        margin-top: 30px;
        margin-bottom: 30px; 
    }
    
    @media screen  and (min-width: 700px) {
        width: 100%;
        .publicacao-mural-content{
            text-align: center;
            margin-top: 1em;
            border-radius:1em
        }
       

    }
`