import React from "react";
import { useStore, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import Image from "./imageLogo/Image";

/**
 * 
 * @param {*} usuarioLogado objeto com informações do usuário logado
 * @param {*} campanhaService objeto com informações da campanha atual
 * @returns 
 */
function Footer({
  usuarioLogado,
  campanhaService
}) {
  const i18n = useStore().getState().i18n;
  const usuario = useSelector((state) => state.usuario);
  let campanha = campanhaService.campanhaCorrente();
  let logoCentroFooter = null;
  let logoCentroFooterDesktop = null;
  if(campanha) {
    if (!campanha?.logoRight) {
      logoCentroFooter = ""
    } else {
      logoCentroFooter = campanha.logoRight;
    }
    // #region DESKTOP
    if (!campanha?.topRightDesktop) {
      logoCentroFooterDesktop = ""
    } else {
      logoCentroFooterDesktop = campanha.topRightDesktop;
    }
    // #endregion DESKTOP
  }
  let location = useLocation()
  const telaInicial = location.pathname === "/"

  return (
    <>
      {
        location.pathname === '/home' 
                || location.pathname === "/resetPassword"
                || location.pathname === "/passwordReset"
                || location.pathname === '/'
          ?
          (

            <footer className={usuarioLogado && !telaInicial ? `weex_footer weex-container weex-content` : `weex_footer weex-container`} style={{paddingTop: '0px'}}>
              {
                (logoCentroFooter || logoCentroFooterDesktop) && usuario === false ?
                  (
                    <Image
                      className="campanha_logosTopo__centro__footer"
                      src={logoCentroFooter}
                      alt={i18n.message("alt.logo.secundario.footer.mobile", "Logo secundário da empresa")}

                      classNameDesktop="campanha_logosTopo__centro__footer"
                      srcDesktop={logoCentroFooterDesktop}
                      altDesktop={i18n.message("alt.logo.secundario.footer.desktop", "Logo secundário da empresa")}
                    />      
                  ) : ""
              }
            </footer>
          )
          : ""
      }
    </>
  );
}

export default Footer;
