import React from 'react';
import { useStore } from 'react-redux';

/**
 * @param {Object} props
 * @param {number | string} props.chave
 * @param {boolean} props.habilitado
 * @param {string} props.imagemHabilitado
 * @param {string} props.imagemDesabilitado
 * @param {boolean} props.executado
 * @param {string} props.type
 * @param {React.CSSProperties} props.size
 * @param {function(event): void} props.navegar
 */
export default function AtividadeDia({
  chave,
  navegar,
  habilitado,
  imagemHabilitado,
  imagemDesabilitado,
  executado,
  size,
  type,
}) {

  const i18n = useStore().getState().i18n;

  const css = {
    backgroundImage: 'url(' + imagemHabilitado + ')'
  };

  const cssDois ={
    backgroundImage: 'url(' + imagemDesabilitado + ')'
  };

  const altImageAtividade = executado ? i18n.message("alt.atividade.completou","completou") : i18n.message("alt.atividade.nao.completou","Não completou");

  return (
    <button
      className="botao-invisivel"
      style={size}
      onClick={navegar}
      disabled={!habilitado}
      key={"button" + chave}
    >
      <div style={{ opacity: executado ? `1` : `0`, position: 'relative', top: '12px', left: '0px', right: '0px'}}>
        <img src='/positivo_tres.svg' alt={altImageAtividade}></img>
      </div>
      <div className={`card-atividades card-atividades__dia`} style={{border: executado ? '1px solid #00E291' : '1px solid #CECECE'}}>
        {habilitado ? (
          <div style={css} className="icone_atividades card-atividades__imagem">
          </div>
        ) : (
          <div style={cssDois} className="icone_atividades card-atividades__imagem">
          </div>
        )}
      </div>
      <div style={{marginTop: '1px'}}>
        <p className="label-atividades">
          {type}
        </p>
      </div>
    </button>
  )
}
