import styled from "styled-components";

export const BotaoIconeStyle=styled.button`
	background: transparent;
	border: none;
	font-size: 1.5rem;
	color: ${props => { return props.theme.fundoSecundario || `#533888`}};
	cursor: pointer;

  :active{
    filter: brightness(0.5)
  }

  .disabled{
    cursor: not-allowed;
    filter: opacity(0.5);
  }

  .icon-custom {
    margin-right: 1em;
  }

  .botaoDesktop{
    display: flex;
    align-items: center;
  }
`
