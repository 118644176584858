import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import InfosTextuaisMobile from '../../comps/LogosCampanha/InfosTextuaisMobile';
import CampanhaService from "../../services/CampanhaService";
import UsuarioService from '../../services/UsuarioService';
import { versao } from '../../versao';
import Navbar from './navbar/Navbar';

/**
 * 
 * @param {*} usuarioLogado objeto com informações do usuário logado
 * @returns 
 */
function Header({usuarioLogado}) {
  const usuario = useSelector((state) => state.usuario);
  const isDesktopMode = useSelector((state) => state.isDesktopMode);
  const dispatch = useDispatch()
  const [toggleMenu, setToggleMenu] = useState(false)
  let sair = () => {
    dispatch({type: "usuario", payload: false});
    new UsuarioService().logout(() => {
      window.location = "/";
    });
  };

  let toggle = () => {
    setToggleMenu(!toggleMenu)
  }

  const campanhaService = new CampanhaService();
  const usuarioService = new UsuarioService();
  let campanhaCorrente = campanhaService.campanhaCorrente();
  let beginDate
  let now = new Date().getTime();
  let location = useLocation();
  let tipoInscricao
  let legalTerm
  let requiredInterests
  let matricula = usuarioService.usuarioCorrente();
  let logoCentro = ""
  let logoCentroAreaLogada = "";
  let logoCentroDesktop = ""
  let logoCentroAreaLogadaDesktop = "";
  let title = "";
  let campanhaName = "";
  let comoFunciona
  let titleVisible
  if(campanhaCorrente){
    let dtStart = campanhaCorrente.startDate.split(" ");
    let dateStart = dtStart[0].split("/");
    let hourStart = dtStart[1].split(":");
    beginDate = new Date(dateStart[2], dateStart[1]-1, dateStart[0], hourStart[0], hourStart[1], hourStart[2]);
    tipoInscricao = campanhaCorrente.tipoInscricao
    legalTerm = campanhaCorrente.legalTerm
    requiredInterests = campanhaCorrente.requiredInterests
    titleVisible = campanhaCorrente.titleVisible
    comoFunciona = campanhaCorrente.comoFunciona
    if(!campanhaCorrente.logoLeft) {
      logoCentro = ""
    } else {
      logoCentro = campanhaCorrente.logoLeft
    }
    if(!campanhaCorrente.presentationImage){
      logoCentroAreaLogada = ""
    } else {
      logoCentroAreaLogada = campanhaCorrente.presentationImage
    }

    // #region DESKTOP
    if(!campanhaCorrente.topLeftDesktop) {
      logoCentroDesktop = ""
    } else {
      logoCentroDesktop = campanhaCorrente.topLeftDesktop
    }
    if(!campanhaCorrente.presentationDesktop){
      logoCentroAreaLogadaDesktop = ""
    } else {
      logoCentroAreaLogadaDesktop = campanhaCorrente.presentationDesktop
    }
    if(!campanhaCorrente.title){
      title = ""
    } else {
      title = campanhaCorrente.title
    }

    if(!campanhaCorrente.name) {
      campanhaName = ""
    } else {
      campanhaName = campanhaCorrente.name
    }
    // #endregion DESKTOP
  }

  const style = {display: "flex", alignItems: "center", justifyContent: 'space-between', padding: "10px", height: isDesktopMode ? '100px' : '', width: isDesktopMode ? '100%' : ''}
  const styleWithoutTrophy = {display: "flex", alignItems: "center", padding: "10px", height: isDesktopMode ? '100px' : ''}
  const styleElement = {display: 'block', margin: '0px auto 0px auto'}
  return (
    <>
      {usuarioLogado && usuario === true ? (
        <header className={`weex-container`}>
          {
            (tipoInscricao === "ELEGIBILIDADE" && legalTerm && !usuarioLogado.legalTerm)
                        || (requiredInterests === true && location.pathname === '/interests' && beginDate) <= now ?
              (
                <Navbar
                  style={isDesktopMode ? style : styleWithoutTrophy}
                  styleElement={isDesktopMode ? {} : styleElement}
                  toggle={toggle}
                  toggleMenu={toggleMenu}
                  logoCentroAreaLogada={logoCentroAreaLogada}
                  logoCentro={logoCentro}
                  logoCentroAreaLogadaDesktop={logoCentroAreaLogadaDesktop}
                  logoCentroDesktop={logoCentroDesktop}
                  title={title}
                  titleVisible={titleVisible}
                  campanhaName={campanhaName}
                  showTrophy={true}
                  usuarioLogado={matricula}
                  matricula={matricula?.enrollment}
                  tipoInscricao={tipoInscricao}
                  legalTerm={legalTerm}
                  notLegalTerm={!usuarioLogado?.legalTerm}
                  requiredInterests={requiredInterests}
                  locationPathname={location?.pathname}
                  beginDate={beginDate}
                  now={now}
                  comoFunciona={comoFunciona}
                  sair={sair}
                  versao={versao}
                />
              ) :
              (
                <Navbar
                  style={style}
                  toggle={toggle}
                  toggleMenu={toggleMenu}
                  titleVisible={titleVisible}
                  notLogoCentroAreaLogada={!logoCentroAreaLogada}
                  notLogoCentroAreaLogadaDesktop={!logoCentroAreaLogadaDesktop}
                  logoCentroAreaLogada={logoCentroAreaLogada}
                  logoCentro={logoCentro}
                  logoCentroAreaLogadaDesktop={logoCentroAreaLogadaDesktop}
                  logoCentroDesktop={logoCentroDesktop}
                  title={title}
                  campanhaName={campanhaName}
                  showTrophy={true}
                  usuarioLogado={matricula}
                  matricula={matricula?.enrollment}
                  tipoInscricao={tipoInscricao}
                  legalTerm={legalTerm}
                  notLegalTerm={!usuarioLogado?.legalTerm}
                  requiredInterests={requiredInterests}
                  locationPathname={location?.pathname}
                  beginDate={beginDate}
                  now={now}
                  comoFunciona={comoFunciona}
                  sair={sair}
                  versao={versao}
                />
              )
          }
        </header>

      ) : (
        ""
      )}
      {
        !isDesktopMode && campanhaCorrente?.titleVisible  ? (
          <InfosTextuaisMobile campanhaName={campanhaName} title={title} />
        ): ""
      }

    </>
  )
}

export default Header;
