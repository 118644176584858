import React from "react";
import Estrela from "./Estrela";

/**
 * @param {Object} props
 * @param {number} props.valor quantidade de estrelas que usuário da para a campanha
 * @param {function(string): void} props.callback onChange onde será setada a quantidade de estrelas
 * @returns 
 */
function Estrelas({valor, callback}) {
  return(
    <div className="estrelas">
      <Estrela
        limite="1"
        valor={valor}
        callback={callback}
      />
      <Estrela
        limite="2"
        valor={valor}
        callback={callback}
      />
      <Estrela
        limite="3"
        valor={valor}
        callback={callback}
      />
      <Estrela
        limite="4"
        valor={valor}
        callback={callback}
      />
      <Estrela
        limite="5"
        valor={valor}
        callback={callback}
      />
    </div>
  )
}

export default Estrelas;