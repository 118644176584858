import styled from "styled-components";

export const BotaoFeedbackStyle = styled.button`
  background-color: ${props => { return props.theme.fundoSecundario}};
  border-radius: 8px;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 3px;
  font-weight: 700;
  color: ${props => { return props.theme.textosCorFundoSecundario}};
  box-shadow: ${props => { return props.theme.sombraPrimaria}};
  text-transform: uppercase;
  padding: 1.6em;
  padding-left: 1em;
  padding-right: 1em;
  border: none;

  :active{
    filter: brightness(0.5)
  }


`