import React, { useEffect, useState } from "react";
import { ReCaptcha } from "react-recaptcha-v3";
import { useStore } from "react-redux";
import BotaoSecundario from "../../comps/botaoSecundario/BotaoSecundario";
import WeexMessage from "../../comps/WeexMessage";
import WeexModal from "../../comps/weexModais/WeexModal";

function CustomRegisterCampaignDesktop(props) {
  const i18n = useStore().getState().i18n;

  const [selectsUnidades, setSelectsUnidades] = useState([]);

  const [unit, setUnit] = useState(null);
  const [subUnit, setSubUnit] = useState(null);
  const [subSubUnit, setSubSubUnit] = useState(null);

  useEffect(() => {
    const inputUnidades = () => {
      if (!props || !props.campanha || !props.campanha.client || !props.campanha.client.units || !props.campanha.registrationUnitMandatory) {
        return null;
      }
      let unidades = props.campanha.client.units;
      if (unidades && unidades.length > 0) {
        return unidades;
      }
      return null;
    };
    let unidades = inputUnidades();

    if (unidades) {
      setSelectsUnidades(unidades);
    }
    // TODO: Lint não deixa ser apenas uma array vazia -> Larissa (Tarefa Bug #15192)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mudarUnidade = (event, nivel) => {
    event.preventDefault();
    const value = event.target.value;
    props.onChange(event);
    switch (nivel) {
    case 0:
      setUnit(value);
      setSubUnit(null);
      setSubSubUnit(null);
      break;
    case 1:
      setSubUnit(value);
      setSubSubUnit(null);
      break;
    case 2:
      setSubSubUnit(value);
      break;
    default:
      console.error("Nível desconhecido");
      break;
    }
  };

  const renderInputUnidade = () => {
    if (!props.campanha.registrationUnitMandatory) {
      return null
    }
    return (
      <div className="field-cadastro">
        <div className="field">
          <label className="label" htmlFor="selectUnit"><WeexMessage code="registro.campo.area" default="Selecione sua área"/>
            <div className="select weex-select select-margin-top-control">
              <select value={unit} name="unit" onChange={(e) => mudarUnidade(e, 0)} required id="selectUnit">
                <option value="">
                  {i18n.message("select.unidade", "Selecione uma unidade")}
                </option>
                {selectsUnidades
                  .map((opcao) => {
                    return (
                      <option value={opcao.uuid} key={opcao.uuid}>
                        {opcao.name}
                      </option>
                    );
                  })}
              </select>
            </div>
          </label>
        </div>
      </div>
    );

  };

  const renderInputSubUnidade = () => {
    const unidade = selectsUnidades.find((unidade) => unidade.uuid === unit);
    if (!unidade || !unidade.subUnits || unidade.subUnits.length === 0) {
      return null;
    }
    return (
      <div className="field-cadastro ">
        <div className="field">
          <label className="label" htmlFor="selectSubUnit"><WeexMessage code="registro.campo.subarea" default="Selecione sua subárea"/>
            <div className="select weex-select select-margin-top-control">
              <select id="selectSubUnit" value={subUnit} name="subUnit" onChange={(e) => mudarUnidade(e, 1)}>
                <option value="">
                  {i18n.message("select.subárea", "Selecione uma subárea")}
                </option>
                {unidade.subUnits.map((opcao) => {
                  return (
                    <option value={opcao.uuid} key={opcao.uuid}>
                      {opcao.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </label>
        </div>
      </div>
    );

  };

  const renderInputSubSubUnidade = () => {
    const unidade = selectsUnidades.find((unidade) => unidade.uuid === unit);
    if (!unidade || !unidade.subUnits || unidade.subUnits.length === 0) {
      return null;
    }
    const subUnidade = unidade.subUnits.find(
      (subUnidade) => subUnidade.uuid === subUnit
    );
    if (!subUnidade || !subUnidade.subUnits || subUnidade.subUnits.length === 0) {
      return null;
    }
    return (
      <div className="field-cadastro margin-top-3">
        <div className="field">
          <label className="label" htmlFor="selectSubUnit"><WeexMessage code="registro.campo.nivel3" default="Selecione sua sub-subárea"/>
            <div className="select weex-select select-margin-top-control">
              <select id="selectSubUnit" value={subSubUnit} name="level3" onChange={(e) => mudarUnidade(e, 2)}>
                <option value="">
                  {i18n.message("select.subsubunidade",
                    "Selecione uma sub-subunidade")}
                </option>
                {subUnidade.subUnits.map((opcao) => {
                  return (
                    <option value={opcao.uuid} key={opcao.uuid}>
                      {opcao.name}
                    </option>
                  );
                })}
              </select>
            </div>
          </label>
        </div>
      </div>
    );
  };

  const renderColumns = () => {
    const totalFields = props.fields.length;
    const halfFields = Math.ceil(totalFields / 2);
    const [coluna1Linhas] = totalFields % 2 !== 0 ? [halfFields + 1] : [halfFields];

    let coluna1 = [];
    let coluna2 = [];
    let tamanhoColuna1 = 0;
    let tamanhoColuna2 = 0;

    props.fields.forEach((f, index) => {
      const fieldWrapper = (
        <div className={`field-cadastro`}>
          {props.renderizaCampo(f, index)}
        </div>
      );

      if (index < coluna1Linhas) {
        coluna1.push(fieldWrapper);
        tamanhoColuna1 += 1;
      } else {
        coluna2.push(fieldWrapper);
        tamanhoColuna2 += 1;
      }
    });

    if (tamanhoColuna1 <= tamanhoColuna2) {
      coluna1.push(renderInputUnidade());
      coluna2.push(renderInputSubUnidade());
      coluna1.push(renderInputSubSubUnidade())
    } else {
      coluna2.push(renderInputUnidade());
      coluna1.push(renderInputSubUnidade());
      coluna2.push(renderInputSubSubUnidade())
    }

    return (
      <>
        <div
          className={
            tamanhoColuna1 > tamanhoColuna2 &&
            props.campanha.client.units.length > 0
              ? "field-group coluna-margin"
              : "field-group"
          }
        >
          {coluna1}
        </div>
        <div
          className={
            tamanhoColuna1 < tamanhoColuna2 &&
            props.campanha.client.units.length > 0
              ? "field-group coluna-margin"
              : "field-group"
          }
        >
          {coluna2}
        </div>
      </>
    );
  };

  let link = ""
  if(props.code === 'rededor') {
    link = "https://weex.digital/termos-rede-dor/"
  } else if(props.code === 'lorenzetti' || props.code === 'lorenzettiteste') {
    link = "https://weex.digital/wp-content/uploads/2023/06/02-2023_06_01_Regulamento-Sem-Meio-Amb-Loren-2023_SP-rev-02-SP-e-MG.pdf"
  } else {
    if(props.privacyPolicy) {
      link = props.privacyPolicy
    } else {
      link = "https://weex.digital/politica-de-privacidade-e-uso/"
    }
  }

  return (
    <form onSubmit={props.registro} className="cadastro-desktop">
      {props.erro != null ? (
        <WeexModal
          fecharModalCallback={props.callbackWarning}
          titulo={props.erro.titulo}
          conteudo={props.erro.mensagem}
        />
      ) : null}

      <div className="inputs_forms">{renderColumns()}</div>

      <div className="field-group-checkbox">
        <label htmlFor="acceptTerms" className="checkbox checkbox-label-style" aria-label={i18n.message("aria.label.campo.registro.legal.termos", " Aceitar termos e condições ")}>
          <input
            onChange={props.onChangeAccept}
            type="checkBox"
            name="aceito"
            id="acceptTerms"
          />
          &nbsp;&nbsp;
          {i18n.message("registro.legal.liaceito", "Li e aceito os")}
          <a
            className="link-sublinhado"
            href={link}
            target="_blank"
            rel="noopener noreferrer"
          >
            {i18n.message("registro.legal.termos", " Termos e Condições ")}
          </a>
          {i18n.message("registro.legal.daweex", "da Weex")}
        </label>
      </div>
      <ReCaptcha
        sitekey={props.recaptchaToken}
        action="register"
        verifyCallback={props.verifyCallback}
      />

      <div className="button-cadastro-content">
        <BotaoSecundario
          disabled={props.accept || props.captcha ? false : true}
          nome={
            props.processando
              ? i18n.message("geral.aguarde", "AGUARDE...")
              : i18n.message("registro.criar", "CADASTRAR")
          }
        />
        <button className='button-link espacamento-inicio' type='button' onClick={props.modoLogin}>
          {i18n.message("registro.posseuiCadastro", "Já possuo cadastro")}
        </button>
      </div>
    </form>
  );
}

export default CustomRegisterCampaignDesktop;


