import React from 'react';
import { useStore } from 'react-redux';
import FeedbackVideo from '../QuizVideoFeedback/FeedbackVideo';
import BotaoVideo from '../botaoVideo/BotaoVideo';

/**
 * @param {Object} props
 * @param {*} props.codigo
 * @param {*} props.curtiuVideo
 * @param {*} props.comentarioVideo
 * @param {function(): void} props.isVideoCarregado
 * @param {function(): void} props.onChangeCurtiuVideo
 * @param {function(): void} props.onChangeComentarioVideo
 * @param {function(): void} props.botaoBloqueado
 * 
 * @param {function(): void} props.redirecionar
 * @param {function(): void} props.marcarComoVistoMensagem
 * // TODO: método é uma configuração temporária para a campanha da Accenture
 * @param {function(): void} props.validarCampanha
 */
function FormVideoFeedback({
  isVideoCarregado,
  codigo,
  curtiuVideo,
  comentarioVideo,
  onChangeCurtiuVideo,
  onChangeComentarioVideo,
  botaoBloqueado,
  redirecionar,
  marcarComoVistoMensagem,
  classNameDesktopFeedbackDiv,
  classNameDesktopBotaoDiv,
  // TODO: método é uma configuração temporária para a campanha da Accenture
  validarCampanha
}) {

  // TODO: este style é uma configuração temporária para a campanha da Accenture
  const styleAccenture = validarCampanha ? {marginLeft: '22vw', marginRight: '22vw'} : {}
  const i18n = useStore().getState().i18n;

  return (
    <>
      {isVideoCarregado() && (
        <>
          {codigo ? (
            <>          
              <div className={`${classNameDesktopFeedbackDiv || ''}`}>
                <FeedbackVideo
                  pergunta={i18n.message("video.feedback.questao", "O que você achou desse vídeo?")}
                  placeholder={i18n.message("video.feedback.comentario", "Escreva sua dúvida ou comentário")}
                  curtiuVideo={curtiuVideo} comentarioVideo={comentarioVideo}
                  onChangeCurtiuVideo={onChangeCurtiuVideo}
                  onChangeComentarioVideo={onChangeComentarioVideo}
                />
              </div>
              <div className={`${classNameDesktopBotaoDiv || ''}`} style={styleAccenture}>          
                {botaoBloqueado() && (
                  <p style={{ textAlign: 'center', fontWeight: '300', color: '#524C5E', marginBottom: '10px' }}>
                    <small>{i18n.message("video.aviso.botaodesbloquear", "Veja o vídeo até o final")}</small>
                  </p>
                )}
                <div style={{ marginBottom: '2rem' }}>
                  <BotaoVideo
                    funcao={redirecionar}
                    disabled={botaoBloqueado()}
                    classeComplementar="botao-video-desabilitado"
                    nome={i18n.message("video.entendi", "Concluir atividade")}
                  />
                  {!botaoBloqueado() && (
                    <span className="visually-hidden" role="alert" aria-live="assertive">
                      {i18n.message("aria.label.video.alert.botao.desbloqueado", "Botão concluir atividade desbloqueado!")}
                    </span>
                  )}
                </div>
              </div>
            </>
          ): (
            <div style={{ marginBottom: '2rem' }}>
              <BotaoVideo
                funcao={marcarComoVistoMensagem}
                nome={i18n.message("video.entendi", "Entendi")}
              />
            </div>
          )}
        </>
      )}
    </>
  )
}

export default FormVideoFeedback;
