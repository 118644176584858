import React, { useRef, useState } from "react";
import { useStore } from "react-redux";
import { animations } from "react-animation";
import { WeexModalDialog } from "./WeexModalDialog";
import BotaoPrimario from "../botaoPrimario/BotaoPrimario";
import BotaoSecundario from "../botaoSecundario/BotaoSecundario";

/**
 * @param {Object} props 
 * @param {string} props.titulo
 * @param {string} props.conteudo
 * @param {function(event): void} props.confirmarCallback
 * @param {function(): void} props.cancelarCallback
 */
function InteressesConfirmacaoModal({
  titulo,
  conteudo,
  confirmarCallback,
  cancelarCallback
}) {
  const [mostrarModal, setMostrarModal] = useState(true)
  const i18n = useStore().getState().i18n;
  const modalDialogRef = useRef(null)

  function cancelar() {
    cancelarCallback()
    setMostrarModal(false)
    modalDialogRef.current.close()
  }

  function confirmar(event) {
    confirmarCallback(event)
    setMostrarModal(false)
  }

  return (
    mostrarModal ? (
      <WeexModalDialog
        dialogRef={modalDialogRef}
        onPressESC={cancelar}
      >
        <div
          className={"modal is-active"}
          style={{ animation: animations.popIn, zIndex: "102" }}
        >
          <div className="modal-background weex-modal-background"></div>
          <div className="modal-card weex-modal-card">
            <header className="modal-card-head weex-modal-card-header"></header>
            <header className="weex-modal-card-header wex-modal-card-header-title">
              <h1 className="nome-atividade">{titulo}</h1>
            </header>
            <section className="modal-card-body has-text-centered">
              <div className="weex-modal-card-body__texto">
                <div>{conteudo}</div>
              </div>
            </section>
            <footer
              className="modal-card-foot weex-modal-card-footer interesse-obrigatiorio-container"
              style={{ textAlign: "center" }}
            >
              <BotaoSecundario
                nome={i18n.message("geral.cancelar", "Cancelar")}
                funcao={cancelar}
                classeComplementar="espaço"
              />

              <BotaoPrimario
                nome={i18n.message("geral.confirmar", "Confirmar!")}
                funcao={confirmar}
                ariaStatusMessage={i18n.message("aria.status.interessesconfirmacaomodal.confirmar", "Botão confirmar clicado.")}
              />
            </footer>
          </div>
        </div>
      </WeexModalDialog>
    ) : <></>
  );
}

export default InteressesConfirmacaoModal;
