import React, { Component } from 'react';

// import './Contador.css'
import { FlipClockStyle } from './style';
import ContainerFlip from './ContainerFlip'


export default class Contador extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dias: 0,
      diasShuffle: true,
      horas: 0,
      horasShuffle: true,
      minutos: 0,
      minutosShuffle: true
    };
  }
  
  componentDidMount() {
    this.timerID = setInterval(
      () => this.updateTime(),
      100
    );
  }
  
  componentWillUnmount() {
    clearInterval(this.timerID);
  }
  
  updateTime() {
    let horaCorrente = new Date()
    let horaLimite = new Date(this.props.inicioCampanha)
    let tempoRestante = horaLimite.getTime() - horaCorrente.getTime()

    var tempoTemp
    tempoTemp = tempoRestante
    const dias = parseInt(tempoTemp/86400000)
    tempoTemp = tempoTemp%86400000
    const horas = parseInt(tempoTemp/3600000)
    tempoTemp = tempoTemp%3600000
    const minutos = parseInt(tempoTemp/60000)

        
    if( dias !== this.state.dias) {
      const diasSuffle = !this.state.diasSuffle;
      this.setState({
        dias,
        diasSuffle
      });
    }
        
    if( horas !== this.state.horas) {
      const horasShuffle = !this.state.horasShuffle;
      this.setState({
        horas,
        horasShuffle
      });
    }
        
    if( minutos !== this.state.minutos) {
      const minutosShuffle = !this.state.minutosShuffle;
      this.setState({
        minutos,
        minutosShuffle
      });
    }
  }

  render() {

    const { 
      dias,
      horas,
      minutos,
      diasSuffle,
      horasShuffle,
      minutosShuffle,
    } = this.state;

    return (
      <FlipClockStyle>
        <ContainerFlip unit={'dias'} digit={dias} shuffle={diasSuffle}/>
        <h2 className='separador'>:</h2>
        <ContainerFlip unit={'horas'} digit={horas} shuffle={horasShuffle}/>
        <h2 className='separador'>:</h2>
        <ContainerFlip unit={'minutos'} digit={minutos} shuffle={minutosShuffle}/>
      </FlipClockStyle>
    )
  }
}

