import React from 'react';
import { Link } from "react-router-dom";
import Image from '../../imageLogo/Image';

function NavbarElement({
  to,
  className,
  style,
  src,
  alt,
  srcDesktop,
  altDesktop
}) {
  return (
    <>
      <Link to={to} className={className} style={style}>
        <Image
          src={src}
          alt={alt}

          srcDesktop={srcDesktop}
          altDesktop={altDesktop}
        />
      </Link>
    </>
  )
}

export default NavbarElement;