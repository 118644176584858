import React, { Component } from "react";
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from "react-redux";
import { GameRankingsListStyled } from "../../comps/gameRanking/styled";
import Spinner from '../../comps/publicacao/Spinner';
import PontuacaoUsuario from './PontuacaoUsuario';
import PontuacaoUsuarioCorrente from "./PontuacaoUsuarioCorrente";

class GameRankingsList extends Component {

  constructor(props) {
    super(props);
    this.state = { 
      games: [], 
      ranking: [],
      page: 1,
      classTitulo:'',
    };  
  }

  componentDidMount() {
    this.setState({classTitulo: this.classNameTitulo()})
  }

  componentDidUpdate(prevProps) {
    if (prevProps.pontuacaoUsuario !== this.props.pontuacaoUsuario) {
      this.setState({classTitulo: this.classNameTitulo()})
    }
  }
    


    gerarPaginas = () => {
      return (
        this.props.rankingFormat.map((pontuacaoUsuario, index) => {
          if (index + 1 <= this.state.page * 10) {
            return (
              <div className="participante-content" key={index}>
                <div className="outros-participante participante" >
                  <PontuacaoUsuario
                    pontuacaoUsuario={pontuacaoUsuario}
                    isParticipante={false}
                  >
                  </PontuacaoUsuario>
                </div>
              </div>

            )
          }
          return null;
        }))
    }
    loadMore = () => {
      if (this.state.page <= 5) {
        this.setState({ page: this.state.page + 1 })
      }
    }

    infiniteScroll = () => {
      return (
        <div className="scroll">
          <InfiniteScroll
            pageStart={0}
            loadMore={this.loadMore}
            hasMore={true}
            loader={<div>
              <Spinner isVisivel={this.state.spinner} />
            </div>}
            useWindow={false}
          >
            {
              this.gerarPaginas()
            }
          </InfiniteScroll>
        </div>
      )
    }
    classNameTitulo = () => {
      if (this.props.pontuacaoUsuario?.rating > 0){
        return 'dinamica-title-desktop-margin isDesktop margin-adjust'
      }
      return 'dinamica-title-desktop isDesktop margin-adjust'

    }


    render() {
      return (
        <GameRankingsListStyled>
          <div className="isDesktop rankingList">
            <PontuacaoUsuarioCorrente
              pontuacaoUsuario={this.props.pontuacaoUsuario}
              ranking={this.props.ranking}
            />
          </div>

          <h3  className={this.state.classTitulo} >
            {this.props.i18n.message("ranking.title","RANKING")}</h3>
          <div >
            {this.infiniteScroll()}
          </div>
        </GameRankingsListStyled>
      )
    }

}

const mapStateToProps = function (state) {
  return {
    i18n: state.i18n,
    isDesktopMode: state.isDesktopMode
  }
}

export default connect(mapStateToProps)(GameRankingsList);