import React, {useState} from "react";
import { useStore } from "react-redux";

/**
 * @param {Object} props
 * @param {string} props.limite número de estrelas que o usuário pode selecionar
 * @param {number} props.valor quantidade de estrelas que usuário da para a campanha
 * @param {function(string): void} props.callback onChange onde será setada a quantidade de estrelas
 */
function Estrela({limite, valor, callback}) {
  
  const [mensagemDeSelecao, setMensagemDeSelecao] = useState("")
  const i18n = useStore().getState().i18n
  
  const clique = () => {
    callback(limite);
    const mensagemSingular = i18n.message("aria.hidden.feedback.selecionado.singular", "estrela selecionada!")
    const mensagemPlural = i18n.message("aria.hidden.feedback.selecionado.plural", "estrelas selecionadas!")
    const mensagem = `${limite} ${limite === '1' ? mensagemSingular : mensagemPlural}`
    setMensagemDeSelecao(mensagem)
  };

  const selecionado = valor >= limite

  return (
    <>
      <button 
        onClick={clique}
        className="btn-invisible"
        type="button"
      >
        <img
          alt={`${i18n.message("alt.feedback.estrela", "Ícone de estrela ")} ${limite} ${i18n.message("alt.feedback.estrela.quantidade.total", "de 5.")}`}
          style={{ maxWidth: "40px", margin: "0.4em" }}
          src={selecionado
            ? "/new_star_full.svg"
            : "/new_star_empty.svg"
          }
        />
      </button>
      <span 
        className="visually-hidden"
        role="status"
      >
        {mensagemDeSelecao}
      </span>
    </>
  );
}

export default Estrela;