import React, { useEffect, useState } from "react";
import { useDispatch, useStore } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import BotaoPrimario from "../comps/botaoPrimario/BotaoPrimario";
import WeexModal from "../comps/weexModais/WeexModal";
import CampanhaService from "../services/CampanhaService";
import UsuarioService from "../services/UsuarioService";

function SeletorCampanha() {
  const [erro, setErro] = useState(null);
  const [codigo, setCodigo] = useState("");
  const service = new CampanhaService();
  const usuarioService = new UsuarioService();
  const [processando, setProcessando] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  let { code } = useParams();

  const [clienteEscolhido, setClienteEscolhido] = useState('')
  const [campanhaEscolhida, setCampanhaEscolhida] = useState('')

  const [campanhasPublicas, setCampanhas] = useState([])

  useEffect(() => {
    usuarioService.removeUsuarioCorrente();
    dispatch({ type: "usuario", payload: false });
  })

  useEffect(() => {
    new CampanhaService().obterCampanhas((erro, sucesso) => {
      if (erro) {
        console.log(erro)
      }
      if (sucesso) {
        setCampanhas(sucesso)
      }
    })
  }, [])

  const [clienteCampanha, setCliente] = useState('')


  const i18n = useStore().getState().i18n;

  const [erroMsg, setErroMsg] = useState({
    isWrong: false,
    titulo: "",
    mensagem: "",
    voltar: false,
  });

  useEffect(() => {
    if (!processando) {
      setProcessando(true);
      service.removeLocalStorageSeCampanhasForemDiferentes(code)
      service.definirCampanha(code, (erro, sucesso) => {
        if (erro) {
          setErro(erro);
          return;
        }
        if (sucesso) {
          dispatch({ type: "campanha/selecionar", payload: sucesso.messages });
          dispatch({ type: "campanha", payload: sucesso });
          dispatch({ type: "template", payload: sucesso.template });
          dispatch({ type: "icone", payload: sucesso.icons });
          dispatch({ type: "usuario", payload: false });
          history.push("/");
        }
      });
    }
  }, [code, dispatch, history, processando, service]);

  const onChangeCliente = (e) => {
    const { value } = e.target;

    if (value === "") {
      setCliente('')
      setClienteEscolhido("")
      setCodigo('')
      return
    }

    const dadosCliente = campanhasPublicas[value].cliente.campanhas
    if (dadosCliente.length > 1) {
      setCliente(dadosCliente)
      setClienteEscolhido(value)
      setCodigo('')
    } else {
      setCliente('')
      setClienteEscolhido(value)
      setCodigo(dadosCliente[0].code)
    }

  };

  const onChangeCampanha = (e) => {
    const { value } = e.target;

    if (value === "") {
      setCampanhaEscolhida("")
      setCodigo('')
      return
    }
    setCodigo(value)
    setCampanhaEscolhida(value)
  }

  const submitRedirectEvent = (event) => {
    event.preventDefault();
    service.removeLocalStorageSeCampanhasForemDiferentes(codigo)
    service.definirCampanha(codigo, (erro, sucesso) => {
      if (erro) {
        setErroMsg({
          isWrong: true,
          titulo: i18n.message(
            "redirecionamento.erro.aoEncontrarCampanha.titulo",
            "Campanha não encontrada"
          ),
          mensagem: i18n.message(
            "redirecionamento.erro.aoEncontrarCampanha.mensagem",
            "Escolha uma campanha antes de prosseguir."
          ),
          voltar: true,
        });
      }
      if (sucesso) {
        dispatch({ type: "campanha/selecionar", payload: sucesso.messages });
        dispatch({ type: "template", payload: sucesso.template });
        dispatch({ type: "icone", payload: sucesso.icons });
        dispatch({ type: "usuario", payload: false });
        setErroMsg({ isWrong: false });
        history.push("/");
      }
    });
  };

  const callbackModal = () => {
    if (erroMsg.isWrong === true) {
      setErroMsg({ isWrong: false });
    }
  };

  return (
    <>
      {erroMsg.isWrong === true ? (
        <WeexModal
          fecharModalCallback={callbackModal}
          titulo={erroMsg.titulo}
          conteudo={erroMsg.mensagem}
        />
      ) : null}
      {erro !== null ? (
        <>
          <section className="weex_select_campaign_container_content">
            <div>
              <img src="../logo.png" alt={i18n.message("alt.redirecionamento.campanha.weex.logo", "Logo da empresa fornecedora do serviço Weex")} />
            </div>
            <div className="weex_select_campaign_container">
              <form style={{ width: "100%" }}>
                <div className="field redireciona-campanha-field">
                  <div className="field">
                    <label className="label" htmlFor="select-empresa">{i18n.message("redirecionamento.empresa", "Em qual empresa você trabalha?")}
                      <div className="control select-margin-top-control">
                        <select id="select-empresa" className="select-input input is-medium" value={clienteEscolhido} style={{ width: "100%" }} onChange={onChangeCliente}>
                          <option value="">{i18n.message("redirecionamento.empresaSelecao", "Selecione a sua empresa")}</option>
                          {
                          campanhasPublicas?.map((clientes, index) => {
                            return (
                              <option key={clientes.cliente.uuid} value={index}>
                                {clientes.cliente.name}
                              </option>
                            )
                          })
                          }
                        </select>
                      </div>
                    </label>
                  </div>
                </div>
                {
                  clienteCampanha ?
                    <div className="field redireciona-campanha-field">
                      <div className="field">
                        <label className="label" htmlFor="select-campanha">{i18n.message("redirecionamento.campanha", "De qual campanha está participando?")}
                          <div className="control select-margin-top-control">
                            <select id="select-campanha" className="select-input input is-medium" value={campanhaEscolhida} style={{ width: "100%" }} onChange={onChangeCampanha}>
                              <option value="">{i18n.message("redirecionamento.campanhaSelecao", "Selecione uma campanha")}</option>
                              {
                                clienteCampanha.map((campanha, index) => {
                                  return (
                                    <option key={campanha.uuid} value={campanha.code}>
                                      {campanha.name}
                                    </option>
                                  )
                                })
                              }
                            </select>
                          </div>
                        </label>
                      </div>
                    </div>
                    : ""
                }
                <div className="redireciona-campanha-container-btn">
                  {
                    codigo && (
                      <BotaoPrimario
                        nome={i18n.message(
                          "redirecionamento.redirecionar.botao",
                          "Entrar"
                        )}
                        disabled={codigo.length === 0}
                        funcao={submitRedirectEvent}
                        ariaStatusMessage={i18n.message("aria.status.seletorcampanha.botaoredirecionar", "Botão redirecionar clicado.")}
                      />
                    )
                  }
                </div>
              </form>
            </div>
          </section>
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export default SeletorCampanha;
