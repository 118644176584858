import React, { useState } from "react";
import { useStore, useSelector } from "react-redux";
import BotaoPrimario from "../../comps/botaoPrimario/BotaoPrimario";
import FeedbackModal from "../../comps/weexModais/FeedbackModal";
import FeedbackService from "../../services/FeedbackService";
import Estrelas from "./Estrelas";
import SimNao from "./SimNao";
import { FeedbackStyle } from "./style";
import Mensagem from "./Mensagem";

function Feedback() {
  const i18n = useStore().getState().i18n;
  const isDesktopMode = useSelector((state) => state.isDesktopMode);
  const [processando, setProcessando] = useState(false);
  const [enviado, setEnviado] = useState(false);
  const [feedback, setFeedback] = useState({
    conteudoRelevante: 0,
    recomendaria: 0,
    participariaNovamente: null,
    mensagem: "",
  })

  const onChangeConteudoRelevante = (estrelas) => {
    setFeedback({...feedback, conteudoRelevante: estrelas});
  };

  const onChangeRecomendaria = (estrelas) => {
    setFeedback({...feedback, recomendaria: estrelas});
  };

  const onChangeParticiparia = (valor) => {
    setFeedback({...feedback, participariaNovamente: valor});
  };

  const onChangeFeedback = (valor) => {
    let {value} = valor.target;
    setFeedback({...feedback, mensagem: value});
  };

  const enviar = (event) => {
    event.preventDefault();
    if (
      feedback.participariaNovamente === null ||
        feedback.recomendaria === 0 ||
        feedback.conteudoRelevante === 0
    ) {
      return;
    }
    setProcessando(true);
    let service = new FeedbackService();
    service.enviarFeedback(feedback, (erro, sucesso) => {
      setProcessando(false);
      setEnviado(erro === null)
    });
  };

  if (enviado) {
    return <FeedbackModal />;
  }

  return (
    <FeedbackStyle>
      <header style={{ marginBottom: "1.0em" }}>
        <div className="card-header-title weex-card-header card-header-feedback">
          <h1>Feedback</h1>
          <h2>
            {i18n.message("feedback.titulo1", "Conte-nos sobre sua experiência aqui.")}
          </h2>
          <h2>
            {i18n.message("feedback.titulo2", "Sua opinião é fundamental!")}
          </h2>
        </div>
      </header>

      <div className="conteudo-card-atividades">
        <form>
          <div className="questao_feedback">
            <span className="visually-hidden">{`1. ${i18n.message("aria.label.feedback.escala", "Em uma escala de 1 a 5.")} ${i18n.message("feedback.questao1", "O conteúdo foi relevante?")}`}</span>
            <p className="questao_feedback_enunciado" aria-hidden="true">
              <strong>01. </strong>
              {i18n.message("feedback.questao1", "O conteúdo foi relevante?")}
            </p>
            <Estrelas
              valor={feedback.conteudoRelevante}
              callback={onChangeConteudoRelevante}
            />
          </div>
          <div className="questao_feedback">
            <span className="visually-hidden">{`2. ${i18n.message("aria.label.feedback.escala", "Em uma escala de 1 a 5.")} ${i18n.message("feedback.questao2", "Você recomendaria para algum colega?")}`}</span>
            <p className="questao_feedback_enunciado" aria-hidden="true">
              <strong>02. </strong>
              {i18n.message(
                "feedback.questao2",
                "Você recomendaria para algum colega?"
              )}
            </p>
            <Estrelas
              valor={feedback.recomendaria}
              callback={onChangeRecomendaria}
            />
          </div>
          <div className="questao_feedback">
            <span className="visually-hidden">{`3. ${i18n.message("feedback.questao3", "Você participaria novamente?")} ${i18n.message("aria.label.feedback.simNao", "Clique em sim ou não.")}`}</span>
            <p className="questao_feedback_enunciado" aria-hidden="true">
              <strong>03. </strong>
              {i18n.message(
                "feedback.questao3",
                "Você participaria novamente?"
              )}
            </p>
            <SimNao
              valor={feedback.participariaNovamente}
              callback={onChangeParticiparia}
            />
          </div>
          <div className="questao_feedback">
            <span className="visually-hidden">{`4. ${i18n.message("feedback.comentarios", "Escreva seus comentários (opcional)")}`}</span>
            <p className="questao_feedback_enunciado" aria-hidden="true">
              <strong>04. </strong>
              {i18n.message(
                "feedback.comentarios",
                "Escreva seus comentários (opcional)"
              )}
            </p>
            <Mensagem
              mensagem={feedback.mensagem}
              callback={onChangeFeedback}
              isDesktopMode={isDesktopMode}
            />
          </div>
          <div className="questao_feedback" style={{ marginTop: "2.0em" }}>
            <div className={!isDesktopMode ? '' : 'feedback-conteiner-width-desktop'}>
              <BotaoPrimario
                nome={
                  processando
                    ? i18n.message("geral.aguarde", "Aguarde...")
                    : i18n.message("geral.enviar", "Enviar")
                }
                funcao={enviar}
                ariaStatusMessage={i18n.message("aria.status.feedback.botaoenviar", "Botão enviar clicado.")}
              />
            </div>
          </div>
        </form>
      </div>
    </FeedbackStyle>
  );
}

export default Feedback;