import Vimeo from '@u-wave/react-vimeo';
import React, { Component } from 'react';
import { Document, Page, pdfjs } from "react-pdf";
import { connect } from 'react-redux';
import BotaoAsALink from '../../comps/botaoAsALink/BotaoAsALink';
import BotaoAsALinkIconeEsquerda from '../../comps/botaoAsALink/BotaoAsAlinkWithIconLeft';
import BotaoPrimario from '../../comps/botaoPrimario/BotaoPrimario';
import BotaoSecundario from '../../comps/botaoSecundario/BotaoSecundario';
import DinamicasHeader from '../../comps/dinamicasHeader/DinamicasHeader';
import AgendaService from '../../services/AgendaService';
import ExtraService from '../../services/ExtraService';
import UsuarioService from '../../services/UsuarioService';
import { ExtraStyle } from './style';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

/**
 * @typedef {Object} ExtraObject
 * @property {string} uuid - O UUID do objeto extra.
 * @property {string} title - O título do objeto extra.
 * @property {string} code - O código do objeto extra.
 * @property {string} name - O nome do objeto extra.
 * @property {string} description - A descrição do objeto extra.
 * @property {string} presentation - A apresentação do objeto extra.
 * @property {boolean} active - Indica se o objeto extra está ativo.
 * @property {Object} theme - O tema do objeto extra.
 * @property {string} theme.uuid - O UUID do tema.
 * @property {string} theme.name - O nome do tema.
 * @property {string} theme.backgroundColor - A cor de fundo do tema.
 * @property {string} theme.foregroundColor - A cor do texto do tema.
 * @property {string|null} picture - A imagem relacionada ao objeto extra (pode ser nula).
 * @property {string} typeApresentation - O tipo de apresentação do objeto extra.
 * @property {boolean} iframe - Indica se o objeto extra usa iframe.
 * @property {string} mimeType - O tipo MIME do objeto extra.
 * @property {string} url - A URL relacionada ao objeto extra.
 * @property {string|null} arquivoExtra - Arquivo extra relacionado ao objeto extra (pode ser nulo).
 */

class Extra extends Component {
	largura=this.props.isDesktopMode? window.screen.width-window.screen.width*0.06:window.screen.width-window.screen.width*0.0;

	alturaVideo=this.props.isDesktopMode? window.screen.height-window.screen.height*0.38:((window.screen.width*0.8)/16)*9;
	larguraVideo=this.props.isDesktopMode? window.screen.width-window.screen.width*0.06:window.screen.width*0.8;
	service=new ExtraService()
	agendaService=new AgendaService()

	usuarioService=new UsuarioService();

	state={
	  codigo: null,
	  codigoAtividade: null,
	  action: null,
	  extra: {
	    uuid: '',
	    title: '',
	    code: '',
	    name: '',
	    description: '',
	    presentation: '',
	    active: false,
	    picture: null,
	    typeApresentation: '',
	    iframe: false,
	    mimeType: '',
	    url: '',
	    arquivoExtra: null,
	  },
	  processando: false,
	  redirecionando: false,
	  tipoApresentacao: null,
	  sizeScreen: window.innerWidth,
	  erro: {
	    isWrong: false,
	    titulo: null,
	    mensagem: null,
	  },
	  fileUrl: '',
	  numPages: null,
	  pageNumber: 1,
	  erroPdf: true,

	}

	componentDidMount() {
	  if(this.state.processando) {
	    return
	  }
	  this.setState({processando: true})
	  const codigo=this.props.match.params.codigo;
	  const codigoAtividade=this.props.match.params.codigoAtividade;

	  this.service.iniciarConteudoExtra(codigo,codigoAtividade,(erro,action) => {
	    this.setState({processando: false})
	    if(action) {
	      this.agendaService.atualizarAgenda(codigo,codigoAtividade,"EXTRA",null,null,(error,sucesso) => {
	        if(error) {
	          alert(this.props.i18n.message("dinamica.erro.jaexecutada.mensagem","Falha ao atualizar informações de atividade executadas, entre em contato com o suporte"))
	        }
	      })
	    }
	    if(erro) {
	      alert(JSON.stringify(erro))
	      return
	    }
	    this.setState({
	      codigo: codigo,
	      codigoAtividade: codigoAtividade,
	      action: action,
	      tipoApresentacao: action.extra.typeApresentation,
	      extra: action.extra

	    })

	    if(action.extra.typeApresentation === 'REDIRECT'){
	      window.location = action.extra.url;
	    }

	    this.getExtraArquivo(this.state.extra)
	    this.setState({redirecionando: false})
	  })


	}

	createYouTubeEmbedLink=(link) => {
	  let videoId=null;
	  let queryParamsIndex=null;
	  let embedUrl=null;
	  if(link.includes("?v")){
	    videoId=link.split("https://www.youtube.com/watch?v=")[1];
	    embedUrl=`https://www.youtube.com/embed/${videoId}`;
	    queryParamsIndex=videoId.indexOf("&");
	  }else if(link.includes("youtu.be")){
	    videoId=link.split("https://youtu.be/")[1];
	    embedUrl=`https://www.youtube.com/embed/${videoId}`;
	    queryParamsIndex=videoId.indexOf("&");
	  }

	  if(queryParamsIndex!==-1) {
	    // A URL já possui parâmetros adicionais.
	    const queryParams=videoId.slice(queryParamsIndex+1);
	    return `${embedUrl}?${queryParams}&autoplay=1`;
	  } else {
	    // A URL não possui parâmetros adicionais.
	    return `${embedUrl}?autoplay=1`;
	  }
	};

	createGoogleDocEmbedLink=(link) => {
	  return link.replace("view?usp=sharing","preview");
	}


	createVimeoIframe=(link) => {

	  return (
	    <div className='columns iframe-extra'>
	      <div className='column is-full text-align' >
	        <Vimeo
	          video={link}
	          width={this.larguraVideo}
	          height={this.alturaVideo}
	          autoplay
	        > </Vimeo>
	      </div>

	    </div>

	  )
	}

	verificarseURLContemYoutube=(url) => {
	  return url.includes("youtube.com") || url.includes("youtu.be");
	}
	verificarSeUrlGoogleDrive=(url) => {
	  return url.includes("drive.google.com");
	}

	verificarSeUrlIsIframe=(url) => {
	  return url.includes("<iframe");
	}

	verificarSeUrlVimeo=(url) => {
	  return url.includes("vimeo.com");
	}

	gerarHtmliframe(url) {
	  return (
	    <div className='columns iframe-extra'
	      dangerouslySetInnerHTML={{__html: url}} >

	    </div>
	  )
	}

	gerarHtmlLink(url) {
	  return (
	    <div className=''>
	      <div className='is-full'>
	        <iframe
			  id='iframe-extra'
	          src={url}
	          allowFullScreen
	          title='Atividade extra'
	          allow="autoplay; picture-in-picture"
			  webkitallowfullscreen="webkitallowfullscreen"
			  allowfullscreen="allowfullscreen"
			  mozallowfullscreen="mozallowfullscreen"
			  msallowfullscreen="msallowfullscreen"
			  oallowfullscreen="oallowfullscreen"
			  />
	      </div>
	    </div>
	  )
	}

	getExtra=(isIframe,url) => {
	  if(this.verificarSeUrlVimeo(url) ) {
	    return this.createVimeoIframe(url)
	  } else if(isIframe) {
	    return this.gerarHtmliframe(url)
	  } else {
	    return this.gerarHtmlLink(url)
	  }

	}


	iframeRender = () => {
	  const { extra } = this.state;
	  if((this.state.extra.typeApresentation==='REDIRECT') ) {
	    window.location=extra.url;

	  } else {
	    return this.iframeHandler(extra)
	    }
	  }

	/**
 * Executa o tipo de apresentação.
 *
 * @param {ExtraObject} extra - O objeto extra a ser processado.
 */
	iframeHandler =  (extra) => {
	  const {history}=this.props;
	  let isIframe;
	    let url;
	    if(extra.url) {
	      url=extra.url;
	      isIframe=this.verificarSeUrlIsIframe(url);
	      if(this.verificarseURLContemYoutube(url)) {
	        url=this.createYouTubeEmbedLink(url)
	      }
	      if(this.verificarSeUrlGoogleDrive(url)) {
	        url=this.createGoogleDocEmbedLink(url)
	      }
	      if(this.verificarSeUrlVimeo(url)) {
	        isIframe=true;
	      }
		  if(extra.mimeType !== 'application/pdf') {
	      return (
	        <div className='columns'>
	          <div className='is-full'>

	              <div className='desktop-voltar'>
	                <BotaoAsALinkIconeEsquerda
	                  icon="fas fa-arrow-left "
	                  classeComplementar="header-como-funciona-desktop-botao"
	                  nome={this.props.i18n.message("geral.voltar","Voltar")}
	                  ariaLabelMsg={this.props.i18n.message("aria.label.geral.mensagem.voltar.atividades","Volta para a página de atividades.")}
	                  funcao={() => {
	                    history.push("/home")
	                  }}
	                />
	              </div>

	            <div className='extra-iframe'>
	              {this.getExtra(isIframe,url)}
	            </div>

	          </div>

	        </div>
	      )
	      }
	  }
	}

	voltar=() => {
	  window.location="/"
	}
	/**
 * Executa o tipo de apresentação.
 *
 * @param {ExtraObject} extra - O objeto extra a ser processado.
 */
	getExtraArquivo(extra){
	  const usuarioLogado=this.usuarioService.usuarioCorrente()


	  if(extra.typeApresentation !== 'REDIRECT' && (extra.url==='' || extra.url === undefined || extra == null) ){
	  this.service.getConteudoExtra(usuarioLogado.participantCode,this.state.codigoAtividade).then(fileRequest => {
	    const file=new Blob([fileRequest.data],{type: extra.mimeType});
	    const fileUrl=URL.createObjectURL(file);
	    this.setState({
	      fileUrl: fileUrl,
		  extra: {...extra, url: fileUrl}
	    })
	  })
	  }else{
	    this.setState({
	      fileUrl: extra.url
	    })
	  }



	}


	renderIframePdf(){
	  if(this.state.fileUrl){
	    return (
	      <div className='has-text-centered mt-5' >
				  <Document
				    error={this.errorPdf()}
				  	file={this.state.fileUrl}
					  loading={this.props.i18n.message("extra.pdf.carregando","Carregando PDF...")}
				  onLoadSuccess={this.onDocumentLoadSuccess}
				  onLoadError={ () => this.setState({erroPdf:true})}

				  >
	          <Page
				 renderAnnotationLayer={false}
				 width={this.props.isDesktopMode? '':360}
				 scale={this.props.isDesktopMode? 1.5:1}
			  pageNumber={this.state.pageNumber} />
				  </Document>
	          <div className='has-text-centered mt-5'>
	          <p>
	            {this.props.i18n.message("extra.pdf.pagina", "Pagina")} {this.state.pageNumber || (this.state.pageNumber ? 1 : '--')} {this.props.i18n.message("extra.pdf.pagina.de", "de")} {this.state.numPages || '--'}
	          </p>

			  <div className='is-flex is-justify-content-center mt-2'>
	            <div className='m-3' >
	              <BotaoSecundario
			  nome={this.props.i18n.message("extra.pagina.anterior","Anterior")}
			  funcao={this.previousPage}
			  disabled={this.state.pageNumber <= 1}
			  classeComplementar='button p-2'
			  ></BotaoSecundario>
			  				</div>
	            <div className='m-3'>
	              <BotaoSecundario
			  nome={this.props.i18n.message("extra.pagina.proxima","Próxima")}
			  funcao={this.nextPage}
			  disabled={this.state.pageNumber >= this.state.numPages}
			  classeComplementar='button p-2'
			  >

			  </BotaoSecundario>
	            </div>



			  </div>

	        </div>
	        <div>
	          {this.pdfDownload()}
	        </div>
				  </div>
			  )
	  }

	}
	previousPage= () => {
	  this.changePage(-1);

	}

	nextPage= () => {
	  this.changePage(1);

	}

	changePage = (offset) => {
	  this.setState({pageNumber: this.state.pageNumber + offset});
	}



	onDocumentLoadSuccess = ({ numPages }) => {
	  this.setState({
	    numPages,
	    pageNumber: 1
	  })
	  }


	errorPdf= () => {
	  return (
	    <div className='is-flex is-justify-content-center'>
	      <p> {this.props.i18n.message("extra.pdf.erro.carregar","Não foi possível carregar o PDF")} </p>
	    </div>

	  )
	}


	pdfDownload = ()=>{

	  return (
	    <div className='is-flex is-justify-content-center mt-5 mb-3'>
	      <div className=''>
	        <BotaoPrimario
	          nome={this.props.i18n.message("extra.pdf.botao.download","Baixar arquivo")}
	          funcao={this.baixarArquivo}
			  classeComplementar='px-4'
	        >

	        </BotaoPrimario>
	      </div>
	    </div>
	  )

	}

	baixarArquivo= () => {
	    const link = document.createElement('a');
	    link.href = this.state.fileUrl;
	    link.setAttribute('download', `${this.state.extra.title}.pdf`);
	    document.body.appendChild(link);
	    link.click();


	}



	renderExtraArquivo() {
	  const {history}=this.props;
	  if(this.state.extra.mimeType==='application/pdf'){
	  return (
	    <>
	      <div className=''>
	        <div className=''>

	          <div className='desktop-voltar mb-3'>
	            <BotaoAsALinkIconeEsquerda
	              icon="fas fa-arrow-left "
	              classeComplementar="header-como-funciona-desktop-botao"
	              nome={this.props.i18n.message("geral.voltar","Voltar")}
	              ariaLabelMsg={this.props.i18n.message("aria.label.geral.mensagem.voltar.atividades","Volta para a página de atividades.")}
	              funcao={() => {
	                history.push("/home")
	              }}
	            />
	          </div>
	          <div className=''>
				  {this.renderIframePdf()}
	          </div>

	        </div>

	      </div>

	    </>

	  )}
	  return null;
	}

	render() {
	  let i18n=this.props.i18n

	  const extra=this.state.extra
	  if(!extra) {
	    setTimeout(() => {
	      return <p>{i18n.message("dinamica.extra.semConteudo","Sem conteúdo...")}</p>
	    },2000);
	  }
	  return (
	    <ExtraStyle tamanho={this.largura}>
	      <div>
	        <div className="card weex-card-interno">
	          <div>
	            <div className="card-content">


	              {this.state.tipoApresentacao===null?
	                (
	                  <>
	                    <div style={{width: '100%',textAlign: 'center'}}>
	                      <p>{i18n.message("geral.redirecionando","Aguarde...")}</p>
	                    </div>
	                    <BotaoAsALink
	                      nome={i18n.message("missaoCumprida.repetir","Voltar")}
	                      ariaLabelMsg={i18n.message("aria.label.geral.mensagem.voltar.atividades","Volta para a página de atividades.")}
	                      funcao={this.voltar}
	                    />
	                  </>
	                ):""
	              }
	              {this.state.tipoApresentacao==='REDIRECT'?
	                (
	                  <>


	                    <DinamicasHeader
	                      dinamica={extra}
	                      theme={extra?.theme}
	                      dinamicaNome={i18n.message("dinamica.extra.nome","Extra")}
	                      dinamicaAlt={i18n.message("alt.dinamica.extra","Ícone de uma mão clicando em um ponto especifíco da página.")}
	                      dinamicaImg={this.props.location.state.icon||"/new_board.svg"}
	                    />
	                    <div style={{width: '100%',textAlign: 'center'}}>
	                      <p>{i18n.message("geral.redirecionando","Aguarde...")}</p>
	                    </div>
	                    <BotaoAsALink
	                      nome={i18n.message("missaoCumprida.repetir","Voltar")}
	                      ariaLabelMsg={i18n.message("aria.label.geral.mensagem.voltar.atividades","Volta para a página de atividades.")}
	                      funcao={this.voltar}
	                    />
	                  </>
	                ):(
	                  <>
	                    {this.iframeRender()}
	                  </>
	                )

	              }
	              {this.renderExtraArquivo()}
	            </div>
	          </div>
	        </div>
	      </div>
	    </ExtraStyle>
	  )
	}
}

const mapStateToProps=function(state) {
  return {
    i18n: state.i18n,
    isDesktopMode: state.isDesktopMode

  }
}

export default connect(mapStateToProps)(Extra)
