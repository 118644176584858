import React from 'react';
import { DinamicasHeaderStyle } from './style';

function DinamicasHeader(props) {

  return (<>
    <DinamicasHeaderStyle>
      <div>
        <h3 className='dinamica-title' >
          {props.dinamica?.title
            ? (props.dinamica?.title)
            : (props.dinamica?.name || props.theme?.name)}
        </h3>
        <div
          className='dinamica-nome'
          aria-disabled="true"
          aria-hidden="true"
        >
          <img
            alt={props.dinamicaAlt}
            src={props.dinamicaImg}
            className={props.type === 'video' ? 'dinamica-img' : 'dinamica-img filter-color'} />
          <span>{props.dinamicaNome}</span>
        </div>
      </div>
    </DinamicasHeaderStyle>
  </>)
}

export default DinamicasHeader;
