import styled from "styled-components";

export const HomeStyle = styled.div`

    h3{
        color: ${props => { return props.theme.cardLogadoTextoPrimeiro}};
    }
.contador-campanha-container__span {
	font-weight: 600;
}

.info-campanha-container {
	height: 65vh;
	width: 100vw;
	margin-left: -1.5em;
	margin-right: -1.5em;
	padding-top: 10vh;
	text-align: center;
	background-repeat: no-repeat;
	background-position: center top;
	background-size: cover;
	position: absolute;
	top: 2px;
}

.info-campanha-container-interesse{
	margin-left: 0.1em;
	margin-right: 0.1em;
	margin-bottom: 20px;
	text-align: center;
}

.info-campanha-container__span {
	font-weight: 700;
	font-size: 1.1rem;
	color: ${props => { return props.theme.cardLogadoTextoPrimeiro}};
}

.info-campanha-container__span__data {
	font-size: 1.55rem;
}

.contador-campanha-container {
	width: 80vw;
	height: 30vh;
	padding-top: 5vh;
	margin-left: 10vw;
	margin-right: 10vw;
	left: 0px;
	position: absolute;
	top: 65vh;
}

.contador-campanha-container-interesse{
	margin-top: 20px;
	padding-left: 1.1em;
	padding-right: 1.1em;
	position: static;
	overflow-x: hidden;

}


@media screen and (min-width: 700px){
	.weex-content-home{
		margin-top: 10vh;
	}

	.contador-botoes{
		min-height: 20vh;
		display: flex;
		flex-direction: row;
		justify-content: flex-end;
	}
	.content-botoes{
		margin-top: 15vh;
		margin-right: 1em;
		margin-left: 1em;
	}
	
}



/* media query min-width 280px
@media screen and (max-width: 280px) {
	.interesse-mostrar {
		min-width: 220px;
	}
} */

`