import instance from './Api';
import UsuarioService from './UsuarioService';


export default class TrofeuService {

  /**
     * Retorna a lista de troféus a que o usuário tenha acesso usando
     * uma estrutura similar à da agenda
     * {
     *    trofeuCampanha:
     *    dias:[
     *      {
     *         trofeuDia:
     *         atividades:[
     *            {trofeuAtividade}
     *         ]
     *      }
     *    ]
     * }
     */
  obterTrofeus(callback) {
    let usuarioCorrente = new UsuarioService().usuarioCorrente();
    let url = this.host() + "/api/v1/participant/" + usuarioCorrente.participantCode + "/achievements"
    instance.get(url)
      .then((response) => {callback(null, response.data)})
      .catch((error) => {callback(error,null)})
  }


  host() {
    return process.env.REACT_APP_HOST_API
  }
}