import styled from "styled-components";


function hexToRGB(hex, alpha) {

  var r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
  } else {
    return "rgb(" + r + ", " + g + ", " + b + ")";
  }
}


export const Weex = styled.div`
display: flex;
flex-direction: column;
min-height: 100vh;
background: ${props => {
    if ((props.usuarioLogado !== null && props.usuario === true) || (props.usuarioLogado !== undefined && props.usuario === true)) {
      return props.theme.fundoSecundario
    } else {
      return props.theme.fundoPrimario || `#F3F5F9`
    }
  }};

.weex-container{
	padding-left: 1em;
	padding-right: 1em;
	padding-top: 0.5em;
	padding-bottom: ${props => {
    if ((props.usuarioLogado !== null && props.usuario === true) || (props.usuarioLogado !== undefined && props.usuario === true)) {
      return `0em`
    } else {
      return `0px`
    }
  }};
}

.visually-hidden {
  /* position: absolute !important; */
  clip: rect(1px 1px 1px 1px); /* esconde visualmente, mas ainda mantém disponível para leitores de tela */
  padding: 0 !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  overflow: hidden;
  display: block;
}



.weex-content{
	background: ${props => { return props.theme.cardLogadoFundo }};
}



.close-btn{
	border: none;
	background: transparent;
	padding: 0px;
	margin: 0px;
	cursor: pointer;

	:active{
		filter: brightness(0.5);
	}
}

.img-close{
	position: absolute;
  right: 1em;
}

.div-close{
	align-items: center;
	display: flex;
	padding: 2em;
	position: relative;
}

.div-version{
	margin-top: 2em;
	text-align: center;
	color: ${props => { return props.theme.textosCorFundoPrimario}};
}

.link-sublinhado{
	text-decoration: underline;
}

.version-label{
	font-weight: 800;
}

.weex-container-logado-grow{
	flex-grow: 1;
	margin-top: 2em;
}

.weex-main-content-border{
	border-radius: 30px 30px 0px 0px;
}

.weex-cabecalho{
	padding-bottom: 1.5em;

	.campanha_logosTopo_name, .campanha_logosTopo_subtitle{
		color: ${props => { return props.theme.textosCorFundoSecundario }};
	}
}

.weex-header-menu{
	background: ${props => { return props.theme.fundoSecundario }};
	position: absolute;
	z-index: 101;
	top: 0;
	left: 0;
    right: 0;
}

.botao-flutuante{
	position: 'sticky';
    bottom: 0;
    width: '100%';
}


.textos-cor-secundaria{
	background: ${props => {
    if (((props.usuarioLogado !== null && props.usuario === true) || (props.usuarioLogado !== undefined && props.usuario === true)) && (props.theme.fundoSecundario)) {
      var rgb = props.theme.fundoSecundario
      return hexToRGB(rgb, 0.05)
    } else {
      return props.theme.fundoPrimario || `#F3F5F9`
    }
  }};
}
.weex-header-menu-content{
	margin-top: 6em;
	margin-left: 2em;
	margin-right: 2em;
	margin-bottom: 2em;

	ul{
		text-align: center;
		li{
			margin-bottom: 20px;

			a, .button-link{
				text-transform: uppercase;
				font-size: 16px;
				line-height: 14px;
  				font-weight: 700;
				color: ${props => { return props.theme.textosCorFundoSecundario }};

				:active{
					filter: opacity(0.5);
				}
			}

			.button-link:last-child{
				text-transform: capitalize;
			}

			p{
				color: ${props => { return props.theme.textosCorFundoSecundario }};
				font-weight: bold
			}
		}
	}
}

.button-link{
	background: none;
	border: none;
	cursor: pointer;
}

.weex_select_campaign_container {
    border-radius: 8px;
    border: none;
    margin-top: 2.0em;
    padding: 1.0em;
    margin-bottom: 1.0em;
}

.menu {
	max-width: 20px;
	position: relative;
	z-index: 102;
}

.sala-de-trofeus{
	max-width: 1.875rem;
	:active{
		filter: opacity(0.5);
	}
}

a, .weex-link, .button-link{
	color: #201D1D;
    font-weight: 700;
	font-size: 12px;
	line-height: 14px;
}

label{
	font-weight: 700;
	font-size: 12px;
	line-height: 12px;
	color: #424A57;
}

.input::placeholder {
    font-weight: 300;
	font-size: 14px;
	line-height: 12px;
	color: #847D92;
}

.input-size{
	padding-top: 1.8em;
	padding-bottom: 1.8em;
	padding-left: 1em;
}

.select-input, select{
	height: 4em;
}

.input, select, textarea {
    border-left: 5px solid ${props => {
    if (props.backgroundIsPresent) {
      return props.theme.fundoTres
    } else {
      return props.theme.fundoSecundario || `#533888`
    }
  }};
    font-weight: 300;
	font-size: 14px;
	line-height: 12px;
	background: #FFFFFF;
}

.input:focus, select:focus, textarea:focus{
    border-color: ${props => {
    if (props.backgroundIsPresent) {
      return props.theme.fundoTres
    } else {
      return props.theme.fundoSecundario || `#533888`
    }
  }};
}

.input:hover, select:hover, textarea:hover {
	border-left: 5px solid ${props => {
    if (props.backgroundIsPresent) {
      return props.theme.fundoTres
    } else {
      return props.theme.fundoSecundario || `#533888`
    }
  }};
}

.interesse-content{
	h3{
        color: ${props => { return props.theme.cardLogadoTextoPrimeiro }};
        margin-top: 20px;
        margin-bottom: 10px;
    }

    h4{
        color: ${props => { return props.theme.cardLogadoTextoSegundo }};
    }
}

.weex_footer {
	text-align: center;
	flex-grow: 0;
}

.weex_footer img {
	max-width: 30%;
}

.retirar-padding{
	padding-top: 0;
}

.versao{
  font-size: 0.7rem;
  text-transform: lowercase;
}

/**
	Cabeçalho e subcabeçalho
*/
h1{
	text-align: center;
  	text-transform: uppercase;
	font-weight: 700;
	font-size: 18px;
	line-height: 18px;
}

h2{
	text-align: center;
	font-weight: 400;
	font-size: 12px;
	line-height: 18px;
}

/**
	Titulo e subtitulos dentro dos cards
*/

h3{
	text-align: center;
  	text-transform: uppercase;
	font-weight: 700;
	font-size: 16px;
	line-height: 15px;
	margin-top: 20px;
    margin-bottom: 10px;
}

h4{
	text-align: center;
	font-weight: 400;
	font-size: 13px;
	line-height: 16px;
}

.botao-invisivel + .botao-invisivel {
	border-left: solid 2px #ffffff;
}

.botao-invisivel {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.botao-invisivel {
	background: transparent;
	border: none;
	padding-right:0.5em;
	margin: 0px;
}

.card-atividades {
	display: flex;
	justify-content: center;
    border-radius: 8px;
}

.icone_atividades {
    width: 34px;
    height: 35px;
}

.trofeu__sala img{
    -webkit-transform: translateY(10px);
          transform: translateY(10px);
    max-height: 70px;
}

.card-atividades__imagem, card-atividades-trofeu__imagem {
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
}

.label-atividades{
    font-family: Poppins;
	font-style: normal;
	font-weight: lighter;
	font-size: 10px;
	line-height: 14px;
	color: black;
}

.text-area-feedback-video{
	max-height: 3rem;
	margin-top: 1rem;

}

.text-area-feedback-video::placeholder {
    font-weight: 300;
	font-size: 14px;
	line-height: 12px;
	color: #847D92;
}


.accep-legal-term{
	h3{
        color: ${props => { return props.theme.cardLogadoTextoPrimeiro }};
    }

    h4{
        color: ${props => { return props.theme.cardLogadoTextoSegundo }};
    }
}

.home-btn-padding-como-funciona{
	margin-bottom: 1rem;
}

.home-btn-padding-interesses{
	margin-bottom: 3.5rem;
}

.campanha_logosTopo__centro__footer {
	max-width: 30% ;
    margin-left: 1vw ;
}

.campanha_logosTopo__centro {
	max-width: 30% !important;
}

.campanha_logosTopo__btn {
	background-color: transparent;
    border: none;
	cursor: pointer;
}


.botao-video-desabilitado{
	background-color: #DFDDE3;
	color: #FFFFFF;
	cursor: not-allowed;
}

/**
	DINAMICAS WEEX 2.0
*/

.dinamica-container {
	overflow: hidden;
}

.weex-card-container-imagem {
	display: flex;
	justify-content: center;
	margin-top: 3vh;
}

.card, .card-content{
	background: transparent;
	box-shadow: none;
}

.weex-card-interno__content {
	min-height: 30vh;
	margin-bottom: 3vh;
}

.weex-card-interno__content__fato-fake__novos-habitos {
	min-height: 5vh;
	padding-bottom: 3vh;

	.markup__fato-fake{
		p{
			text-align: center;
			color: ${props => { return props.theme.cardLogadoTextoPrimeiro }};
			font-weight: 400;
			font-size: 18px;
			line-height: 24px;
		}
	}
}

.video-quiz-feedback-markup {
		p{
			text-align: center;
			color: ${props => { return props.theme.cardLogadoTextoPrimeiro }};
			font-weight: 500;
			font-size: 14px;
			line-height: 1.5;
		}
	}
	.quiz-markup-answers{
		ul{
			list-style-type: none;
			padding: 0;
			margin: 0;
			text-align: center;

			li{
				label{
					color: ${props => { return props.theme.cardLogadoTextoSegundo }};
				font-weight: 400;
				font-size: 14px;
				line-height: 15px;
				}
			}
		}
}

.quiz-question {
	margin-bottom: 20px;
}

.marcador-progresso {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	flex-direction: row;
	width: 80%;
	margin-left: 10%;
	margin-right: 10%;
	padding-top: 2rem;
	padding-bottom: 2rem;
}

.marcador {
	border-radius: 100%;
	background: #DFDDE3;
	float: left;
}

.marcador-concluido {
	background: ${props => { return props.theme.fundoSecundario }};
}

.weex-button__ff{
	padding-top: 5px;
	padding-bottom: 5px;
	width: 45%;
	font-weight: 700;
	letter-spacing: 0.2rem;
	color: #777777;
}

.weex-card-ff-certo{
	background: rgba(0, 226, 145, 0.1);
	border: 2px solid #00E291;
	border-radius: 14px;
	position: relative;

	.tag-acertou-container{
		position: relative;
  		margin-top: -15px;
		span{
			padding-top: 15px;
			padding-bottom: 15px;
			padding-left: 30px;
			padding-right: 30px;
			border-radius: 8px;

			color: #FFFFFF;
			font-weight: 700;
			font-size: 14px;
			line-height: 14px;
			letter-spacing: 2px;
			text-transform: uppercase;
			background: #00E291;
			box-shadow: 0px 20px 40px -10px rgba(119, 119, 119, 0.23);
		}
	}

	.tag-resposta{
		padding: 30px;
		p{
			text-align: center;
			color: ${props => { return props.theme.cardLogadoTextoPrimeiro }};
		}
	}
}

.weex-card-ff-errado{
	background: #FFF7F7;
	border: 2px solid #FE001A;
	border-radius: 14px;
	position: relative;

	.tag-errou-container{
		position: relative;
  		margin-top: -15px;
		span{
			padding-top: 15px;
			padding-bottom: 15px;
			padding-left: 30px;
			padding-right: 30px;
			border-radius: 8px;

			color: #FFFFFF;
			font-weight: 700;
			font-size: 14px;
			line-height: 14px;
			letter-spacing: 2px;
			text-transform: uppercase;
			background: #FE001A;
			box-shadow: 0px 20px 40px -10px rgba(119, 119, 119, 0.23);
		}
	}

	.tag-resposta{
		padding: 30px;
		div {
			p{
				text-align: center;
				color: ${props => { return props.theme.cardLogadoTextoPrimeiro }};
			}
		}
	}
}

.weex-card-header {
	text-align: center;
	display: block;
	font-size: 2rem;
	font-weight: 600;
	padding: 0.75rem 1rem 0.75rem 1rem;
}

.weex-como-funciona{
	margin-top: 40px;
	color: ${props => { return props.theme.cardLogadoTextoPrimeiro }};

	button{
		margin-top: 50vh;
	}

	ul{
		list-style: unset;
		padding-left: 40px;
		margin-top: 16px;
		margin-bottom: 16px;
	}
}

.redireciona-campanha-field{
  margin-top: 0.75rem;
}

.select-margin-top-control{
	margin-top: 0.5em;
}

.redireciona-campanha-container-btn{
  text-align: center;
  margin-top: 20px;
  margin-bottom: 10px;
}

.legalTerm-field{
	margin-top: 0.75rem;
}

.legalTerm-Title{
  font-size: 1.2rem;
  text-align: center;
}

.legalTerm-msg{
  text-align: center;
}
.legalTerm-btn{
  text-align: center;
  margin-top: 20px;
  margin-bottom: 10px;
}

.card-atividades__img {
	max-height: 100%;
}

.card-atividades__disabled {
	background: transparent;
}

.card-atividades__primeiro {
	border-bottom-left-radius: 1rem;
	border-top-left-radius: 1rem;
}

.card-atividades__ultimo {
	border-bottom-right-radius: 1rem;
	border-top-right-radius: 1rem;
}

.weex-botao-confirmacao__texto-comprido__missao-cumprida {
	z-index: 100;
	position: relative;
}


.videoPlayerContainer {
	display: flex;
	justify-content: center;
	align-items: center;
	box-shadow: 0px 0.45rem 1rem 0.25rem rgba(255, 255, 255, 0.5);
}

.trofeu {
	height: 20vh;
	margin-top: 5vh;
	margin-bottom: 5vh;
}

.weex-select {
	width: 100%;
}

.weex-select select {
	width: 100%;
}

.legenda-input {
	font-size: 0.75rem;
}


.aguarde {
	width: 80%;
	margin: 0px;
	text-align: center;
	font-size: 1.2rem;
	font-weight: 600;
	padding-top: calc(50vh - (1rem + 1em + 2rem));
	position: absolute;
	z-index: 50;
	left: 10%;
}

.como-funcionar-titulo{
	display: none;
}
@media screen and (max-width: 600px) {
	.card-atividades {
		padding: 10px;
	}
}

.input, .input:focus, .input:active {
	border-left: 5px solid  ${props => {return props.theme.fundoSecundario || `#533888`}};
}

.card-atividades-trofeu__imagem{
		background-size: cover;
	}

.games-apresentacao{
	margin: 4% 4%;
}

.games-apresentacao > div {
	margin-top: 1rem;
}
/* #region DESKTOP */

/*media query min-width 700px*/
@media screen and (min-width: 700px) {
	.weex-container{
		margin-left: 2em;
		margin-right: 2em;
	}

	.weex-main-content-border{
		border-radius: 8px 8px 0px 0px;
	}

	.campanha_logosTopo__centro img {
		height: auto;
		max-width: 12vw !important;
		max-height: 10vh !important;
		margin-right: 4em;
	}
	.campanha_logosTopo__centro__notLogged{
		max-width: 15vw;
	}


	.sala-de-trofeus{
		:hover{
			filter: opacity(0.5);
		}
	}

	.weex_select_campaign_container_content{
		margin: 0 30vw 0 30vw;
	}
  .login {
	  width:30%;
	  display: flex;
	  flex-direction: column;
	  justify-content: center;
	  margin: auto;
	}
	.login-opcoes{
		margin-top: 1em;
	}

	.cadastro-desktop{
		display: block;
	}

	.espacamento-botao-login{
	  margin-top: 1em;
	}
	.login-opcoes> p > a, .button-link{
		font-size:1em;
	}


	.weex-container {
		display: flex;
		flex-direction: row;
		justify-content: center;
	}

	.box-custom{
            background: transparent;
            border: 1px solid #ccc;
            border-radius: 14px;
            position: relative;
     }

	 .input, .input:focus,.input:hover,  .input:active,select, select:focus, select:active, select:hover{
		border-left: 5px solid  ${props => {return props.theme.fundoSecundario || `#533888`}};
	}

	.icon-custom, .icon-custom-right{
		color :${props => {
    if ((props.usuarioLogado !== null && props.usuario === true) || (props.usuarioLogado !== undefined && props.usuario === true)) {
      return props.theme.fundoSecundario
    } else {
      return props.theme.fundoPrimario || `#F3F5F9`
    }
  }};
	}

	.marcador-progresso{
            padding-top: 1em;
            justify-content: center;
        }

        .marcador{
            margin: 0.3em;
        }

	.dinamica-content-desktop{
            margin: 1vw 5vw;
    }

	.full-width-desktop{
		width: 100%;
	}
	.modal-button-missaocomprida{
		width: 80%;
	}
	.campanha_logosTopo{
		min-height: 10vh;
	}
	.foreground_not_logged{
		margin-left: 25vw;
		margin-right: 25vw;
	}

	.foreground{
  color: ${props => {
    return props.theme.textosCorFundoSecundario }};
}

.foreground_not_logged{
  color: ${props => {
    return props.theme.textosCorFundoPrimario }};
}
	.card-atividades__imagem{
		background-size: 60%;
	}
	.card-atividades-trofeu__imagem{
		background-size: cover;
	}
	.icone_atividades {
		width : 4.0vw;
		height : 4.0vw;
	}
	.card-atividades {
		display: flex;
		justify-content: center;
		border-radius: 8px;
	}
	.campanha_logosTopo__centro__footer {
    margin : 0 ;
	}

	.weex_footer{
		margin: 0 35vw;
	}
	.weex_footer img {
		max-width: 100%;
	}
	.weex-container-logado-grow{
		margin-top: 0em;
	}

	.games-apresentacao > :not(div:first-child) {
		margin-top: 1.5rem;
	}

}

/* #endregion DESKTOP */
`
