import React, { useEffect, useState } from "react";
import { AnimateOnChange } from "react-animation";
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useHistory } from "react-router-dom";
import WeexMessage from "../comps/WeexMessage";
import WeexSpinner from "../comps/WeexSpinner/WeexSpinner";
import CampanhaService from "../services/CampanhaService";
import TemaService from '../services/TemaService';
import UsuarioService from "../services/UsuarioService";
import LoginCampaign from "./LoginRegister/LoginCampaign";
import { LoginRegisterContainerStyle, ModoStyle, WeexUserContainerStyle } from "./LoginRegister/style";
import RegisterSelector from "./RegisterSelector";



function LoginRegister(props) {
  const usuario = useSelector((state) => state.usuario);
  const i18n = useStore().getState().i18n
  const dispatch = useDispatch();

  const campanhaService = new CampanhaService();
  const usuarioService = new UsuarioService();
  const history = useHistory();
  const [campanha] = useState(campanhaService.campanhaCorrente());


  useEffect(() => {
    const temaService = new TemaService(campanhaService.campanhaCorrente())
    temaService.aplicarTema('ENTRADA')
    if(temaService.temBackGround()) {
      dispatch({type: "backgroundIsPresent", payload: true});
    }
    if (usuarioService.usuarioCorrente() && campanha) {
      campanhaService.contadorCampanha((erro, sucesso)=>{
        if(erro){

          console.error(erro);
          return;
        }
        if(sucesso){
          if(sucesso !== null){
            if(sucesso.length > 0 ){
              history.push('/atividades')
            } else {
              history.push("/home")
            }
          }
        }
      })
    }
    // TODO: Ao colocar as dependencias solicitadas pelo Lint, a aplicação fica em loop infinito no useEffect --> realacionada tarefa 14797 --> By Renan
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const [modo, setModo] = useState(
    campanhaService.campanhaCorrente() ? (
      campanhaService.campanhaCorrente().restrictEligibility
        ? "login"
        : "register"
    ) : 'notfound'
  );

  if (!campanha) {
    history.push("/select")
    return null;
  }

  const modoLogin = () => {
    props.callbackModo("login")
    setModo("login");
  }

  const modoRegister = () => {
    props.callbackModo("register")
    setModo("register");
  }

  window.addEventListener('resize', function(){
    const temaService = new TemaService(campanhaService.campanhaCorrente())
    temaService.aplicarTema('ENTRADA')
    if(temaService.temBackGround()) {
      dispatch({type: "backgroundIsPresent", payload: true});
    }
  } )
  const exporRegistro = !campanha.restrictEligibility;
  return (
    <LoginRegisterContainerStyle>

      {
        usuario === true ?
          (
            <WeexSpinner aguarde={i18n.message("geral.aguarde", "Aguarde...")} />
          ) :
          (
            <WeexUserContainerStyle>

              {exporRegistro ? (
                <div style={{ textAlign: "center", marginBottom: "1.2em", textTransform: 'capitalize'}}>



                  <div>
                    <ModoStyle>
                      {
                        modo === "register" ?
                          (
                            <li>
                              <WeexMessage code="registro.action.registre" default="CADASTRO"/>
                            </li>
                          ) : null
                      }
                    </ModoStyle>
                  </div>
                </div>
              ) : null}

              <AnimateOnChange style={{ width: "100%" }}>
                {modo === "register" ? (
                  <RegisterSelector
                    callbackLogin={props.callbackLogin}
                    campanha={campanha}
                    modoLogin={modoLogin}
                  />
                ) : (
                  <LoginCampaign
                    callbackLogin={props.callbackLogin}
                    campanha={campanha}
                    modoRegister={modoRegister}
                  />
                )}
              </AnimateOnChange>
            </WeexUserContainerStyle>
          )
      }
    </LoginRegisterContainerStyle>
  );
}
export default LoginRegister;
