import React, { useEffect } from "react";
import { useStore } from "react-redux";
import CampanhaService from "../../../services/CampanhaService";
import TemaService from "../../../services/TemaService";
import InteresseDesktop from "./InteresseDesktop";
import { InteresseDesktopStyle } from "./InteresseDesktopStyle";

export default function InteressesDesktop(props) {
  const campanhaService = new CampanhaService();
  const i18n = useStore().getState().i18n;
  let campanhaCorrente = campanhaService.campanhaCorrente();

  let logoCentro = "";

  useEffect(() => {
    let temaService = new TemaService(new CampanhaService().campanhaCorrente());
    temaService.aplicarTema("HOME");
  }, []);

  if (campanhaCorrente) {
    if (!campanhaCorrente.topLeftDesktop) {
      logoCentro = "";
    } else {
      logoCentro = campanhaCorrente.topLeftDesktop;
    }
  }
  return (
    <>
      <InteresseDesktopStyle>
        <div className="interesse-content">
          <div className="interesse-content-desktop">
            <div className="cabecalho-interesse">
              <div className="cabecalho-interesse-content">
                {logoCentro ? (
                  <img
                    width="30%"
                    src={logoCentro}
                    alt={i18n.message(
                      "alt.logo.principal.desktop",
                      "Logo principal da empresa"
                    )}
                  />
                ) : (
                  ""
                )}

                <h1 className="interesse-titulo-desktop">
                  {props.titleMessage}
                </h1>
                <h2 className="interesse-titulo-desktop">
                  {props.selectMessage}
                </h2>

                <hr />
              </div>

              <div></div>
            </div>
            <div>
              <div className="interesse-title-escolha">
                <div className="interesse-tilte-block"></div>
                <div className="interesse-title-content">
                  <p className="title is-6">
                    {i18n.message("interesse.escolha", "Você escolheu:")}{" "}
                  </p>
                </div>
              </div>
              <InteresseDesktop
                campanha={props.campanha.interests}
                beginDate={props.beginDate}
                now={props.now}
                daysOfEvent={props.daysOfEvent}
                canSelectInterets={props.canSelectInterets}
                requiredInterests={props.requiredInterests}
              />
            </div>
          </div>
        </div>
      </InteresseDesktopStyle>
    </>
  );
}
