import styled from "styled-components";

export const ButtonVideoMuralStyle=styled.button`
  width: 100%;
  border: none;
  color: ${props => {return props.theme.textosCorFundoSecundario||`rgb(239, 231, 102)`}};
  cursor: pointer;
  font: inherit;
 background-color: transparent;
.mural-label{
	  display: flex;
	  justify-content: center;
	  align-items: center;
	  font-weight: 700;
		font-size: 12px;
		line-height: 12px;
}

button:disabled + .mural-label{
	cursor: not-allowed;
}
.label-content{
	margin-top: 0.3em;
	margin-left:0.3em;
}

:disabled{
	cursor: not-allowed;
    filter: opacity(0.5);
}

:disabled + label {
	cursor: not-allowed;
}

  @media screen and (max-width: 600px) {
      padding-left: 0em;
      padding-right: 0em;
  }

`
