import React from 'react';
import { HideUntilLoaded } from 'react-animation';
import { connect } from 'react-redux';
import BotaoPrimario from './botaoPrimario/BotaoPrimario';
import BotaoSecundario from './botaoSecundario/BotaoSecundario';

function CardRespostaFatoFake(props) {

  let i18n = props.i18n
        
  return (
    <div className="card weex-card-interno">
      <div className="conteudo-card-atividades">
        <div className="card-image">
          <HideUntilLoaded style={{ width: "100%" }}/>
        </div>
        <div className="card-content" style={{marginTop: '2em'}}>
          <div 
            role="alert"
            className={
              props.resposta === props.fatoFake?.fact 
                ? `content weex-card-ff-certo weex-card-interno__content weex-card-interno__content__fato-fake__novos-habitos`
                : `content weex-card-ff-errado weex-card-interno__content weex-card-interno__content__fato-fake__novos-habitos`
            }
            style={{marginBottom: '4em'}}
          >
            <div 
              className={
                props.resposta === props.fatoFake?.fact 
                  ? `tag-acertou-container` 
                  : `tag-errou-container`
              } 
              style={{textAlign: 'center'}}
            >
              <span>
                {
                  props.resposta === props.fatoFake?.fact 
                    ? (<>{i18n.message("dinamica.fatofake.acertou", "ACERTOU!")}</>) 
                    : (<>{i18n.message("dinamica.fatofake.errou", "OPS!")}</>)
                }
              </span>
            </div>
            <div className='tag-resposta'>
              <div dangerouslySetInnerHTML={{__html: props.fatoFake?.answer}}/>
            </div>
          </div>
          <div className='botao-fatofake-desktop'>
            {props.ultimo 
              ? (
                <BotaoPrimario
                  funcao={props.finalizar}
                  nome={i18n.message("dinamica.fatofake.concluir", "Concluir atividade")}
                  ariaStatusMessage={i18n.message("aria.status.cardrespostafatofake.concluir", "Botão concluir atividade clicado")}
                />
              ) : (
                <BotaoSecundario
                  nome={i18n.message("dinamica.fatofake.proxima", "Avançar")}
                  funcao={props.proximoFatoFake}
                />
              )
            }
          </div>
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = function(state) {
  return {
    i18n:state.i18n
  }
}

export default connect(mapStateToProps)(CardRespostaFatoFake)