import React from 'react';
import UsuarioService from "../../services/UsuarioService";

/**
 * @param {Object} props
 * @param {string} props.title
 * @param {boolean} props.titleVisible
 * @param {string} props.campanhaName
 */
function InfosTextuais({
  campanhaName,
  title,
  titleVisible
}) {
  const usuarioService = new UsuarioService();
  let usuarioLogado = usuarioService.usuarioCorrente() && usuarioService.usuarioCorrente().token != null ? true : false;
  return (
    <>
      {campanhaName && titleVisible ? (
        <div className={usuarioLogado? "campanha_logosTopo_name foreground" : "campanha_logosTopo_name foreground_not_logged" }>
          <h1>
            {campanhaName}
          </h1>
        </div>
      ) : ""
      }           
      {title ? (
        <div className={usuarioLogado?"campanha_logosTopo_subtitle foreground": "campanha_logosTopo_subtitle foreground_not_logged"} style={{marginTop: '10px'}}>
          <h2>
            {title}
          </h2>
        </div>
      ) : ""
      }
    </>
  )
}

export default InfosTextuais;