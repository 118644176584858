import React, { Component } from "react";
import { connect } from "react-redux";
import RankingGameService from "../../services/RankingGameService";
import AtividadeEntity from "../DiaSemanaAgenda/AtividadeEntity";



class GameRankings extends Component {
    i18n = this.props.i18n;

    gameUuid = this.props.gameUuid;
    constructor(props) {
      super(props);
      this.state = {games: []};

    }


    componentDidMount(){
      const rankingService = new RankingGameService();
      this.setState({games: rankingService.getGamesAvailable()});
    }

    isSelected = (g) => {
      const game = new AtividadeEntity(g);
      return this.props.gameUuid === game.uuid;
    }

    gameItems = () => {
      const games = this.state.games;
      return games.map((g) => {
        const game = new AtividadeEntity(g);
        const dia = game.day.split("-")[2]
        return (
          <option selected={this.isSelected(game)}  key={game.uuid} value={game.uuid}>{`GAME DIA ${dia}  ${game.title ? game.title:game.name}`}</option>
        );
      });
    }


    gameSelected = (event) => {
      const selectedGame = this.state.games.find(game => game.uuid === event.target.value);
      if(selectedGame) {
        this.props.gameSelected(selectedGame);
      }
    }




    render() {

      return (

        <form>
          <div className="field">
            <div className="control">
              <select defaultValue={this.props.gameUuid}
                className="select-input input is-medium"
                onChange={this.gameSelected}
              >
                { this.gameItems() }
              </select>
            </div>
          </div>
        </form>


      );

    }

}

const mapStateToProps = function (state) {
  return {
    i18n: state.i18n,
    gameUuid: state.gameUuid
  }
}

export default connect(mapStateToProps)(GameRankings)
