import styled from "styled-components";

export const AtividadesStyle = styled.div`

.subtitle-content{
	h5{
		color: ${props => { return props.theme.cardLogadoTextoSegundo}};
		text-align: start;
		font-weight: 400;
		font-size: 13px;
		line-height: 16px;
	}
}

.atividades-header{
    h3{
        color: ${props => { return props.theme.cardLogadoTextoPrimeiro}};
        margin-top: 20px;
        margin-bottom: 10px;
    }

    h4{
        color: ${props => { return props.theme.cardLogadoTextoSegundo}};
    }


}

.agenda-container {
	margin-top: 4vh;
}
.botao-feedback{
    text-align: center;

}

/* #region DESKTOP */
@media screen and (min-width: 700px) {
    .agenda-container {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin-bottom: 2em;
    }

    .atv-card-desktop{
        margin-left: 2em;
    }
    .botao-feedback{
        padding : 2em 0 2em 0;
        text-align: center;
    }
    .label-atividades {
        font-size: 12px;
    }
}


/* #endregion DESKTOP */

`
