import instance from "./Api";
import CampanhaService from "./CampanhaService";

export default class PreSelecaoInteressesService {
	campanhaService = new CampanhaService();
	campanhaCorrente = this.campanhaService.campanhaCorrente();

	selecaoDeInteresses(interesse, callback) {
	  instance
	    .post(
	      this.host() +
					"/api/v1/campaign/" +
					this.campanhaCorrente.uuid +
					"/interests",
	      interesse
	    )
	    .then((response) => {
	      window.localStorage.setItem("interesse", JSON.stringify(response.data));
	      callback(null, response.data);
	    })
	    .catch((error) => {
	      callback(error, null);
	    });
	}

	host() {
	  return process.env.REACT_APP_HOST_API;
	}
}
