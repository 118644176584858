import CampanhaService from './CampanhaService';

const campanhaService = new CampanhaService();

export default class TemplateService {

  constructor(){
    this.template = campanhaService.templateCorrente();
    this.finalTemplate = {}
  }
  getTemplate(){
    return this.template;
  }
  listShadows(){
    return this.template.shadows
  }
  listColors(){
    return this.template.colors;
  }
  findShadowByCode(code){
    const shadow = this.template.shadows.filter(shadow => shadow.code === code);

    return shadow;
  }
  findColorByCode(code){
    const color = this.template.colors.filter(color => color.code === code);

    return color;
  }

  load(){
    this.init(this.template)
  }

  init(template){
    let finalTemplateObj = {}           
    if(template && template.colors && template.shadows) {
      for(let c of template.colors){
        finalTemplateObj[c.code] = c.color
      }
        
      for(let s of template.shadows){
        finalTemplateObj[s.code] = s.boxShadow
      }
    }
    this.finalTemplate = finalTemplateObj;

  }
}