import React, { useEffect, useState } from 'react';
import { ReCaptcha } from 'react-recaptcha-v3';
import { useStore } from 'react-redux';
import WeexMessage from '../../comps/WeexMessage';
import BotaoSecundario from '../../comps/botaoSecundario/BotaoSecundario';
import WeexModal from '../../comps/weexModais/WeexModal';
function CustomRegisterCampaignMobile(props) {
  const i18n = useStore().getState().i18n;
    
  const [selectsUnidades, setSelectsUnidades] = useState([]);
  const [unidadeMae, setUnidadeMae] = useState(null)
  const [subareas, setSubareas] = useState(null)
  const [subSubUnit, setSubSubUnit] = useState(null);

  useEffect(() => {
    const inputUnidades = () => {
      if (!props || !props.campanha || !props.campanha.client || !props.campanha.client.units) {
        return [];
      }
      let unidades = props.campanha.client.units;
      if (unidades && unidades.length > 0) {
        return unidades;
      }
      return [];
    };
    let unidades = inputUnidades();

    if (unidades) {
      setSelectsUnidades(unidades);
    }
    // TODO: Lint não deixa ser apenas uma array vazia -> Larissa (Tarefa Bug #15192)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mudarUnidade = (event, nivel) =>{
    event.preventDefault();
    const value = event.target.value;
    switch (nivel) {
    case 0:
      setUnidadeMae(value);
      setSubareas(null);
      setSubSubUnit(null);
      break;
    case 1:
      setSubareas(value);
      setSubSubUnit(null);
      break;
    case 2:
      setSubSubUnit(value);
      break;
    default:
      console.error("Nível desconhecido");
      break;
    }
    props.onChange(event);
  }

  const inputUnidade = () => {
    if(selectsUnidades && selectsUnidades.length > 0){
      return(
        <div className="field">
          <label className="label" htmlFor="selectUnit"><WeexMessage code="registro.campo.area" default={i18n.message('select.unidade.title',"Selecione sua área")}/>
            <div className="control select-margin-top-control">
              <select id="selectUnit" className='select-input input is-medium' value={unidadeMae} name="unit" onChange={(e) => mudarUnidade(e, 0)} required>
                <option value="">{i18n.message('select.unidade', 'Selecione uma unidade')}</option>
                {
                  selectsUnidades.map((opcao) => {
                    return <option value={opcao.uuid} key={opcao.uuid}>{opcao.name}</option>
                  })
                }
              </select>
            </div>
          </label>
        </div>
      )

    }  
    return null;
  }

  const inputSubUnidade = () => {
    const unidade = selectsUnidades.find((unidade) => unidade.uuid === unidadeMae);
    if (!unidade || !unidade.subUnits || unidade.subUnits.length === 0) {
      return null;
    }
    
    return(
      <div className="field">
        <label className="label" htmlFor="selectSubUnit"><WeexMessage code="registro.campo.subarea" default="Selecione sua subárea"/>
          <div className="control select-margin-top-control">
            <select id="selectSubUnit" className='select-input input is-medium' value={subareas} name="subUnit" onChange={(e) => mudarUnidade(e, 1)}>
              <option value="">{i18n.message('select.subárea', 'Selecione uma subárea')}</option>
              {
                unidade.subUnits.map((opcao) => {
                  return <option value={opcao.uuid} key={opcao.uuid}>{opcao.name}</option>
                })
              }
            </select>
          </div>
        </label>
      </div>
    )

    
  }

  const inputLevel3 = () => {
    const unidade = selectsUnidades.find((unidade) => unidade.uuid === unidadeMae);
    if (!unidade || !unidade.subUnits || unidade.subUnits.length === 0) {
      return null;
    }
    const subUnidade = unidade.subUnits.find(
      (subUnidade) => subUnidade.uuid === subareas
    );
    if (!subUnidade || !subUnidade.subUnits || subUnidade.subUnits.length === 0) {
      return null;
    }
    
    return(
      <div className="field">
        <label className="label" htmlFor="selectSubUnit"><WeexMessage code="registro.campo.nivel3" default="Selecione sua sub-subárea"/>
          <div className="control select-margin-top-control">
            <select id="selectSubUnit" className='select-input input is-medium' value={subSubUnit} name="level3" onChange={(e) => mudarUnidade(e, 2)}>
              <option value="">{i18n.message("select.subsubunidade",
                "Selecione uma sub-subunidade")}</option>
              {
                subUnidade.subUnits.map((opcao) => {
                  return <option value={opcao.uuid} key={opcao.uuid}>{opcao.name}</option>
                })
              }
            </select>
          </div>
        </label>
      </div>
    )
  }

  let link = ""
  if(props.code === 'rededor') {
    link = "https://weex.digital/termos-rede-dor/"
  } else if(props.code === 'lorenzetti' || props.code === 'lorenzettiteste') {
    link = "https://weex.digital/wp-content/uploads/2023/06/02-2023_06_01_Regulamento-Sem-Meio-Amb-Loren-2023_SP-rev-02-SP-e-MG.pdf"
  } else {
    if(props.privacyPolicy) {
      link = props.privacyPolicy
    } else {
      link = "https://weex.digital/politica-de-privacidade-e-uso/"
    }
  }
  
  return(
    <form onSubmit={props.registro}>
      { props.erro != null ? (
        <WeexModal 
          fecharModalCallback={props.callbackWarning}
          titulo={props.erro.titulo} 
          conteudo={props.erro.mensagem}
        />
      ) : null}
            
      {props.fields.map(function (el, index) {
        return (
                    
          props.renderizaCampo(el, index)
        );
      })}

      {
        props.campanha.registrationUnitMandatory ? 
          <>
            { inputUnidade()}
            {inputSubUnidade()}
            {inputLevel3()}
          </>
          :""
      }     

      

      <label className="checkbox" style={{textAlign:'center', marginTop:'2.0em', marginBottom:'2.0em'}} aria-label={i18n.message("aria.label.campo.registro.legal.termos", " Aceitar termos e condições ")}>
        <input onChange={props.onChangeAccept} type="checkBox" name="aceito"/>&nbsp;&nbsp;{i18n.message("registro.legal.liaceito", "Li e aceito os")} 
        <a className="link-sublinhado" href={link} target="_blank" rel="noopener noreferrer">{i18n.message("registro.legal.termos", " Termos e Condições ")}</a> 
        {i18n.message("registro.legal.daweex", "da Weex")}
                
                
      </label>
      <ReCaptcha
        sitekey={props.recaptchaToken}
        action='register'
        verifyCallback={props.verifyCallback}
      />

      <p style={{textAlign:'center', marginTop:'1.0em'}}>
        <BotaoSecundario disabled={props.accept || props.captcha ? false : true} nome={props.processando ? i18n.message("geral.aguarde", "AGUARDE...") : i18n.message("registro.criar", "CADASTRAR")} />
        <button className='button-link espacamento-inicio' type='button' onClick={props.modoLogin}>{i18n.message("registro.posseuiCadastro", "Já possuo cadastro")}</button>
      </p>
    </form>
  )

}

export default CustomRegisterCampaignMobile;