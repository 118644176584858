import React, { Component } from 'react';
import { connect } from 'react-redux';
import BotaoAsALink from '../../comps/botaoAsALink/BotaoAsALink';
import DinamicasHeader from '../../comps/dinamicasHeader/DinamicasHeader';
import AgendaService from '../../services/AgendaService';
import GameService from '../../services/GameService';
import UsuarioService from '../../services/UsuarioService';
import { GameStyled } from "./styled";


class Game extends Component {

    state = {
      codigo: null,
      codigoAtividade: null,
      action: null,
      game: null,
      processando: false,
    }

    componentDidMount() {
      if (this.state.processando) {
        return
      }
      this.setState({ processando: true })
      const codigo = this.props.match.params.codigo
      const codigoAtividade = this.props.match.params.codigoAtividade
      const service = new GameService()
      const agendaService = new AgendaService()
      service.iniciarGame(codigo, codigoAtividade, (erro, action) => {
        this.setState({ processando: false })
        if (action) {
          agendaService.atualizarAgenda(codigo, codigoAtividade, "GAME", null, null, (errorAtualizarAgenda, sucesso) => {
            if (errorAtualizarAgenda) {
              alert(this.props.i18n.message("dinamica.erro.jaexecutada.mensagem", "Falha ao atualizar informações de atividade executadas, entre em contato com o suporte"))
            }
          })
        }
        if (erro) {
          alert(JSON.stringify(erro))
          return
        }

        action.game.css = { background: action.game.theme.backgroundColor, color: action.game.theme.foregroundColor, borderTopLeftRadius: '30px', borderTopRightRadius: '30px' }

        this.cssHeader = {}
        this.cssHeader.color = action.game.theme.foregroundColor;

        this.setState({
          codigo: codigo,
          codigoAtividade: codigoAtividade,
          action: action,
          game: action.game,
        })
        // this.jogar()
        const usuarioService = new UsuarioService()
        const url = this.state.game.url + "?action=" + this.state.action.actionId + "&tkn=" + usuarioService.token() + "&agenda=" + this.state.codigo
        localStorage.setItem('ultimoGame', url)
        localStorage.setItem('actionGame', this.state.codigoAtividade);
        setTimeout(() => {
          window.location = url
        }, "3000")
            
      })
    }


    voltar = () => {
      window.location = "/"
    }

    render() {
      let i18n = this.props.i18n

      const game = this.state.game
      if (!game) {
        setTimeout(() => {
          return <div>{i18n.message("dinamica.game.semjogo", "Sem jogo...")}</div>
        }, 2000);
      }
      return (
        <GameStyled>
          <DinamicasHeader
            dinamica={game}
            theme={game?.theme}
            dinamicaNome={i18n.message("dinamica.game.nome", "Game")}
            dinamicaAlt={i18n.message("alt.dinamica.game", "Ícone de um controle de videogame.")}
            dinamicaImg={this.props.location.state 
              ? this.props.location.state.icon || "/new_game.svg" 
              : "/new_game.svg"
            }
          />
          <div className="card-content">
            <div 
              role="region" 
              className="info" 
              aria-label={i18n.message("aria.label.game.informacoes", "Informações do Jogo")}
            >
              <p className="info-game">{i18n.message("info.gamer", "Clique no botão concluir para que sua pontuação seja registrada ")} </p>
              <p>{i18n.message("geral.aguarde", "Aguarde...")}</p>
            </div>
          </div>
          <BotaoAsALink
            nome={i18n.message("missaoCumprida.repetir", "Voltar")}
            ariaLabelMsg={i18n.message("aria.label.geral.mensagem.voltar.atividades", "Volta para a página de atividades.")}
            funcao={this.voltar}
          />
        </GameStyled>
      );
    }
}

const mapStateToProps = function (state) {
  return {
    i18n: state.i18n
  }
}

export default connect(mapStateToProps)(Game)