import CampanhaService from './CampanhaService';
let campanhaService = new CampanhaService()
export default class InternacionalizacaoService {

  constructor(){
    this.i18n = campanhaService.i18nCorrente();
    this.messages = {}
  }

  load(){
    this.init(this.i18n)
  }

  diasSemana(){
    return [
      this.message("geral.diasemana.1", "DOM"),
      this.message("geral.diasemana.2", "SEG"),
      this.message("geral.diasemana.3", "TER"),
      this.message("geral.diasemana.4", "QUA"),
      this.message("geral.diasemana.5", "QUI"),
      this.message("geral.diasemana.6", "SEX"),
      this.message("geral.diasemana.7", "SAB")
    ];
  }

  meses(){
    return [
      this.message("geral.mes.1", "JANEIRO"),
      this.message("geral.mes.2", "FEVEREIRO"),
      this.message("geral.mes.3", "MARÇO"),
      this.message("geral.mes.4", "ABRIL"),
      this.message("geral.mes.5", "MAIO"),
      this.message("geral.mes.6", "JUNHO"),
      this.message("geral.mes.7", "JULHO"),
      this.message("geral.mes.8", "AGOSTO"),
      this.message("geral.mes.9", "SETEMBRO"),
      this.message("geral.mes.10", "OUTUBRO"),
      this.message("geral.mes.11", "NOVEMBRO"),
      this.message("geral.mes.12", "DEZEMBRO"),
    ]
  }

  /**
   * @param {string} mes
   * @param {i18n} i18n instância i18n 
   * @returns {string}
   * @example getMes("01")
   */
  getNomeMesInteiro(mes) {
    switch (mes) {
    case '01': return this.message("aria.labe.geral.mes.1", "JANEIRO")
    case '02': return this.message("aria.labe.geral.mes.2", "FEVEREIRO")
    case '03': return this.message("aria.labe.geral.mes.3", "MARÇO")
    case '04': return this.message("aria.labe.geral.mes.4", "ABRIL")
    case '05': return this.message("aria.labe.geral.mes.5", "MAIO")
    case '06': return this.message("aria.labe.geral.mes.6", "JUNHO")
    case '07': return this.message("aria.labe.geral.mes.7", "JULHO")
    case '08': return this.message("aria.labe.geral.mes.8", "AGOSTO")
    case '09': return this.message("aria.labe.geral.mes.9", "SETEMBRO")
    case '10': return this.message("aria.labe.geral.mes.10", "OUTUBRO")
    case '11': return this.message("aria.labe.geral.mes.11", "NOVEMBRO")
    case '12': return this.message("aria.labe.geral.mes.12", "DEZEMBRO")
    default: return ""
    }
  }

  /**
   * @param {string} mes
   * @param {i18n} i18n instância i18n 
   * @returns {string}
   * @example getMes("01")
   */
  getNomeMesAbreviado(mes) {
    switch (mes) {
    case '01': return this.message("geral.mes.1", "JAN")
    case '02': return this.message("geral.mes.2", "FEV")
    case '03': return this.message("geral.mes.3", "MAR")
    case '04': return this.message("geral.mes.4", "ABR")
    case '05': return this.message("geral.mes.5", "MAI")
    case '06': return this.message("geral.mes.6", "JUN")
    case '07': return this.message("geral.mes.7", "JUL")
    case '08': return this.message("geral.mes.8", "AGO")
    case '09': return this.message("geral.mes.9", "SET")
    case '10': return this.message("geral.mes.10", "OUT")
    case '11': return this.message("geral.mes.11", "NOV")
    case '12': return this.message("geral.mes.12", "DEZ")
    default: return ""
    }
  }

  /**
   * @param {string} diaSemana Nome do dia da semana, abreviada e em Upper Case
   * @param {i18n} i18n instância i18n 
   * @returns {string}
   * @example getMes("SEG")
   */
  getNomeDiaSemana(diaSemana) {
    switch (diaSemana) {
    case 'SEG': return this.message("geral.diasemana.SEG", "Segunda")
    case 'TER': return this.message("geral.diasemana.TER", "Terça")
    case 'QUA': return this.message("geral.diasemana.QUA", "Quarta")
    case 'QUI': return this.message("geral.diasemana.QUI", "Quinta")
    case 'SEX': return this.message("geral.diasemana.SEX", "Sexta")
    case 'SAB': return this.message("geral.diasemana.SAB", "Sabado")
    case 'DOM': return this.message("geral.diasemana.DOM", "Domingo")
    default: return ""
    }  
  }

  init(i18n){
    let mensagens = {}
    if (i18n) {
      i18n.forEach((item) => {
        let idioma = mensagens["idioma"]; // temporário: futuramente mudamos para buscar por idioma
        if (! idioma) {
          idioma = {};
          mensagens["idioma"] = idioma;
        }
        idioma[item.code] = item.message;
      });
    }
    this.messages = mensagens;
    return this.messages
  }

  message=(code, padrao)=>{
    if (this.messages && this.messages["idioma"] && this.messages["idioma"][code]) {
      return this.messages["idioma"][code]
    }

    return padrao ? padrao : undefined
  }

}
