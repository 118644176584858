import React, { useEffect } from "react";
import { useSelector, useStore } from "react-redux";
import { useHistory } from "react-router-dom";
import BotaoAsALink from "../comps/botaoAsALink/BotaoAsALink";
import BotaoAsALinkIconeEsquerda from "../comps/botaoAsALink/BotaoAsAlinkWithIconLeft";
import CampanhaService from "../services/CampanhaService";
import TemaService from "../services/TemaService";
import { ComoFuncionaStyle } from "./comoFuncionaStyle";

function ComoFunciona() {
  const i18n = useStore().getState().i18n;
  const history = useHistory();
  const campanhaService = new CampanhaService();
  const isDesktopMode = useSelector((state) => state.isDesktopMode);
  useEffect(() => {
    let temaService = new TemaService(new CampanhaService().campanhaCorrente());
    temaService.aplicarTema("HOME");
  }, []);

  let now;
  let beginDate;
  let campanhaCorrente = campanhaService.campanhaCorrente();
  if (campanhaCorrente) {
    let dtStart = campanhaCorrente.startDate.split(" ");
    let dateStart = dtStart[0].split("/");
    let hourStart = dtStart[1].split(":");
    beginDate = new Date(
      dateStart[2],
      dateStart[1] - 1,
      dateStart[0],
      hourStart[0],
      hourStart[1],
      hourStart[2]
    );
    now = new Date();
  }

  let logoCentro = "";

  if (campanhaCorrente) {
    if (!campanhaCorrente.topLeftDesktop) {
      logoCentro = "";
    } else {
      logoCentro = campanhaCorrente.topLeftDesktop;
    }
  }

  let i18nmsg = ""
  if(
    beginDate != null && 
    now != null && 
    now >= beginDate
  ) {
    i18nmsg = i18n.message("aria.label.geral.mensagem.voltar.atividades", "Volta para a página de atividades.")
  } else {
    i18nmsg = i18n.message("aria.label.geral.mensagem.voltar.espera", "Volta para a página de espera da campanha.")
  }

  return (
    <ComoFuncionaStyle logoCentro={logoCentro}>
      {isDesktopMode ? (
        <div className="header-como-funciona">
          <div className="header-como-funciona-voltar">
            <div>
              <BotaoAsALinkIconeEsquerda
                icon="fas fa-arrow-left "
                classeComplementar="header-como-funciona-desktop-botao"
                nome={i18n.message("geral.voltar", "Voltar")}
                ariaLabelMsg={i18nmsg}
                funcao={() => {
                  if (beginDate != null && now != null && now >= beginDate) {
                    history.push("/atividades");
                  } else {
                    history.push("/home");
                  }
                }}
              />
            </div>
          </div>
        </div>
      ) : (
        ""
      )}

      <div className="weex-como-funciona">

        <div
          dangerouslySetInnerHTML={{
            __html: campanhaCorrente.comoFunciona.helpMessage,
          }}
        ></div>
      </div>
      {!isDesktopMode ? (
        <div style={{marginTop: '3em', marginBottom: '2em'}}>
          <BotaoAsALink
            nome={i18n.message("geral.voltar", "Voltar")}
            ariaLabelMsg={i18nmsg}
            funcao={() => {
              if (beginDate != null && now != null && now >= beginDate) {
                history.push("/atividades");
              } else {
                history.push("/home");
              }
            }}
          />
        </div>
      ) : (
        ""
      )}
    </ComoFuncionaStyle>
  );
}

export default ComoFunciona;
