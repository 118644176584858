import styled from "styled-components";

export const TrofeusStyle = styled.div`
h3{
    color: ${props => { return props.theme.cardLogadoTextoPrimeiro}};
    margin-top: 20px;
    margin-bottom: 10px;
}

h4{
    color: ${props => { return props.theme.cardLogadoTextoSegundo}};
    margin-bottom: 40px;
}

.prateleira {
    border: solid 1px #d5d3d5;
    background-color: #d5d3d5;
    border-top: solid 2px white;
    max-height: 6px;
}

.trofeu__sala {
    max-width: 34px;
    width: 34px;
    height: 35px;
}

.trofeu__sala img{
    -webkit-transform: translateY(10px);
          transform: translateY(10px);
    max-height: 70px;
}

.trofeu__sala__semana_able{
    max-width: 25px;
    width: 25px;
}

.trofeu__sala__semana_disable{
    max-width: 18px;
    width: 18px;
}

.trofeu__sala__semana{
    transform: scale(1.3,1.3);
}

.trofeu__sala__semana img{
    -webkit-transform: translateY(3px);
          transform: translateY(3px);
}

.estante_topo {
    text-align: center;
}

.vidro-sala-trofeus{
    position: absolute;
    z-index: 100;
    width: 90%;
    left: 5%;
}

.trofeu-agenda{
        margin-top: 5em;
        
    }

@media screen and (min-width: 700px) {

    .trofeu-content-desktop{
        display: flex;
    }
    .trofeu-agenda{
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin: auto;
        
    }
    .trofeu-agenda-desktop{
        min-width: 45%;
        max-width: 45%;
        margin-left: 1em;
        margin-right: 1em;
        
    }
}

`

export const TrofeuDaCampanhaContainerStyle = styled.div`
    display: flex;
    justify-content: start;
    flex-direction: column;
    align-items: center;

    .trofeu__sala__campanha {
        max-width: 55px;
        width: 55px;
        height: 55px;

        position: relative;
        bottom: 30px;

        p{
            font-weight: 500;
            font-size: 15px;
            line-height: 15px;
            color: ${props => { return props.completedColor}};
        }
    }

    .trofeu-width-campanha{
            max-width: 50%;
            max-height: 50%;
    }  
    @media screen and (min-width: 700px) {
        min-width: 29%;
        margin-top: 1em;
        margin-right: 7vw;

        .trofeu-width-campanha{
            max-width: 80%;
            max-height: 80%;
        }       
    }

`

export const EstanteStyle = styled.div`
    border: 2px solid ${props => props.achieved ? `#00E291` : `#CECECE`};
    border-radius: 14px;
    background: ${props => props.achieved ? `rgba(0, 226, 145, 0.1)` : `#FFFFFF`};
    padding-bottom: 12px;
    
    .trofeuDia{
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        top: -40px;
        margin-left: 20px;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 30px;
        padding-right: 30px;
        border-radius: 8px;

        color: #FFFFFF;
        font-weight: 700;
        font-size: 14px;
        line-height: 14px;
        letter-spacing: 2px;
        text-transform: uppercase;
        background: ${props => props.achieved ? `#00E291` : `#CECECE`};
        /* box-shadow: 0px 20px 40px -10px rgba(119, 119, 119, 0.23); */
        box-shadow: ${props => { return props.theme.sombraPrimaria}};
        max-width: 88px;
        width: 88px;
        height: 72px;

        div{
            margin-bottom: 10px;
        }

        span{
            font-size: 14px;
            line-height: 14px;
        }
    }

    .trofeus-estante{
        display: flex;
        align-items: baseline;
        justify-content: center;
        margin: 0 auto;
    }
    .header-trofeu-container{
        max-height: 6vh;
    }

    @media screen and (min-width: 700px) {
        min-height: 38vh;
        min-width: 24vw;
        
    }

@media screen and (min-width: 1024px) {
    .header-trofeu-container{
        max-height: 4vh;
    }

    .trofeus-estante{
        display: flex;
        align-items: baseline;
        justify-content: space-evenly;
        margin: 0 auto;
    }
}

@media screen and (min-width: 768px) {
    .header-trofeu-container{
        max-height: 4vh;
    }
}
`

export const MedalhaAtividadeStyle = styled.div`
   
    display: flex;
    flex-direction: column;
    align-items: center;

    .check{
        opacity: ${props => props.activityAchieved ? `1` : `0`};
        position: relative;
        top: 14px;
    }

    .medalha{
        border: 1px solid ${props => props.activityAchieved ? `#00E291` : `#CECECE`};
        border-radius: 8px;
        padding: 10px;
        margin-right: 6px;
        margin-left: 6px;
    }

    p{
        text-align: center;
    }
`