import styled from "styled-components";

export const InteresseFormStyle = styled.div`
.container-interesse {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 1.5em;
}

.info-interesse {
	margin: 1em auto 1em auto;
	display: table;
	margin-top: 1em;
	font-size: 1.4rem;
}

.interesse-mensagem {
	font-size: 1.2rem !important;
	font-weight: bold !important;
}

.interesse-submensagem {
	font-style: italic;
	font-weight: 400;
}

.aviso-obrigatoriedade {
	margin-top: 1.5rem;
	display: block;
	text-align: center;
	font-weight: 600;
}

.title-interesse {
	font-size: 1.1rem;
	font-weight: 600;
	line-height: normal;
}

.switch-interesse {
	position: relative;
	display: inline-block;
	width: 60px;
	height: 34px;
}

.switch-interesse input {
	opacity: 0;
	width: 0;
	height: 0;
}

.slider-interesse {
	position: absolute;
	cursor: pointer;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

.slider-interesse:before {
	position: absolute;
	content: "";
	height: 26px;
	width: 26px;
	left: 4px;
	bottom: 4px;
	background-color: white;
	-webkit-transition: 0.4s;
	transition: 0.4s;
}

input:checked + .slider-interesse {
	background-color: ${props => { return props.theme.fundoSecundario}};
}

input:focus + .slider-interesse {
	box-shadow: 0 0 1px ${props => { return props.theme.fundoSecundario}};
}

input:checked + .slider-interesse:before {
	-webkit-transform: translateX(26px);
	-ms-transform: translateX(26px);
	transform: translateX(26px);
}

/* Rounded sliders */
.slider-interesse.round {
	border-radius: 34px;
}

.slider-interesse.round:before {
	border-radius: 50%;
}

.interesse-btn-container{
	display: flex;
	justify-content: center;
}
.interesse-salvar {
	min-width: 300px;
	background-color: #6b6b71;
	color: #ffffff;
	border: none;
	font-size: 20px;
	letter-spacing: 2px;
	border-radius: 6px;
	cursor: pointer;
	padding: 15px;
	margin-bottom: 15px;
	box-shadow: rgb(0 0 0 / 15%) 0px 5px 15px;
}

.interesse-salvar-disabled {
	min-width: 300px;
	background-color: #6b6b71;
	color: #999;
	border: none;
	font-size: 20px;
	letter-spacing: 2px;
	border-radius: 6px;
	cursor: pointer;
	padding: 15px;
	margin-bottom: 15px;
	box-shadow: rgb(0 0 0 / 15%) 0px 5px 15px;
}

.salvar-obrigatório{
	text-align: center;
	font-size: 1.4rem;
	font-weight: 500;
}

.interesse-obrigatiorio-container{
	display: flex;
	justify-content: space-evenly;
}




`