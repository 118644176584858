import styled from "styled-components";

export const GameAgendaStyle = styled.button`
  width: 100%;
  border-radius: 10px;
  line-height: 14px;
  background-color: transparent;
  box-shadow: ${props => { return props.theme.sombraSecundaria}};
  cursor: pointer;
  font-weight: 700;
	font-size: 12px;
	line-height: 12px;
	color: ${props => { return props.theme.cardLogadoTextoPrimeiro}};
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 1px;
  border-style: solid;
  border-color: ${props => { return props.theme.fundoSecundario}};


  :active{
    filter: brightness(0.8)
  }

  .icon.is-large{
    margin-right: 0.5rem;
    fill: ${props => { return props.theme.cardLogadoTextoPrimeiro}};
  }
  .texto-habilitado{
    font-weight: bold;
    font-size: 0.9em;
    width:70%;
  }

  .disabled{
    cursor: not-allowed;
    filter: contrast(0.5);
  }

  @media screen and (max-width: 600px) {
      padding-left: 0em;
      padding-right: 0em;
  }
`

export const GameAgendaBlockStyle = styled.div`
  width: 100%;
  border-radius: 10px;
  line-height: 14px;
  background-color: transparent;
  box-shadow: ${props => { return props.theme.sombraSecundaria}};
  cursor: not-allowed;
  font-weight: 700;
	font-size: 12px;
	line-height: 12px;
	color: #776969;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 0.5px;
  border-style: solid;
  border-color: #bcb3b3;


  .icon.is-large{
    margin-right: 0.5rem;
    fill: ${props => { return props.theme.cardLogadoTextoPrimeiro}};
  }
  .texto-habilitado{
    font-weight: bold;
    font-size: 0.9em;
    width:75%;
  }

  .disabled{
    cursor: not-allowed;
    filter: contrast(0.5);
  }
  .icon-disabled{
    fill: #c1c1c1 !important;
  }

`
