import React from 'react';
import { versao } from "../versao";
import { useStore } from "react-redux";

function Version() {
  const i18n = useStore().getState().i18n;

  return(
    <div>
      <div>
        <img src="../logo.png" alt={i18n.message("alt.redirecionamento.campanha.weex.logo", "Logo da empresa fornecedora do serviço Weex")} />
      </div>
      <div className="div-version">versão <span className='version-label'>{versao}</span></div>
    </div>
  );
}

export default Version;
