import React, { Component } from "react";
import { connect } from "react-redux";
import { GameRankingsListStyled } from "../../comps/gameRanking/styled";
import UsuarioService from "../../services/UsuarioService";
import PontuacaoUsuario from './PontuacaoUsuario';
class PontuacaoUsuarioCorrente extends Component {
    mensagemUsuario = "";
    usuarioService = new UsuarioService();
    constructor(props) {
      super(props);
      this.state = {
        verificarSeUsuarioEstaNoRankingBolean: false,
      };
    }

    verificarSeUsuarioEstaNoRanking = () => {
      let encontrei = false;
      let usuario = this.usuarioService.usuarioCorrente();
      let uuidParticipante = usuario.participantCode;
      this.props.ranking.forEach((pontuacaoUsuario) => {
        if (pontuacaoUsuario.uuidParticipante === uuidParticipante) {
          encontrei = true;
        }
      });
      return encontrei;
    }
    componentDidMount(){
      this.mensagemUsuario =  this.props.i18n.message("info.pontuacao", "Sua posição: fora do top 50 ")
      this.setState({verificarSeUsuarioEstaNoRankingBolean: this.verificarSeUsuarioEstaNoRanking()})
    }

    componentDidUpdate(prevProps) {
      if (this.props.pontuacaoUsuario !== prevProps.pontuacaoUsuario) {
        this.setState({ mensagemUsuarioQueNaoEstaNoRanking: this.mensagemUsuarioQueNaoEstaNoRanking() })
      }
    }
    
    mensagemUsuarioQueNaoEstaNoRanking = () => {
      if(!this.verificarSeUsuarioEstaNoRanking() && this.props.pontuacaoUsuario && this.props.pontuacaoUsuario?.rating !== 0  ){
        return(
          <div className="info-position">
            <span className="info-pontuacao">{this.mensagemUsuario}</span>
          </div>
        )
      }

    }

    render(){
      return (
        <GameRankingsListStyled>
          {this.state.mensagemUsuarioQueNaoEstaNoRanking}
          <div className="participante-content">
            <div className="participante">
              <div className="participante-icon">
                <svg width="30" height="30" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M17.0711 2.92892C15.1823 1.04019 12.6711 0 10 0C7.3288 0 4.81766 1.04019 2.92892 2.92892C1.04019 4.81766 0 7.3288 0 10C0 12.6711 1.04019 15.1823 2.92892 17.0711C4.81766 18.9598 7.3288 20 10 20C12.6711 20 15.1823 18.9598 17.0711 17.0711C18.9598 15.1823 20 12.6711 20 10C20 7.3288 18.9598 4.81766 17.0711 2.92892ZM5.01328 17.2807C5.43152 14.8726 7.51831 13.0963 10 13.0963C12.4818 13.0963 14.5685 14.8726 14.9867 17.2807C13.5669 18.2562 11.8491 18.8281 10 18.8281C8.15094 18.8281 6.43311 18.2562 5.01328 17.2807ZM6.82037 8.74481C6.82037 6.99142 8.24677 5.56519 10 5.56519C11.7532 5.56519 13.1796 6.99158 13.1796 8.74481C13.1796 10.498 11.7532 11.9244 10 11.9244C8.24677 11.9244 6.82037 10.498 6.82037 8.74481ZM16.0017 16.4679C15.6863 15.3468 15.06 14.3297 14.182 13.5352C13.6433 13.0476 13.0302 12.663 12.3711 12.3918C13.5625 11.6147 14.3517 10.2702 14.3517 8.74481C14.3517 6.34537 12.3994 4.39331 10 4.39331C7.60056 4.39331 5.6485 6.34537 5.6485 8.74481C5.6485 10.2702 6.43768 11.6147 7.62894 12.3918C6.96991 12.663 6.35666 13.0475 5.81802 13.535C4.94019 14.3295 4.31366 15.3467 3.99826 16.4677C2.26105 14.8544 1.17188 12.5522 1.17188 10C1.17188 5.13214 5.13214 1.17188 10 1.17188C14.8679 1.17188 18.8281 5.13214 18.8281 10C18.8281 12.5523 17.739 14.8546 16.0017 16.4679Z" fill="#847D92" />
                </svg>
              </div>
              <PontuacaoUsuario
                pontuacaoUsuario={this.props.pontuacaoUsuario}
                isParticipante={true}
                                
              >
              </PontuacaoUsuario>

            </div>
          </div>
        </GameRankingsListStyled>
      )
    }
}

const mapStateToProps = function (state) {
  return {
    i18n: state.i18n
  }
}

export default connect(mapStateToProps)(PontuacaoUsuarioCorrente);