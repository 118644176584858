import React from 'react';
import { useSelector, } from "react-redux";
import { useLocation } from "react-router-dom";
import UsuarioService from "../../services/UsuarioService";
import { CampanhaLogosStyle } from "./style";
const usuarioService = new UsuarioService();

function InfosTextuais({
  campanhaName,
  title
}) {
  const usuario = useSelector((state) => state.usuario);
  let usuarioLogado = usuarioService.usuarioCorrente() && usuarioService.usuarioCorrente().token != null ? true : false;
  let location = useLocation()
    
  return (
    <>
      {
        (location.pathname === '/atividades'
			|| location.pathname === '/'
			|| location.pathname === '/trophy'
			|| location.pathname === '/interests'
			|| location.pathname === '/home' 
			|| location.pathname === "/resetPassword"
			|| location.pathname === "/passwordReset") &&
            (usuarioLogado && usuario === true)
          ?
          (
            <>    
              <div className="weex-container">
                <CampanhaLogosStyle>
                  <div className="campanha_logosTopo">
                    {campanhaName ? (
                      <div className="campanha_logosTopo_name foreground">
                        <h1>
                          {campanhaName}
                        </h1>
                      </div>
                    ) : ""
                    }            
                    {title ? (
                      <div className="campanha_logosTopo_subtitle foreground" style={{marginTop: '10px'}}>
                        <h2>
                          {title}
                        </h2>
                      </div>
                    ) : ""
                    }
                  </div>
                </CampanhaLogosStyle>
              </div>				
            </>):<div></div>
      }
    </>
  )
}

export default InfosTextuais;