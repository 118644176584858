import Vimeo from '@u-wave/react-vimeo';
import React from 'react';
import {animations} from 'react-animation';
import {VideoIframeStyled} from './VideoIframeStyle';

function VideoIframe(props) {

  function videoHandler(url) {
    if(url) {
      if(url.indexOf("youtube")!==-1||url.indexOf("youtu.be")!==-1) {
        return (
          <iframe
            title={props.title}
            className='video-mural youtube'
            width="100%"
            height="100%"
            webkitallowfullscreen="webkitallowfullscreen"
            allowfullscreen="allowfullscreen"
            mozallowfullscreen="mozallowfullscreen"
            msallowfullscreen="msallowfullscreen"
            oallowfullscreen="oallowfullscreen"
            src={url}
          />
        )
      } else {
        return (
          <Vimeo
            className='video-mural'
            video={url}
            controls={props.controls}
            style={{animation: animations.popIn}}
            showTitle={props.showTitle}
          ></Vimeo>
        )

      }
    }

  }

  function parsearUrlvideo(url) {
    if(url) {
      if(url.indexOf("youtube")!==-1||url.indexOf("youtu.be")!==-1) {
        let regex=/^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|&v(?:i)?=))([^#&?]*).*/
        let resultadoRegex=url.match(regex)
        let urlParseada = "https://www.youtube.com/embed/"+resultadoRegex[1]+"?autoplay=0&mute=0&controls=2&origin=" + window.location.origin +
				"&playsinline=1&showinfo=0&rel=0&iv_load_policy=3&modestbranding=1&enablejsapi=0&fs=1&autohide=1&widget_referrer=" + window.location.origin

        if(props.showControls){
          urlParseada+="&controls=1"
        }


        return urlParseada;
      }
      return url
    }

  }
  return (
    <VideoIframeStyled>
      {videoHandler(parsearUrlvideo(props.urlVideo))}
    </VideoIframeStyled>

  )
}

export default VideoIframe
