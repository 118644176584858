import React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import './index.css';
import './reset.css';
import GlobalTheme from './styles/GlobalTheme';

import { Provider } from 'react-redux';
import store from './store';
import axe from '@axe-core/react';

if (process.env.NODE_ENV !== 'production') {
  axe(React, ReactDOM, 1000);
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <GlobalTheme>
        <App />
      </GlobalTheme>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// if (process.env.NODE_ENV === "production" || process.env.NODE_ENV === "homol") {
//   serviceWorker.register();
// } else {
//   serviceWorker.unregister()
// }
