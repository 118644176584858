import styled from "styled-components";

export const NovosHabitosStyled = styled.div`
     @media screen and (min-width: 700px) {
        .desktop-content-left{
            margin-right: 10vw;
            margin-top: 20vh;
            min-width: 25%;
    
        }
        .desktop-content{
            display: flex;
        }
        .card-novos-habitos{
        }
        .botao-voltar-desktop{
            display: flex;
            min-width:80vw;
            justify-content: flex-start;
        }
        .desktop-content-right-CardNovosHabitos{
            max-height: 75vh;
            overflow: auto;
            padding-left: 1em;
            padding-right: 1em;
            ::-webkit-scrollbar {
                width: 5px;
            }

            ::-webkit-scrollbar-track {
                box-shadow: inset 0 0 5px transparent ;
                border-radius: 10px;
            }
            
            ::-webkit-scrollbar-thumb {
                background: ${props => { return props.theme.fundoSecundario }};; 
                border-radius: 10px;
            }

            ::-webkit-scrollbar-thumb:hover {
                background: ${props => { return props.theme.fundoSecundario || `#533888` }}; 
            }
        
        }
        .desktop-content-right-marcador{
            position: fixed;
            right: 30vw;
            top: 100%;
            width: 8em;
            margin-top: -2.5em;
        }

        .desktop-novos-habitos{
            margin: 1vw 6vw;
            margin-bottom: 0;
        }        

        .marcador-progresso{
            padding-top: 1em;
            justify-content: center;
        }

        .marcador{
            margin: 0.3em;
        }

        .weex-card-container-imagem{
            width: 100%;
        }
        .botao-card-novos-habitos{
            width: 100%;
            text-align: center;
        }

        
    }

`