import React, {useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import AtividadesService from '../services/AtividadesService';
function CallbackGame(props) {
  let {codigo} = useParams()
  let history = useHistory()
  const [erro, setErro] = useState(null)
  let service = new AtividadesService()
  let actionGame = localStorage.getItem('actionGame')
  service.finalizarAtividade(codigo, actionGame, 'GAME', {}, (erro, sucesso) => {
    if (erro) {
      setErro("Erro ao obter ação")
    } else if (sucesso) {
      history.push('/missionFinished/' + codigo + '/GAME/' + actionGame)
    }
  })



  return (<div>{erro}</div>)
}

export default CallbackGame