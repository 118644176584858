import React, { useState } from 'react';
import { AnimateOnChange } from 'react-animation';
import { useStore } from 'react-redux';
import { Link, useLocation } from "react-router-dom";
import CampanhaService from '../services/CampanhaService';
import SenhaService from '../services/SenhaService';
import { ChangePasswordStyle } from './ChangePasswordStyle';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function ChangePassword() {

  let query = useQuery();
  const i18n = useStore().getState().i18n

  const [senha, setSenha] = useState({senha: "", confirmarSenha: ""})
  const [mensagemSucesso, setMensagemSucesso] = useState("")
  const [mensagemErro, setMensagemErro] = useState("")
  const [exibirContinuar, setExibirContinuar] = useState(true)
  const [token] = useState(query.get("token"))
  const [temCampanha] = useState(new CampanhaService().campanhaCorrente() != null)

  let alterarSenha = (event) => {
    event.preventDefault()
    if(senha.senha !== senha.confirmarSenha){
      setMensagemErro(i18n.message("registro.erro.senhas.mensagem", "A senhas devem ser iguais"))
      setMensagemSucesso("")
      return
    }
    let corpo = {
      token: token,
      password: senha.senha
    }
    new SenhaService().alterarSenha(corpo, (erro, sucesso) => {
      if(sucesso){
        setMensagemErro("")
        setMensagemSucesso(i18n.message("senha.alterada.sucesso", "Senha alterada com sucesso."))
        setExibirContinuar(false)
      }
      if(erro){
        if (erro.response && erro.response.data && erro.response.data.message) {
          setMensagemErro(erro.response.data.message)
          setMensagemSucesso("")
        } else {
          setMensagemErro(i18n.message("geral.erro.network.mensagem", "Falha ao tentar alterar senha, entre em contato com o suporte ou tente novamente mais tarde"))
          setMensagemSucesso("")
        }
      }
    })
  }

  let onChange = (event) => {
    if(event.target.name === "senha"){
      senha.senha = event.target.value
    } else if(event.target.name === "confirmarSenha"){
      senha.confirmarSenha = event.target.value
    }
    setSenha(senha)
  }

  let ocultarNotificacoes = () => {
    setMensagemErro("")
    setMensagemSucesso("")
  }

  return (
    <AnimateOnChange>
      <ChangePasswordStyle>
        {token ? (
          <div>
            <h1 className="title titulo">{i18n.message("senha.alterar.titulo", "Nova Senha")}</h1>

            <p className="my-4">{i18n.message("senha.alterar.instrucoes", "Pronto, agora você só precisa definir sua nova senha e clicar em continuar.")}</p>

            <form onSubmit={alterarSenha} style={{"overflow": "hidden"}}>
              <div className="field">
                <div className="control">
                  <input type="password" required className="input input-size" placeholder={i18n.message("registro.campo.senha", "Senha")} name="senha" onChange={onChange}/>
                </div>
              </div>
              <div className="field">
                <div className="control">
                  <input type="password" required className="input input-size" placeholder={i18n.message("registro.campo.senha2", "Confirmação da senha")} name="confirmarSenha" onChange={onChange}/>
                </div>
              </div>
              {exibirContinuar &&
                                <div className="field">
                                  <div className="control">
                                    <input type="submit" value="Continuar" className="button my-4 button-submit" />
                                  </div>
                                </div>
              }
            </form>

            <div className="my-3">
              {mensagemErro &&
                                <div className="notification is-danger is-light">
                                  <button className="delete" onClick={ocultarNotificacoes}></button>
                                  {mensagemErro}
                                </div>
              }
              {mensagemSucesso &&
                                <div className="notification is-success is-light">
                                  <button className="delete" onClick={ocultarNotificacoes}></button>
                                  {mensagemSucesso}
                                  {temCampanha &&
                                        <span>{i18n.message("senha.alterar.voltar", "Para voltar a tela de login")} <Link to="/">{i18n.message("geral.label.cliqueaqui", "clique aqui")}</Link></span>
                                  }
                                </div>
              }
            </div>
          </div>
        ) : (
          <div>
            <h1 className="title">{i18n.message("senha.alterar.erro.titulo", "Solicitação inválida")}</h1>
            <p>{i18n.message("senha.alterar.recuperar", "Se você esqueceu sua senha")} <Link to="/resetPassword">{i18n.message("geral.label.cliqueaqui", "clique aqui")}.</Link></p>
          </div>
        )}
      </ChangePasswordStyle>
    </AnimateOnChange>
  )
}

export default ChangePassword;