import React,{useEffect,useState} from 'react';
import {useSelector, useStore} from "react-redux";
import WeexProgressBar from '../WeexFlatProgressBar/WeexProgressBar';
import {AgendaHeaderStyle} from './style';

function AgendaHeader(props) {
  const [completed,setCompleted]=useState(0);
  const i18n = useStore().getState().i18n;
  let completedColor=completed==='100'? '#00E291':'#847D92'
  const isDesktopMode=useSelector((state) => state.isDesktopMode);

  useEffect(() => {
    setTimeout(() => setCompleted(props.porcentagemAtividadesFeitas),100);
  },[props.porcentagemAtividadesFeitas]);


  const openCloseDinamicas =() => {
    if(!isDesktopMode) {
      return (
        <button className='close-btn' onClick={props.openClose}>
          <span className='openClose' >
            {
              props.openCloseDynamics===true||props.openCloseDynamics===undefined?
                (
                  <>
                    {props.fechar} <img src="/arrow-up.svg" alt={i18n.message("alt.abrir.dinamicas","Seta para cima")} />
                  </>
                ):
                (
                  <>
                    {props.verMais} <img src="/arrow-down.svg" alt={i18n.message("alt.fechar.dinamicas","Seta para baixo")} />
                  </>
                )
            }
          </span>
        </button>
      )
    }

  }

  return (
    <AgendaHeaderStyle completedColor={completedColor}>
      <h5 className='dia-titulo'>
        {props.nome? props.nome:props.nomeInteresse}
      </h5>
      <div className={!isDesktopMode ? `dia-progress-bar-container` : ''}>
        <div className='dia-progress-bar'>
          <div style={{display: 'flex'}}>
            <p style={{marginRight: '25px'}} className="weekName">{props.dayOfWeekComplete}</p>
            <p>{`${completed}% ${props.concluido}`}</p>
          </div>
          <WeexProgressBar bgColor={completed==='100'? '#00E291':'#847D92'} completed={completed} />
        </div>
        <div className="dia-da-semana-e-btn" style={{alignSelf: 'self-end'}}>
          {
            props.habilitado===false?
              (
                <p className='agenda-aguarde'>
                  {props.aguarde}
                </p>
              ):
              (
                openCloseDinamicas()
              )
          }
        </div>
      </div>
    </AgendaHeaderStyle>
  );
}

export default AgendaHeader;
