import React, { useState } from "react";
import { ButtonPrimarioStyle } from './style';

/**
 * @param {Object} props
 * @param {string} props.nome
 * @param {boolean} props.disabled
 * @param {boolean} props.ariaDisabled
 * @param {boolean} props.ariaHidden
 * @param {boolean} props.tabIndex
 * @param {string} props.ariaStatusMessage
 * @param {string} props.ariaLabelMsg
 * @param {string} props.classeComplementar
 * @param {ReactNode} props.children
 * @param {function(event): void} props.funcao
 */
export default function BotaoPrimario({
  nome,
  disabled,
  ariaDisabled,
  ariaHidden,
  tabIndex,
  ariaLabelMsg,
  classeComplementar,
  funcao,
  children,
  ariaStatusMessage
}) {

  const [ariaAtivarMensagemDeClique, setAriaAtivarMensagemDeClique] = useState(false)

  function onClick(event) {
    setAriaAtivarMensagemDeClique(true)
    funcao && funcao(event)
  }

  return (
    <ButtonPrimarioStyle
      className={`${classeComplementar} ${disabled && "disabled"}`}
      onClick={onClick}
      aria-disabled={(disabled || ariaDisabled) ? "true" : "false"}
      aria-hidden={ariaHidden ? "true" : "false"}
      tabIndex={tabIndex ? tabIndex : '0'}
      aria-label={ariaLabelMsg}
    >
      <span className="content-botao">
        {children}
        {nome}
      </span>
      <span className="visually-hidden" role="status">
        {ariaAtivarMensagemDeClique ? ariaStatusMessage : ""}
      </span>
    </ButtonPrimarioStyle>
  )
}
