import lottie from 'lottie-web';
import React, { useEffect, useRef, useState } from 'react';
import { HideUntilLoaded, animations } from 'react-animation';
import { useDispatch, useStore } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import AgendaService from '../../services/AgendaService';
import CampanhaService from '../../services/CampanhaService';
import TemaService from '../../services/TemaService';
import BotaoAsALink from '../botaoAsALink/BotaoAsALink';
import BotaoPrimario from '../botaoPrimario/BotaoPrimario';
import { MissaoCumpridaFeedbackModalStyle } from './StyleMissaoCumpridaFeedback';
import { WeexModalDialog } from './WeexModalDialog';

function MissaoCumpridaModal() {

  const i18n = useStore().getState().i18n;
  const agendaService = new AgendaService()
  const history = useHistory()
  let { atividade } = useParams()
  let { codigoAgenda, codigoAtividade } = useParams()
  const [processando, setProcessando] = useState(false)
  const [informacoes, setInformacoes] = useState({ textoPontuacao: "", trofeu: "" })
  const dispatch = useDispatch();
  const dialogRef = useRef(null)

  useEffect(() => {
    let campanhaService = new CampanhaService();
    let temaService = new TemaService(campanhaService.campanhaCorrente())
    temaService.aplicarTema('MISSAO')
    if (!processando) {
      setProcessando(true)
      agendaService.agendaCorrente((erro, agenda) => {
        if (agenda) {
          lottie.loadAnimation({
            container: document.getElementById("missao-cumprida-container-confetes"),
            renderer: 'svg',
            loop: true,
            autoplay: true,
            path: '/confete.json'
          });
          for (let i = 0; i < agenda.schedule.length; i++) {
            if (agenda.schedule[i].scheduleCode === codigoAgenda) {
              for (let j = 0; j < agenda.schedule[i].activities.length; j++) {
                if (agenda.schedule[i].activities[j].type === atividade && agenda.schedule[i].activities[j].uuid === codigoAtividade) {
                  let info = {}
                  if (atividade === "FACT_OR_FAKE") {
                    let acertos = agenda.schedule[i].activities[j].acertos
                    let perguntas = agenda.schedule[i].activities[j].numeroQuestoes
                    info.textoPontuacao = <div className="pontuacao">{i18n.message("missaoCumprida.pontuacao.perguntas.inicio", "E acertou")} <span className="pontuacao__destaque">{acertos} {i18n.message("missaoCumprida.pontuacao.perguntas.de", "de")} {perguntas} ({parseInt(100 * ((acertos / perguntas).toFixed(2)))}%)</span> <br></br> {i18n.message("missaoCumprida.pontuacao.perguntas.final", "das perguntas!")}</div>

                  }
                  info.trofeu = agenda.schedule[i].activities[j].trophyEnabled
                  setInformacoes(info)
                  break
                }
              }
              break
            }
          }
        }
        if (erro) {
          history.push("/")
        }
      })
    }
    // TODO: Ao colocar as dependencias solicitadas pelo Lint, a aplicação fica em loop infinito no useEffect --> realacionada tarefa 14797 --> By Renan
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function navegarTrofeus() {
    history.push("/trophy")
  }

  function voltar() {
    history.push("/atividades")
  }

  const gameRanking = () => {
    history.push("/gameRankings")
  }

  const botaoRedirect = (chaveInternacionalizacao, msgDefault, navegate) => {
    return (<div className='modal-card-foot-button-desktop missao-cumprida' >
      <div className="modal-button-missaocomprida">
        <BotaoPrimario
          nome={i18n.message(chaveInternacionalizacao, msgDefault)}
          funcao={navegate}
          classeComplementar="weex-botao-confirmacao__texto-comprido__missao-cumprida mb-4"
          ariaStatusMessage={i18n.message("aria.status.missaocompridamodal.botaomissaocumprida", "Botão missao cumprida clicado.")}
        />
      </div>
    </div>
    )
  }

  const handlerRedirectAtividade = () => {
    if (atividade === "GAME") {
      if (new CampanhaService().campanhaCorrente().showRankingGame) {
        dispatch({ type: "game", payload: codigoAtividade });
        return botaoRedirect("missaoCumprida.game.ranking", "IR PARA O RANKING DO GAME", gameRanking);

      }
      
    } else {
      return botaoRedirect("missaoCumprida.trofeus", "IR PARA SALA DE TROFÉUS", navegarTrofeus);
    }

  }

  return (
    <WeexModalDialog
      dialogRef={dialogRef}
      onPressESC={voltar}
    >
      <div className={'modal is-active'} style={{ animation: animations.popIn, zIndex: '102' }}>
        <div className="modal-background weex-modal-background"></div>
        <div className="modal-card weex-modal-card" style={{ padding: '20px', paddingBottom: '50px' }}>
          <header className="modal-card-head weex-modal-card-header">
            <button 
              onClick={voltar}
              className="close-btn"
              aria-label={i18n.message("aria.label.fechar.modal", "Fecha a janela e volta para a página atividades.")}
              style={{ zIndex: '1003' }}
            >
              <img
                src="/close-btn.png"
                alt={i18n.message("alt.button.imagem.fechar", "ícone em formato de um X indicando o encerramento de uma ação.")}
              />
            </button>
          </header>
          <MissaoCumpridaFeedbackModalStyle>
            <div id="missao-cumprida-container-confetes" className="missao-cumprida-container-confetes foreground"></div>
            {
              informacoes.trofeu ?
                (
                  <div>
                    <p className="modal-card-title wex-modal-card-img">
                      <HideUntilLoaded>
                        <img src="/new_obrigado.svg" alt={i18n.message("alt.missaoCumprida.trofeu", "Troféu ") + atividade} className="image is-2by1 retirar-padding"></img>
                      </HideUntilLoaded>
                    </p>
                  </div>
                ) : ""
            }
            <div className="titulo-container">
              <h1 className="missao-cumprida__title foreground">{i18n.message("missaoCumprida.fim", "PARABÉNS!")}</h1>
              <h2 className="missao-cumprida__subtitle foreground">{i18n.message("missaoCumprida.parabens", "Você concluiu a atividade:")}</h2>
              <h3 className="missao-cumprida__subtitle missao-cumprida__subtitle__nameActivity foreground">{atividade === 'VIDEO' ? i18n.message("dinamica.video.nome", "Vídeo") : atividade === 'FACT_OR_FAKE' ? i18n.message("dinamica.fatofake.nome", "Fato ou Fake?") : atividade === 'NEW_HABIT' ? i18n.message("dinamica.novoshabitos.nome", "Novos Hábitos") : atividade}</h3>
            </div>
            {
              atividade === 'FACT_OR_FAKE' ?
                (
                  <div className="pontuacao-container foreground">
                    <p>
                      {informacoes.textoPontuacao}
                    </p>
                  </div>

                ) : ""
            }
            <p style={{ marginTop: atividade === 'FACT_OR_FAKE' ? '3vh' : '35px', marginBottom: '20px' }}>
              {handlerRedirectAtividade()}
            </p>
            <BotaoAsALink
              nome={i18n.message("missaoCumprida.repetir", "Voltar")}
              ariaLabelMsg={i18n.message("aria.label.geral.mensagem.voltar.atividades", "Volta para a página de atividades.")}
              funcao={voltar}
              classeComplementar="weex-botao-confirmacao__texto-comprido__missao-cumprida"
            />
          </MissaoCumpridaFeedbackModalStyle>
        </div>
      </div>

    </WeexModalDialog>
  )
}


export default MissaoCumpridaModal;
