import React, { useEffect, useRef, useState } from 'react';
import { AnimateOnChange } from 'react-animation';
import { useSelector, useStore } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import CardNovosHabitos from '../comps/CardNovosHabitos';
import BotaoAsALink from '../comps/botaoAsALink/BotaoAsALink';
import BotaoAsALinkIconeEsquerda from '../comps/botaoAsALink/BotaoAsAlinkWithIconLeft';
import DinamicasHeader from "../comps/dinamicasHeader/DinamicasHeader";
import WeexModal from '../comps/weexModais/WeexModal';
import AgendaService from '../services/AgendaService';
import AtividadesService from '../services/AtividadesService';
import LoaderService from '../services/LoaderService';
import { NovosHabitosStyled } from './NovosHabitosStyled';

function NovosHabitos() {
  const css = {
    width: "100%"
  }
  const i18n = useStore().getState().i18n; 
  const history = useHistory()
  const location = useLocation();
  let {codigo, codigoAtividade} = useParams()
  const atividadesService = new AtividadesService()
  const agendaService = new AgendaService()
  const loaderService = new LoaderService()
  const [processando, setProcessando] = useState(false)
  const [erro, setErro] = useState(null)
  const [novoHabito, setNovoHabito] = useState()
  const [indiceNovoHabito, setIndiceNovoHabito] = useState(0)
  const [marcadorProgresso, setMarcadorProgresso] = useState()
  const [primeiraExecucao, setPrimeiraExecucao] = useState(true)
  const [atividade, setAtividade] = useState()
  const isDesktopMode = useSelector((state) => state.isDesktopMode);
  const ref = useRef();
  useEffect(() => {
    if(!processando){
      setProcessando(true)
      atividadesService.iniciarAtividade(codigo, codigoAtividade, (erroIniciarAtividade, atividade) => {
        if(atividade){
          if(atividade.dateFinished !== null){
            agendaService.atualizarAgenda(codigo, codigoAtividade, "NEW_HABIT", atividade.newHabit.cards.length, atividade.score, (erro, sucesso) => {
              if(sucesso){
                setPrimeiraExecucao(false)
              } else if(erro){
                setErro({
                  titulo: i18n.message("dinamica.erro.jaexecutada.titulo", "Erro ao exibir atividade já executada"), 
                  mensagem: i18n.message("dinamica.erro.jaexecutada.mensagem", "Falha ao atualizar informações de atividade executadas, entre em contato com o suporte"), 
                  voltarAgenda: true})
              }
            })
          }
          setNovoHabito(atividade.newHabit)
          setAtividade(atividade)
          atividade.newHabit.theme.css = {background: atividade.newHabit.theme.backgroundColor, color: atividade.newHabit.theme.foregroundColor}
          let dimensoes = (atividade.newHabit.cards.length < 10 ? 4 : 40/atividade.newHabit.cards.length)
          if(isDesktopMode){
            dimensoes = (dimensoes/4) + 'vw'
          }else{
            dimensoes = (dimensoes) + 'vw'
          }
                    
          if(atividade.newHabit.cards.length > 1){
            loaderService.load(atividade.newHabit.cards[1].picture)
          }
          let progresso = atividade.newHabit.cards.map((a, i) => {
            return indiceNovoHabito >= 0 ? (
              (i <= indiceNovoHabito) ? (<div className="marcador marcador-concluido" style={{width: dimensoes, height: dimensoes}} key={i}></div>) :
                (<div className="marcador" style={{width: dimensoes, height: dimensoes}} key={i}></div>)
            ):
              null

          })
          setMarcadorProgresso(progresso)
        }
        if(erroIniciarAtividade){
          if (erroIniciarAtividade.response && erroIniciarAtividade.response.data && erroIniciarAtividade.response.data.message) {
            setErro({
              titulo: i18n.message("dinamica.erro.iniciar.titulo", "Erro ao iniciar atividade"), 
              mensagem: erroIniciarAtividade.response.data.message, voltarAgenda: true})
          } else {
            setErro({
              titulo: i18n.message("dinamica.erro.iniciar.titulo", "Erro ao iniciar atividade"), 
              mensagem: i18n.message("dinamica.erro.iniciar.mensagem", "Entre em contato com o suporte ou tente novamente mais tarde."), 
              voltarAgenda: true})
          }
        }
      })
    }
  }, [atividadesService, agendaService, codigo, codigoAtividade, i18n, indiceNovoHabito, isDesktopMode, loaderService, processando]);

  const callbackModal = () => {
    setErro(null)
    if(erro.voltarAgenda){
      history.push("/atividades")
    }
  }

  const finalizar = () => {
    window.scrollTo(0, 0)
    if(primeiraExecucao){
      atividadesService.finalizarAtividade(codigo, codigoAtividade, "NEW_HABIT", {}, (erroFinalizarAtividade, atividade) => {
        if(atividade){
          history.push('/missionFinished/' + codigo + '/NEW_HABIT/' + codigoAtividade)
        }
        if(erroFinalizarAtividade){
          if (erroFinalizarAtividade.response && erroFinalizarAtividade.response.data && erroFinalizarAtividade.response.data.message) {
            setErro({
              titulo: i18n.message("dinamica.erro.finalizar.titulo", "Erro ao finalizar atividade"), 
              mensagem: erroFinalizarAtividade.response.data.message, voltarAgenda: false})
          } else {
            setErro({
              titulo: i18n.message("dinamica.erro.finalizar.titulo", "Erro ao finalizar atividade"), 
              mensagem: i18n.message("dinamica.erro.finalizar.mensagem", "Entre em contato com o suporte ou tente novamente mais tarde."), 
              voltarAgenda: false})
          }
        }
      })
    } else {
      atividadesService.finalizarAtividade(codigo, codigoAtividade, "NEW_HABIT", {}, (erroFinalizarAtividade, sucesso) => {
        if(sucesso){
          history.push('/missionFinished/' + codigo + '/NEW_HABIT/' + codigoAtividade)
        } else if(erroFinalizarAtividade){
          setErro(
            {
              titulo: i18n.message("dinamica.erro.finalizar.titulo", "Erro ao finalizar atividade"), 
              mensagem: i18n.message("dinamica.erro.finalizar.mensagem", "Falha ao atualizar informações de atividade executadas, entre em contato com o suporte"), 
              voltarAgenda: false})
        }
      })
    }
  }

  const proximoCard = () => {
    ref.current.scrollTo(0, 0)
    window.scrollTo(0, 0)
    let indice = indiceNovoHabito + 1
    if(indice === novoHabito.cards.length){
      return
    }
    if(novoHabito.cards.length > indice + 1){
      loaderService.load(novoHabito.cards[indice + 1].picture)
    }
    setIndiceNovoHabito(indice)
    let dimensoes = (atividade.newHabit.cards.length < 10 ? 4 : 40/atividade.newHabit.cards.length)
    if(isDesktopMode){
      dimensoes = (dimensoes/4) + 'vw'
    }else{
      dimensoes = (dimensoes) + 'vw'
    }
    let progresso = novoHabito.cards.map((a, i) => {
      return (i <= indice) ? (<div className="marcador marcador-concluido" style={{width: dimensoes, height: dimensoes}} key={i}></div>) :
        (<div className="marcador" style={{width: dimensoes, height: dimensoes}} key={i}></div>)
    })
    setMarcadorProgresso(progresso)
  }
  return (
    <NovosHabitosStyled>
      <div className='desktop-novos-habitos'>
        {erro != null ? (
          <WeexModal 
            fecharModalCallback={callbackModal}
            titulo={erro.titulo} 
            conteudo={erro.mensagem}
          />
        ) : null}
        {isDesktopMode ? 
          <div className='botao-voltar-desktop'>
            <BotaoAsALinkIconeEsquerda
              icon="fas fa-arrow-left "
              classeComplementar="header-como-funciona-desktop-botao"
              nome={i18n.message("geral.voltar", "Voltar")}
              ariaLabelMsg={i18n.message("aria.label.geral.mensagem.voltar.atividades", "Volta para a página de atividades.")}
              funcao={() => { 
                history.push("/home")
              }}
            />
          </div>:""
        }
                
        <div className="dinamica-container">
          <AnimateOnChange style={css}>
            {novoHabito != null ? (
              <div className='desktop-content'>
                <div className='desktop-content-left'>
                  <DinamicasHeader
                    dinamica={novoHabito}
                    theme={novoHabito.theme}
                    dinamicaNome={i18n.message("dinamica.novoshabitos.nome", "Novos Hábitos")}
                    dinamicaAlt={i18n.message("alt.dinamica.novoshabitos", "Ícone de uma cabeça com uma engrenagem dentro dando a entender que está pensando.")}
                    dinamicaImg={location && location.state && location.state.icon ? location.state.icon : "/new_novos_habitos.svg"}
                  />
                </div>
                <div className='desktop-content-right'>
                  <div className='card-novos-habitos box-custom'>
                    <section ref={ref} className = 'desktop-content-right-CardNovosHabitos'>
                      <CardNovosHabitos
                        ultimo={indiceNovoHabito >= (novoHabito.cards.length - 1)}
                        cardPictureSrc={indiceNovoHabito >= 0 ? novoHabito.cards[indiceNovoHabito].picture : null}
                        cardDescription={indiceNovoHabito >= 0 ? novoHabito.cards[indiceNovoHabito].description : null}
                        proximoHabitoCallback={proximoCard}
                        finalizarCallback={finalizar}
                      />
                    </section>
                                        
                    {isDesktopMode ? "":
                      <>
                        <div className="marcador-progresso" style={{ marginBottom: '15px'}}>
                          {marcadorProgresso}
                        </div>
                        <div className='botão-voltar-mobile' style={{marginBottom: '2rem'}}>
                          <BotaoAsALink
                            nome={i18n.message("missaoCumprida.repetir", "Voltar")}
                            ariaLabelMsg={i18n.message("aria.label.geral.mensagem.voltar.atividades", "Volta para a página de atividades.")}
                            funcao={() => history.push("/")}
                          />
                        </div>
                      </>
                                       
                    }
                                        
                  </div>
                  {
                    isDesktopMode ?
                      <div className="marcador-progresso" style={{ marginBottom: '15px'}}>
                        {marcadorProgresso}
                      </div>:""
                  }
                                       
                                    
                </div>
              </div>
            ) : (null)}
          </AnimateOnChange>
        </div>
      </div>

    </NovosHabitosStyled>
  )
} 

export default NovosHabitos;