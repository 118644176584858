import styled from "styled-components";

export const WeexModalStyle = styled.dialog`

.weex-modal-card {
	width: 85vw;
	margin-left: 7.5vw;
	margin-right: 7.5vw;
	/* background: ${props => {return props.theme.fundoTres}}; */
	background: #FFFFFF;
	border-radius: 8px;
	box-shadow: 0px 20px 40px rgba(0, 0, 0, 0.17);
}

.not-padding-left{
	padding-left: 0;
}

.textarea-mural{
	line-height: initial;
}

.preview{
	margin-top: 1em;
	width: 25%;
	height: 25%;
	object-fit: cover;
}

.count-text-area{
	float: right;
}

.overflow{
	overflow: scroll;
}

#input-file{
	display: none;
}

.display-icon{
	display: flex;
}

.title-mensagem-video{
	font-weight: 400;
}

.imagem-publicacao{
	padding: 0.5em;
	margin-top: 1em;
	border-radius: 8px;
	padding-top: 1.2em;
	padding-bottom: 1.2em ;
	font-size: 12px;
}
.weex-modal-background {
	background: rgb(255 255 255 / 80%);
	/* background: transparent; */
}

.modal-card-head{
    justify-content: flex-end;

}

.close-btn{
	border: none;
	background: transparent;
	padding: 0px;
	margin: 0px;
	cursor: pointer;

	:active{
		filter: brightness(0.5);
	}
}

.weex-modal-card-header {
	background: white;
    border: none;
}

.weex-modal-card-header-publicacao {
	padding: 10px;
}
.titulo-icone{
	display: flex;
	flex-direction: row;
    align-items: baseline;
	justify-content: center;
}

.color-fundo-secundaria{
	color: ${props => {return props.theme.fundoSecundario||`#533888`}};
}


.pointer{
    cursor: pointer;
}

.spinner{
	display: "flex";
    justify-content: "center";
    margin-bottom: "2em";
	margin: auto;
}

.weex-modal-interests-header{
	padding-top: 10px;
	padding-bottom: 10px;
}

.wex-modal-card-header-title {
	display: flex;
    flex-direction: column;
	justify-content: center;
	align-items: center;

    .data {
        color: ${props => {return props.theme.cardLogadoTextoTerceiro}};
        font-weight: 600px;
        font-size: 11px;
        line-height: 17px;
    }

    .nome-atividade {
        color: ${props => {return props.theme.fundoSecundario}};
        font-weight: 800px;
        font-size: 15px;
        line-height: 17px;
        text-transform: uppercase;
		text-align: center;
    }

	.nome-atividade-font-weight-normal{
		font-weight: normal
	}
}

.wex-modal-card-img{
	display: flex;
	justify-content: center;
	align-items: center;
}

.espacamento-fim{
	margin-bottom: 1.5rem
}

.weex-modal-card-body__texto {
	margin: 1rem 1rem 2.5rem 1rem;

	p{
		color: ${props => { return props.theme.cardLogadoTextoSegundo }};
        font-weight: 400px;
        font-size: 12px;
        line-height: 16px;
		text-align: center;
	}
}

.weex-modal-card-body__publicacao {
	margin: 1rem 1rem 0.5rem 1rem;
}

.weex-modal-card-footer {
	background: white;
    border: none;
}

.espaço{
	margin-right: 10px;
	box-shadow: 0px 1px 40px -5px rgb(0 0 0 / 25%);
}
 .modal-card-foot-button-desktop{
	display:none
}
 .missao-cumprida{
	display: block;
}


.modal-card-foot-button-mobile{
	display:block;
	width: 100%;
}
.weex-mural-button{
	pointer-events:none;
	cursor: not-allowed;
}

.modal-title-anexar-video__texto{
	margin-bottom: 2em;
	font-weight: 700;
	font-size: 14px !important;
	line-height: 14px ;
}


.video-mural{
	width: 100%;
	margin: auto;
}

.video-mural > iframe{
	width: 100%;
	height: 100%;
	margin: auto;
}

.youtube{
	margin-top: 1em;
}

.modal-card-body-publicacao{
	padding: 10px 10px 0px 10px;
}

.video-publicacao{
	pointer-events: none;
	margin-left: 3.5rem;
	margin-top: 1rem;
}

.video-publicacao-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

#input-file:disabled + label{
	cursor: not-allowed;
	filter: opacity(0.5);
}

/*media query min-width 700px*/
@media screen and (min-width: 700px) {
	.modal {

	}
	.weex-modal-card {
		width: calc(500px - 8em);
		margin-left: calc(40vw - (208px + 1.0em));
		margin-right: calc(40vw - (208px + 1.0em));
	}

	.modal-card {
		width: 450px;
		margin-left: calc(50vw - 208px);
		margin-right: calc(50vw - 208px);
	}

	.modal-card-publicacao{
    	display: flex;
    	flex-direction: column;
	    max-height: calc(100vh - 5px);
	}


	.weex-modal-card-body__texto {
		p{
			font-weight: 400px;
		}

	}
	.modal-card-foot-button-desktop{
		width: 100%;
		display: flex;
		justify-content: center;
		margin-bottom: 2em;
	}
	.modal-button{
			width: 60%;
	}
	.modal-card-foot-button-mobile{
		display:none;
	}

	.wex-modal-card-header-title{
		margin-left: 3vw;
		margin-right: 3vw;
	}

	.header-atividades{
		margin-right: 1vw;
		margin-top : 1vh;
		padding: 1px;
		background: transparent;
		z-index: 99999;
	}

	.titulo-mural-modal{
		width: 100%;
		margin-left: 1em;
		font-size: 1.6em;
		font-weight: bold;
	}
	.icon-mural-modal{
		margin-right: 0.4em;
		font-weight: 500;
	}
	.weex-modal-card-body__texto {
		margin: 1rem 2rem 2.5rem 2rem;
	}

	.modal-card-foot weex-modal-card-footer{
		min-height: 20vh;
	}

	.weex-modal-card{

	}
	.modal-card-body{
		overflow-x:hidden;
		::-webkit-scrollbar {
		width: 5px;
		}

		::-webkit-scrollbar-track {
		box-shadow: inset 0 0 5px transparent ;
		border-radius: 10px;
		}

		::-webkit-scrollbar-thumb {
		background: ${props => { return props.theme.fundoSecundario }};;
		border-radius: 10px;
		}

		::-webkit-scrollbar-thumb:hover {
		background: ${props => { return props.theme.fundoSecundario || `#533888` }};
		}

	}

	.titulo-icone{
		display: flex;
		flex-direction: row;
		align-items: flex-start;
		justify-content: flex-start;
		margin-left: 1.4em;
	}
	.titulo-modal{
		margin-top: 0.5em;

	}

	.atv-card-desktop{
		margin-left: 0 !important;
	}
	.dia-container > div {
		min-height:auto;
		width: 100%;
		max-height: 100%;
		border: none;
		margin-bottom: 0;
	}
	.final-atividades{
		max-height: 100% !important;

	}
	.atividade-section{
		padding: 0;
		margin-top: -1.7em;
	.weex-modal-card-body__publicacao {
		margin: 1rem 1rem 0.5rem 1rem;
	}

}


`
