import "bulma/css/bulma.css";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { Weex } from "./AppStyle";
import Footer from "./comps/Footer";
import LogosCampanha from "./comps/LogosCampanha/LogosCampanha";
import Header from "./comps/header/Header";
import MissaoCumpridaModal from "./comps/weexModais/MissaoCumpridaModal";
import AcceptLegalTerm from "./pages/AcceptLegalTerm";
import AtivarParticipante from "./pages/AtivarParticipante";
import Atividades from "./pages/Atividades/Atividades";
import CallbackGame from "./pages/CallbackGame";
import ChangePassword from "./pages/ChangePassword";
import Clean from "./pages/Clean";
import ComoFunciona from "./pages/ComoFunciona";
import FatoFake from "./pages/FatoFake";
import Feedback from "./pages/Feedback/Feedback";
import GameRankings from "./pages/GameRankings/GameRankings";
import Home from "./pages/Home/Home";
import Interesses from "./pages/Interesses";
import LoginRegister from "./pages/LoginRegister";
import NovosHabitos from "./pages/NovosHabitos";
import RegisterSelector from "./pages/RegisterSelector";
import ResetPassword from "./pages/ResetPassword";
import SeletorCampanha from "./pages/SeletorCampanha";
import Trofeus from "./pages/Trofeus/Trofeus";
import Version from "./pages/Version";
import Videos from "./pages/Videos/Videos";
import Extra from "./pages/extra/Extra";
import Game from "./pages/gamer/Game";
import Mural from "./pages/mural/Mural";
import CampanhaService from "./services/CampanhaService";
import UsuarioService from "./services/UsuarioService";

function App(props) {
  const [modo, setModo] = useState("");
  const usuario = useSelector((state) => state.usuario);
  const [usuarioLogado, setUsuarioLogado] = useState(
    new UsuarioService().usuarioCorrente()
  );
  const dispatch = useDispatch();
  const backgroundIsPresent = useSelector((state) => state.backgroundIsPresent);

  window.addEventListener('resize', function(){
    if(window.innerWidth > 700) {
      dispatch({ type: "isDesktopMode", payload: true });
    } else {
      dispatch({ type: "isDesktopMode", payload: false });
    }
  })

  const callbackLogin = (user) => {
    setUsuarioLogado(user);
  };

  const callbackModo = (modo) => {
    setModo(modo);
  };

  const campanhaService = new CampanhaService();

  return (
    <Router>
      <Weex
        usuarioLogado={usuarioLogado}
        usuario={usuario}
        backgroundIsPresent={backgroundIsPresent}
        className="weex"
      >
        <Header usuarioLogado={usuarioLogado} />
       
        <LogosCampanha modo={modo} />
        <main
          className={
            usuarioLogado && usuario === true
              ? `weex-container weex-content weex-main-content-border weex-container-logado-grow`
              : `weex-container weex-container-deslogado-grow`
          }
        >
          <Switch>
            <Route path="/home" component={Home} />
            <Route path="/help" component={ComoFunciona} />
            <Route path="/clean" component={Clean} />
            <Route path="/select" component={SeletorCampanha} />
            <Route path="/version" component={Version} />
            <Route path="/accept">
              <AcceptLegalTerm callbackLogin={callbackLogin} />
            </Route>
            <Route path="/campaign/:code" component={SeletorCampanha} />
            <Route path="/resetPassword" component={ResetPassword} />
            <Route path="/passwordReset" component={ChangePassword} />
            <Route
              path="/activateSubscription/:code"
              component={AtivarParticipante}
            />
            <Route path="/interests" component={Interesses} />
            <Route path="/atividades" component={Atividades} />
            <Route
              path="/fatoFake/:codigo/:codigoAtividade"
              component={FatoFake}
            />
            <Route path="/video/:codigo/:codigoAtividade" component={Videos} />
            <Route path="/mural/:codigo/:codigoAtividade" component={Mural} />
            <Route
              path="/video-message/:codMensagemDeVideo"
              component={Videos}
            />
            <Route path="/game/:codigo/:codigoAtividade" component={Game} />
            <Route path="/extra/:codigo/:codigoAtividade" component={Extra} />
            <Route
              path="/newHabit/:codigo/:codigoAtividade"
              component={NovosHabitos}
            />
            <Route path="/trophy" component={Trofeus} />
            <Route
              path="/missionFinished/:codigoAgenda/:atividade/:codigoAtividade"
              component={MissaoCumpridaModal}
            />
            <Route path="/feedback" component={Feedback} />
            <Route path="/callbackGame/:codigo" component={CallbackGame} />

            <Route path="/registerSelector">
              <RegisterSelector
                callbackLogin={callbackLogin}
              ></RegisterSelector>
            </Route>
            <Route 
              path="/gameRankings" 
              component={GameRankings} />

            <Route path="/">
              <LoginRegister
                callbackLogin={callbackLogin}
                callbackModo={callbackModo}
              ></LoginRegister>
            </Route>
          </Switch>
        </main>
        <Footer
          usuarioLogado={usuarioLogado}
          campanhaService={campanhaService}
        />
      </Weex>
    </Router>
  );
}

export default App;
