import instance from "./Api";
import CampanhaService from './CampanhaService';

export default class MensagemVideoService {
	campanhaService = new CampanhaService();
	campanhaCorrente = this.campanhaService.campanhaCorrente();
	
	obterMensagemDeVideo(callback) {
	  if (this.cacheStorageValido()) {
	    callback(null, this.obterMensagemDeVideoStorage());
	  } else{
	    instance
	      .get(this.host() + "/api/v1/campaign/" + this.campanhaCorrente.code + "/video-message")
	      .then((response) => {
	        window.localStorage.setItem(
	          "aviso",
	          JSON.stringify(response.data)
	        );
	        window.localStorage.setItem(
	          "validade-cache-aviso",
	          new Date().getTime() + 1 * 60 * 60 * 1000
	        );
	        callback(null, response.data);
	      })
	      .catch((erro) => {
	        callback(erro, null);
	      });
	  }

	}

	marcarComoVisto(uuidMensagemDeVideo, callback){
	  instance
	    .post(this.host() + "/api/v1/campaign/" + this.campanhaCorrente.code + "/video-message", {code: uuidMensagemDeVideo})
	    .then((response) => {
	      window.localStorage.setItem(
	        "aviso",
	        JSON.stringify(response.data)
	      );
	      callback(null, response.data);
	    })
	    .catch((erro) => {
	      callback(erro, null);
	    });
	}

	obterMensagemDeVideoStorage(){
	  let avisoStorage = window.localStorage.getItem("aviso");
	  if(avisoStorage){
	    return JSON.parse(avisoStorage);
	  } else {
	    return null;
	  }
	}

	cacheStorageValido(){
	  let avisoStorage = window.localStorage.getItem("validade-cache-aviso");
	  if(avisoStorage){
	    return parseInt(avisoStorage) > new Date().getTime();
	  } else{
	    return false;
	  }
	}

	host() {
	  return process.env.REACT_APP_HOST_API;
	}
}
