import CampanhaServ from "../services/CampanhaService";
import UsuarioService from "../services/UsuarioService";
import { versao } from "../versao";
import instance from "./Api";
import PwaService from "./PwaService";

export default class CampanhaService {
	usuarioService = new UsuarioService();
	usuarioCorrente = this.usuarioService.usuarioCorrente();
	pwaService = new PwaService();
	campanhaService = new CampanhaServ();

	agendaCorrente(callback) {
	  this.cacheValido((erro, valido) => {
	    if (valido.campaign === true) {
	      callback(null, this.obterAgendaStorage());
	    } else {
	      if (this.pwaService.estouOnline()) {
	        if (!this.usuarioCorrente) {
	          this.usuarioService.logout(() => {
	            callback(true, null);
	          });
	          return;
	        }

	        instance
	          .get(
	            this.host() +
								"/api/v1/participant/" +
								this.usuarioCorrente.participantCode +
								"/schedule"
	          )
	          .then((response) => {
	            window.localStorage.setItem(
	              "agenda",
	              JSON.stringify(response.data)
	            );
	            window.localStorage.setItem(
	              "validade-cache-agenda",
	              new Date().getTime() + 2 * 60 * 60 * 1000
	            );
	            callback(null, response.data);
	          })
	          .catch((error) => {
	            if (error.status !== 401 && error.status !== 403) {
	              callback(null, this.obterAgendaStorage());
	            }
	          });
	      } else {
	        callback(null, this.obterAgendaStorage());
	      }
	    }
	  });
	}

	invalidateCacheAndReload() {
	  if (caches) {
	    // Service worker cache should be cleared with caches.delete()
	    caches.keys().then(function (names) {
	      for (let name of names) caches.delete(name);
	    });
	  }
	  // delete browser cache and hard reload
	  window.location.reload(true);
	}

	obterAgendaStorage() {
	  let valorStorage = window.localStorage.getItem("agenda");
	  if (valorStorage) {
	    return JSON.parse(valorStorage);
	  } else {
	    return null;
	  }
	}

	versaoAlterada(callback) {
	  instance
	    .get(
	      this.host() +
					"/api/v1/weex/info?campaign=" +
					this.campanhaService.campanhaCorrente().uuid
	    )
	    .then((response) => {
	      window.localStorage.setItem(
	        "validade-cache-agenda",
	        new Date().getTime() + 30 * 60 * 1000
	      );
	      let versoes = {};
	      if (
	        (response.data.campaign === null &&
						this.campanhaService.campanhaCorrente().version === null) ||
					(response.data.campaign != null &&
						this.campanhaService.campanhaCorrente().version !== null &&
						response.data.campaign ===
							this.campanhaService.campanhaCorrente().version)
	      ) {
	        versoes.campaign = false;
	      } else {
	        versoes.campaign = true;
	      }
	      if (response.data.pwa === null || response.data.pwa === versao) {
	        versoes.pwa = false;
	      } else {
	        versoes.pwa = true;
	      }

	      callback(null, versoes);
	    })
	    .catch((error) => {
	      if (error.status !== 401 && error.status !== 403) {
	        callback(null, { campaign: false, pwa: false });
	      }
	    });
	}

	cacheValido(callback) {
	  if (this.obterAgendaStorage()) {
	    let valorStorage = window.localStorage.getItem("validade-cache-agenda");
	    if (valorStorage) {
	      let valido = parseInt(valorStorage) > new Date().getTime();
	      if (!valido) {
	        this.versaoAlterada((erro, versoes) => {
	          callback(null, this.preencheCacheValido(versoes));
	        });
	      } else {
	        callback(null, { campaign: true, pwa: true });
	      }
	    } else {
	      this.versaoAlterada((erro, versoes) => {
	        callback(null, this.preencheCacheValido(versoes));
	      });
	    }
	  } else {
	    callback(null, { campaign: false, pwa: true });
	  }
	}

	atualizarAgenda(codigo, codigoAtividade, atividade, numeroQuestoes, acertos, callback) {
	  let agenda = this.obterAgendaStorage();
	  if (!agenda) {
	    //não deveria ocorrer
	    this.agendaCorrente(callback);
	    return;
	  }
	  for (let i = 0; i < agenda.schedule.length; i++) {
	    if (agenda.schedule[i].scheduleCode === codigo) {
	      for (let j = 0; j < agenda.schedule[i].activities.length; j++) {
	        if (agenda.schedule[i].activities[j].type === atividade && agenda.schedule[i].activities[j].uuid === codigoAtividade) {
	          agenda.schedule[i].activities[j].executed = true;
	          if (atividade === "FACT_OR_FAKE") {
	            agenda.schedule[i].activities[j].numeroQuestoes = numeroQuestoes;
	            agenda.schedule[i].activities[j].acertos = acertos;
	          }
	          window.localStorage.setItem("agenda", JSON.stringify(agenda));
	          callback(null, true);
	          return;
	        }
	      }
	      callback(true, null);
	      return;
	    }
	  }
	  callback(true, null);
	}

	atualizarInteresse(interesse) {
	  let valorStorage = window.localStorage.getItem("agenda");
	  let valorStorageValidade = window.localStorage.getItem(
	    "validade-cache-agenda"
	  );
	  if (interesse === true && valorStorage && valorStorageValidade) {
	    window.localStorage.removeItem("agenda");
	    window.localStorage.removeItem("validade-cache-agenda");
	  }
	}

	preencheCacheValido(versoesAlteradas) {
	  let cacheValido = { campaign: true, pwa: true };
	  if (versoesAlteradas.campaign != null) {
	    cacheValido.campaign = !versoesAlteradas.campaign;
	  } else {
	    cacheValido.campaign = false;
	  }
	  if (versoesAlteradas.pwa != null) {
	    cacheValido.pwa = !versoesAlteradas.pwa;
	  } else {
	    cacheValido.pwa = false;
	  }
	  return cacheValido;
	}

	host() {
	  return process.env.REACT_APP_HOST_API;
	}
}
