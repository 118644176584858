import React from 'react';
import { HideUntilLoaded } from 'react-animation';
import BotaoSecundario from './botaoSecundario/BotaoSecundario';

export default function CardFatoFake(props) {

  const botaoSecundarioStyles = {
    display: "flex", 
    alignItems: "center", 
    justifyContent: 'center', 
    padding: "10px"
  }

  return (
    <div className='box-custom'>
      <div className="card weex-card-interno">
        {props.fatoFake?.picture && (
          <HideUntilLoaded style={{width: "100%"}}>
            <figure className="image weex-card-container-imagem retirar-padding">
              <img className='weex-card-container-imagem-desktop image is-2by1 retirar-padding' src={props.fatoFake.picture} alt={props.i18n.message("alt.dinamica.imagem.fatofake", "Imagem ilustrativa das questões de fato ou fake")}/>
            </figure>
          </HideUntilLoaded>
        )}
        <div className="card-content">
          <div className="content weex-card-interno__content weex-card-interno__content__fato-fake__novos-habitos">
            <div className='markup__fato-fake' dangerouslySetInnerHTML={{__html: props.fatoFake?.enunciation}}></div>
          </div>
          <div style={{
            display: 'flex', 
            justifyContent: 'space-around'
          }}>
            <BotaoSecundario
              classeComplementar="weex-button__ff"
              nome={
                <div style={botaoSecundarioStyles}>
                  {props.i18n.message("dinamica.fatofake.fato", "Fato")}
                  <img 
                    alt={props.i18n.message("alt.dinamica.fatofake.fatoButtonImage", "Imagem do botão fato")} 
                    src="/positivo_dois.svg" 
                    className="image is-24x24" 
                    style={{marginRight: "5px", marginLeft: "16px"}}
                  /> 
                </div>
              }
              funcao={() => props.responderFatoFake(true)}
            />
            <BotaoSecundario
              classeComplementar="weex-button__ff"
              nome={
                <div style={botaoSecundarioStyles}>
                  {props.i18n.message("dinamica.fatofake.fake", "Fake")}
                  <img 
                    src="/negativo_dois.svg" 
                    className="image is-24x24" 
                    alt={props.i18n.message("alt.dinamica.fatofake.fakeButtonImage", "Imagem do botão fake")} 
                    style={{marginRight: "5px", marginLeft: "16px"}}
                  />
                </div>
              }
              funcao={() => props.responderFatoFake(false)}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
