import React, { useEffect, useRef, useState } from 'react';
import { animations } from 'react-animation';
import InfiniteScroll from 'react-infinite-scroller';
import MuralService from '../../services/MuralService.js';
import BotaoPrimario from '../botaoPrimario/BotaoPrimario.js';
import PublicacaoMural from '../publicacao/PublicacaoMural.js';
import Spinner from '../publicacao/Spinner.js';
import EscreverPublicacao from './EscreverPublicacao.js';
import { WeexModalDialog } from './WeexModalDialog';
import { useStore } from 'react-redux';

/**
 * @param {Object} props
 * @param {string} props.titulo
 * @param {any} props.publicacao TODO: Verificar a tipagem dessa propriedade, e desestrutura-la em tipos mais simples ao longo dos componentes que a recebem
 * @param {any} props.imagemPublicacaoMural TODO: Verificar a tipagem dessa propriedade, e desestrutura-la em tipos mais simples ao longo dos componentes que a recebem
 * @param {function(): void} props.fecharModalCallback
*/
function ModalComentarios({
  fecharModalCallback,
  publicacao,
  imagemPublicacaoMural,
  titulo
}) {
  const i18n = useStore().getState().i18n
  const [mostrarModalEscreverPublicacao, setMostrarModalEscreverPublicacao] = useState(false);
  const [mostrarModalComentarios, setMostrarModalComentarios] = useState(true)
  const [listComentarios, setListComentarios] = useState([]);
  const [maisComentarios, setMaisComentarios] = useState(true);
  const [page, setPage] = useState(0);
  const escreverPulicacaoModalRef = useRef(null)

  useEffect(() => {
    const service = new MuralService();

    service.buscarComentarios(publicacao, {}, (erro, sucess) => {
      if (sucess) {
        setListComentarios(sucess);
        setMaisComentarios(sucess.length > 49);
      }
      if (erro) {
        alert(JSON.stringify(erro));
      }
    })
  }, [publicacao]);



  const abrirModalEscrita = () => {
    setMostrarModalEscreverPublicacao(true)
  }

  const deletarComentario = (uuid) => {
    let listComentariosAtualizada = listComentarios.filter((comentario) =>
      comentario.uuid !== uuid
    );
    setListComentarios(listComentariosAtualizada)
  }

  const criarComentario = (request) => {

    const service = new MuralService();

    service.criarComentario(publicacao, request, (error, sucess) => {
      if (sucess) {
        let list = listComentarios
        list.unshift(sucess);
        setListComentarios([...list]);
        fecharModalEscrita();
        window.scrollTo();
      } else {
        alert(JSON.stringify(error));
      }
    })
  }

  const scroll = () => {

    if (maisComentarios) {
      setMaisComentarios(false)
      const service = new MuralService();
      let nextPage = page + 1;
      setPage(nextPage)
      let resquest = {
        page: nextPage,
      }
      service.buscarComentarios(publicacao, resquest, (_erro, sucess) => {
        if (sucess) {
          setListComentarios(listComentarios.concat(sucess));
          if (!sucess.length > 0) {
            setMaisComentarios(false)
          } else {
            setMaisComentarios(true)
          }
        } else {
          setMaisComentarios(false);
        }
      })
    }

  }

  const fecharModalEscrita = () => {
    setMostrarModalEscreverPublicacao(false)
  }

  const fecharModalComentarios = () => {
    setMaisComentarios(false);
    fecharModalCallback()
    setMostrarModalComentarios(false)
    escreverPulicacaoModalRef.current.close()
  }

  /* TODO:
        Mover essas mensagens i18n para um escopo menos global,
        pois o i18n passado para publicação mural pode ser o mesmo 
    */
  const mensagemExcluir = i18n.message("dinamica.mural.comentario.excluir", "Tem certeza que deseja excluir essa comentário?")
  const dropdow = i18n.message("dinamica.mural.comentario.dropdown", "Excluir comentário")
  const ariaTextArea = i18n.message("aria.label.escrever.comentario", "Escreva seu comentario para a publicação")
    
  return (
    mostrarModalComentarios ? (
      <WeexModalDialog
        dialogRef={escreverPulicacaoModalRef}
        onPressESC={fecharModalComentarios}
      >
        {mostrarModalEscreverPublicacao && (
          <EscreverPublicacao 
            ariaTextArea={ariaTextArea} 
            fecharModalCallback={fecharModalEscrita} 
            isPublicacao={false} 
            create={criarComentario}
          />
        )}
        <div 
          className={'modal is-active'} 
          style={{ 
            animation: animations.popIn, 
            zIndex: '102' 
          }}
        >
          <div className="modal-background weex-modal-background"></div>
          <div className="modal-card weex-modal-card">
            <header className="modal-card-head weex-modal-card-header">
              <button
                onClick={fecharModalComentarios}
                className="close-btn"
                aria-label={i18n.message("aria.label.mural.fechar.modal", "Fecha a janela e volta para a página mural social.")}
              >
                <img
                  src="/close-btn.png"
                  alt={i18n.message("alt.button.imagem.fechar", "ícone em formato de um X indicando o encerramento de uma ação.")}
                />
              </button>
            </header>
            <header className="weex-modal-card-header wex-modal-card-header-title">
              <div className="display-icon titulo-mural-modal">
                <span className="icon color-fundo-secundaria icon-mural-modal">
                  <i className="fa fa-comment-dots"></i>
                </span>
                <h1 className="nome-atividade">{titulo}</h1>
              </div>
            </header>
            <section className="modal-card-body has-text-centered">
              <InfiniteScroll
                loadMore={scroll}
                hasMore={maisComentarios}
                initialLoad={false}
                loader={<div>
                  <Spinner isVisivel={maisComentarios} />
                </div>}
                useWindow={false}
              >
                {listComentarios ? (
                  listComentarios.map((publicacao) => {
                    let publicacaoComponent = {
                      name: publicacao.autor,
                      data: publicacao.data,
                      conteudo: publicacao.postagem,
                      isPublicacao: false,
                      tempo: publicacao.tempoPublicacao,
                      uuid: publicacao.uuid,
                      doUsuario: publicacao.publicacaoDoUsuario
                    }
                    return <PublicacaoMural 
                      key={publicacaoComponent.uuid} 
                      elemento={publicacaoComponent} 
                      deletar={deletarComentario} 
                      msgExcluir={mensagemExcluir} 
                      dropdown={dropdow} 
                      publicacao={publicacao} 
                      imagemPublicacaoMural={imagemPublicacaoMural}
                    />
                  })
                ) : ""}
              </InfiniteScroll>
            </section>
            <footer className="modal-card-foot weex-modal-card-footer" style={{ textAlign: 'center' }}>
              <div style={{
                position: 'sticky',
                bottom: 0,
                width: '100%',
              }}>
                <BotaoPrimario
                  className="my-4 "
                  nome={i18n.message("dinamica.mural.comentar", "COMENTAR")}
                  funcao={abrirModalEscrita}
                  ariaStatusMessage={i18n.message("aria.status.modalcomentarios.botaocomentar", "Botão comentar clicado.")}
                />
              </div>
            </footer>
          </div>
        </div>
      </WeexModalDialog>
    ) : <></>
  );
}

export default ModalComentarios;
