import styled from "styled-components";


export const GameRankingsStyled = styled.div`

    .select-custom{
        width: 100%;
    }
    p{
        font-weight: bold;
    }
    .label-select{
        margin-bottom: 0.5em;
        margin-left: 0.2em;
    }

    .isMobile{
        display: block;    
    }
    .pontuacao-usuario-corrente-mobile{
        margin-top: 3em;
    }
   
    h4{
        text-align: center;
        text-transform: uppercase;
        font-weight: 700;
        color: ${props => {
    if (props.backgroundIsPresent) {
      return props.theme.fundoTres
    } else {
      return props.theme.fundoSecundario || `#533888`
    }
  }};
        line-height: 15px;
        margin-top: 20px;
        margin-bottom: 10px;
    }
    
    .lideranca-content{
        display: flex;
        justify-content: space-evenly;
        align-items: flex-start;
    }

    .content{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 30%;

    }

    .dinamica-title-lideranca{
        margin-top: 3em;
        margin-bottom: 3em;
    }

    .lideranca-content div:nth-child(1){        
        order: 1;
    }

    .lideranca-content div:nth-child(1)> span{        
        font-size: 1.5em;
    }

    .lideranca-content div:nth-child(2) {
        margin-top: 1.5em;
        order: 0;
    }

    .lideranca-content div:nth-child(3){
        margin-top: 3em;
        order: 2;
    }

    

    .nome-ranking{
        text-align: center;
        margin: 0.5em 3vw; 
        height: 4.5em;
        overflow: hidden;

    }
    .pontuacao-ranking{
        margin-top : 0.5em;
        color: #67CE67;

    }

    .posicao{
        font-weight: 700;
    }
    .dinamica-container{
            margin-top: 1vw;
    }


    .size-content{
        display: none;
    }

    .ranking-base{
        display: block;
        margin-top: 0.5em;
        border: none;
        margin-bottom: 0.5em;
        margin-left: auto;
        margin-right: auto;
        height: 0.3em;       
        width: 50%;
        background-color: #847D92;
        
    }
    .ranking-page{
        display: flex;
        flex-direction: column;
    }

    .ranking-list{
        margin-top: 0em;
    }

    

    @media screen and (min-width: 700px) {
        width: 100%;    
        h3{
            margin: 0;
            padding: 0;
        }
        .isMobile{
        display: none;    
        }
        .header-ranking{
            display: flex;
            justify-content: flex-start;

        }
        .dinamica-title-desktop{
            margin-top: 3em;
        }
        .size-content{
            min-width: 50%;
        }

        .ranking-list{
            min-width:60%;
            margin-top: 2.5em;
        }

        .ranking-top{
            width: 35vw;
           
        }
        .ranking-page{
            display: flex;
            flex-direction: row;
        }
        .dinamica-container{
            margin-top: 2.5vw;
        }

        .size-content{
            display: inline
        }

        .ranking-list{
            margin-top:0;
            padding-left: 5vw;
        }
        .dinamica-content-desktop{
            margin: 1vw 5vw;
            height: 79vh;
            width: 87vw;
        }
        .content-desktop{
            display: block;
        }
        
    }
`

