import React from 'react';
import { HideUntilLoaded } from 'react-animation';
import { useStore } from 'react-redux';
import BotaoPrimario from './botaoPrimario/BotaoPrimario';
import BotaoSecundario from './botaoSecundario/BotaoSecundario';

/**
 * @param {Object} props
 * @param {boolean} props.ultimo card deve aparecer então o botão de finalizar atividade 
 * @param {string} props.cardPictureSrc src da imagem do card
 * @param {string} props.cardDescription html da descrição do card
 * @param {function(): void} props.proximoHabitoCallback função que leva para o próximo card
 * @param {function(): void} props.finalizarCallback função que finaliza a atividade
 */
function CardNovosHabitos({
  ultimo,
  cardPictureSrc,
  cardDescription,
  proximoHabitoCallback,
  finalizarCallback
}) {

  const i18n = useStore().getState().i18n;
  const css = {
    width: "100%"
  };
  const createMarkup =() => {
    if(cardDescription){
      return {__html: cardDescription}
    }
  }
  return (
    <div>
      <div className="card weex-card-interno">
        <div>
          {
            cardPictureSrc &&
              (
                <div>
                  <HideUntilLoaded style={css}>
                    <figure className="image weex-card-container-imagem">
                      <img src={cardPictureSrc} alt={i18n.message("alt.novoHabito.card", "Imagem card novo hábito")} className="image is-2by1 retirar-padding" />
                    </figure>
                  </HideUntilLoaded>
                </div>
              )
          }
          <div className="card-content">
            <div className="content weex-card-interno__content weex-card-interno__content__fato-fake__novos-habitos">
              <div className='markup-novos-habitos' dangerouslySetInnerHTML={createMarkup()}></div>
            </div>
            <div className='botao-card-novos-habitos'>
              {   
                cardDescription ? (
                  ultimo ? 
                    (
                      <BotaoPrimario
                        nome={i18n.message("dinamica.novoshabitos.farei", "FAREI ISSO!")}
                        funcao={() => finalizarCallback()}
                        ariaStatusMessage={i18n.message("aria.status.cardnovoshabitos.finalizando", "Botão farei isso clicado")}
                      />
                    ): 
                    (
                      <BotaoSecundario
                        nome={i18n.message("dinamica.novoshabitos.proximo", "PRÓXIMO HÁBITO")}
                        funcao={(e) => {
                          e.target.blur();
                          window.scrollTo(0, 0)
                          proximoHabitoCallback()
                        }}
                      />
                    )
                ) : ""
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CardNovosHabitos;