import React, { useEffect, useState } from 'react';
import { useStore } from 'react-redux';
import { Link } from 'react-router-dom';
import Notificacao from '../comps/Notificacao';
import BotaoPrimario from '../comps/botaoPrimario/BotaoPrimario';
import CampanhaService from '../services/CampanhaService';
import SenhaService from '../services/SenhaService';
import TemaService from '../services/TemaService';
import { ResetarSenhaStyle } from './ResetarSenhaStyle';


function ResetPassword(props) {
  const i18n = useStore().getState().i18n; 

  const [email, setEmail] = useState({email: ""})
  const [processando, setProcessando] = useState(false)
  const [mensagemProcessamento, setMensagemProcessamento] = useState(null)
  const [sucesso, setSucesso] = useState(false)
    
  useEffect(() => {
    let campanhaService = new CampanhaService()
    let campanha = campanhaService.campanhaCorrente()
    let temaService = new TemaService(campanha)
    temaService.aplicarTema('ENTRADA')
  })

  let resetPassword = (event) => {
    setProcessando(true)
    new SenhaService().enviarEmailRestauroSenha(email, (erro, sucesso) => {
      if(sucesso){
        setProcessando(false)
        setSucesso(true)
        setMensagemProcessamento(i18n.message("resetsenha.envioemail", "Foi enviado para o email informado as instruções para troca da sua senha"))
      }
      if(erro){
        setProcessando(false)
        if (erro.response && erro.response.data && erro.response.data.message) {
          setMensagemProcessamento(erro.response.data.message)
          setSucesso(false)
        } else {
          setMensagemProcessamento(i18n.message("resetsenha.erro", "Falha ao tentar fazer o restauro da senha, entre em contato com o suporte ou tente novamente mais tarde"))
          setSucesso(false)
        }
      }
    })
    event.preventDefault()
  }

  let onChange = (event) => {
    email.email = event.target.value
    setEmail(email)
  }

  return (
        
    <ResetarSenhaStyle>
      <div className='reset-desktop'>
        <h1 className="title foreground margin-top titulo">{i18n.message("resetsenha.titulo", "Resetar a senha")}</h1>

        <label className="my-4 foreground margin-top">{i18n.message("resetsenha.instrucoes", "Digite seu e-mail no campo abaixo e clique em continuar, será enviado um e-mail para que possa redefinir sua senha.")}</label>

        <form>
          <div className="field my-4" style={{marginBottom: '20px'}}>
            <div className="control">
              <input type="email" required className="input input-size" placeholder={i18n.message("resetsenha.email", "Digite seu email")} name="email" onChange={onChange}/>
            </div>
          </div>
          <BotaoPrimario
            className="my-4"
            nome={i18n.message("geral.continuar", "Continuar")}
            disabled={processando}
            funcao={resetPassword}
            ariaStatusMessage={i18n.message("aria.status.resetpassword.botaocontinuar", "Botão continuar clicado")}
          />
          <div style={{marginTop: '20px'}}></div>
          <Link to="/" className="weex-link">{i18n.message("geral.cancelar", "Cancelar")}</Link>
        </form>

        <div className="my-3">
                    
          <Notificacao mensagem={mensagemProcessamento} sucesso={sucesso} />
                    
                
        </div>
      </div>
               
    </ResetarSenhaStyle>
            
       
  )
}

export default ResetPassword;