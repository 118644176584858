import styled from "styled-components";

export const AgendaDiaStyle = styled.div`
    border-radius: 8px;
    margin-bottom: 20px;
    background-color: #FFFF;
    max-width: 100vw;

    border: 1px solid ${props => {
    if((props.openCloseDynamics === true || props.openCloseDynamics === undefined) && props.habilitado === true) {
      return props.theme.fundoSecundario
    } else if((props.openCloseDynamics === true || props.openCloseDynamics === undefined) && props.habilitado === false) {
      return props.theme.cardDoDiaAgendaBordaDataDesabilitado || `#DFDDE3`
    } else {
      // return props.theme.fundoTres
      return '#FFFFFF'
    }
  }};

    .container-dia-agenda{
        max-height: 14vh;
    }
    .final-atividades{
        width: 82%;
        display:inline;
        text-align: center;
    }

    .atividade-subtitle{
        text-align: start;
        margin-left: 3em;
    }

    .subtitle-content{
        margin-bottom: 1em;
    }

    .mobile-none{
        display: none;
    }

    .display-none{
            display: none;
        }

    .subtitulo-agenda{
        text-align:left;
        margin-left: 9%;
    }

    @media screen and (min-width: 768px) {
        min-height: 27rem;
        width: 26vw;
        max-height:27rem;

        .container-dia-agenda{
            max-height: 8vh;
        }
        .final-atividades{
            display: flex;
            width: 100%;
            flex-direction: column;
            padding-top: 0.5em;
            overflow: auto;
            max-height: 20rem;
            padding-bottom: 2.5em;
            ::-webkit-scrollbar {
            width: 5px;

            }

		::-webkit-scrollbar-track {
		box-shadow: inset 0 0 5px transparent ;
		border-radius: 10px;
		}

		::-webkit-scrollbar-thumb {
		background: ${props => { return props.theme.fundoSecundario }};;
		border-radius: 10px;
		}

		::-webkit-scrollbar-thumb:hover {
		background: ${props => { return props.theme.fundoSecundario || `#533888` }};
		}
        }
        .mobile-none{
             display: block;
        }
        .subtitle-content{
            margin-top: 0.9em;
            margin-bottom: 0.5em;
        }



        .display-none{
            display: none;
        }


        .div-overflow {

            margin: 0 2vw;

        }

    }

`

export const DataCardAgendaStyle = styled.div`
    padding-bottom: 1rem;
	width: 15vw;
	height: 15vw;
	border-radius: 8px;
	background: #ffffff;
	margin-right: 3vw;
	text-align: center;
	font-weight: bolder;
	line-height: 0.8rem;
	font-size: 1.1rem;
	text-align: center;
    background: ${props => {
    if(props.habilitado === true) {
      return props.theme.fundoSecundario
    } else {
      return props.theme.cardDoDiaAgendaBordaDataDesabilitado || `#DFDDE3`
    }
  }};
    box-shadow: ${props => { return props.theme.sombraPrimaria}};
    color: #FFFFFF;
    text-align: center;

.card-dia__diaSemana {
    font-family: Poppins;
    font-weight: 700;
    font-size: 22px;
    line-height: 22px;
}

.card-dia__mes{
    font-family: Poppins;
    font-weight: 700;
    font-size: 12px;
    line-height: 12px;
    margin-top: 0.198rem
}

.dia-margin-executada{
    margin-left: 5px;
}
@media screen and (min-width: 1024px) {

    .dia-margin-executada{
        margin-left: 0px;
    }
}

@media screen and (min-width: 768px) {
    .dia-margin-executada{
        margin-left: 0px;
    }
}

@media screen and (min-width: 600px) {
    width: 3.5rem;
    height: 3.5rem;
    margin-right: 3%;
    margin-bottom: 3vh;
}
`

export const AgendaHeaderStyle = styled.div`
    padding: 12px 10px 10px 10px;
    flex-grow: 1;

    .dia-titulo{
        color: ${props => { return props.theme.cardLogadoTextoPrimeiro}};
        font-weight: 600;
        font-size: 13px;
        line-height: 12px;

    }

    .dia-progress-bar-container{
		display: flex;
        justify-content: space-between;
    }

    .dia-progress-bar{
        margin-top: 15px;
        max-width: 70%;

        p{
            font-size: 12px;
            line-height: 12px;
            font-weight: 700;
            color: ${props => { return props.completedColor}};
            text-align: center;
            margin-bottom: 5px;
        }

        .weekName{
            font-weight: 400;
            font-size: 12px;
            line-height: 12px
        }
    }

    .dia-da-semana-e-btn{
        padding-top: 1rem;
        color: ${props => { return props.theme.cardLogadoTextoTerceiro}};
        font-size: 12px;
        line-height: 12px;

        span{
            display: block;
            text-align: right;

        }

        .openClose{
            cursor: pointer;
        }

        .agenda-aguarde{

            font-weight: 700;
        }
    }



@media screen and (min-width: 700px) {
    container-dia-agenda{
        min-height: 100vh;
    }
    
    .dia-progress-bar{
        margin-top: 15px;
        max-width: 85%;
    }
}


`
export const AtividadeDiaStyle = styled.div`
    .styled-atividades-dia{
        width:${props => { return props.tamanho }};
        display: flex;
        align-items: baseline;
        justify-content: flex-start;
        margin: 0 auto 10px auto;
        margin-left: 4%;
        margin-right: 4%;
        flex-wrap: wrap;
    }
    @media screen and (min-width: 700px) {

        .styled-atividades-dia{
            justify-content: flex-start;
        }

        .agenda-game{
            width: 100%;
            padding: 1em 2em;
        }
    }

`


export const DinamicasDoDia = styled.div`
    .atividades-dia__disabled {
        background: #acacac;
    }
`
