export default class AtividadeEntity {
  constructor(atividade) {
    Object.assign(this, {...atividade});
    this.disabledCss = atividade.disabledCss;
    this.disabledIcon = atividade.disabledIcon;
    this.enabled = atividade.enabled;
    this.enabledIcon = atividade.enabledIcon ;
    this.executed = atividade.executed;
    this.imageAlt = atividade.imageAlt;
    this.key = atividade.key ;
    this.name = atividade.name ;
    this.navegar = atividade.navegar || (() => {});
    this.nome = atividade.nome;
    this.order = atividade.order;
    this.presentation = atividade.presentation ;
    this.presentationCover = atividade.presentationCover ;
    this.scoreType = atividade.scoreType ;
    this.size = atividade.size ;
    this.subtitle = atividade.subtitle || {uuid: null, subtitle: null, ordem: null};
    this.title = atividade.title ;
    this.trophyDisabled = atividade.trophyDisabled ;
    this.trophyEnabled = atividade.trophyEnabled ;
    this.type = atividade.type ;
    this.typeApresentation = atividade.typeApresentation ;
    this.uuid = atividade.uuid ;        
  }
}