import React from "react";
import {BotaoAsALinkStyle} from './style'

/**
 * @param {Object} props
 * @param {string} props.classeComplementar
 * @param {string} props.ariaLabelMsg
 * @param {boolean} props.disabled
 * @param {string} props.nome
 * @param {function(e): void} props.funcao onClick event
 */
function BotaoAsALink(props) {
  return (
    <BotaoAsALinkStyle
      className={`${props.classeComplementar} ${props.disabled && 'disabled'}`}
      onClick={props.funcao}
      aria-label={props.ariaLabelMsg}
    >
      {props.nome}
    </BotaoAsALinkStyle>
  )
}

export default BotaoAsALink