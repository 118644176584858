import React, { useRef, useState } from 'react';
import { animations } from 'react-animation';
import { useStore } from 'react-redux';
import WeexInput from '../WeexInput';
import BotaoPrimario from '../botaoPrimario/BotaoPrimario';
import { WeexModalDialog } from './WeexModalDialog';

function WeexModalAnexarVideo({ cancela, confirma, visivel, }) {
  
  const dialogRef = useRef(null);

  const i18n = useStore().getState().i18n;

  const [url, setUrl] = useState("");


  const ocultar = () => {
    dialogRef.current.close();
    cancela()
  }

  


  const regexYoutubeAndVimeo = () => {
    return "^(https?:\\/\\/)?((www\\.)?youtube\\.com\\/watch\\?v=([\\w-]+)|youtu\\.be\\/([\\w-]+)|vimeo\\.com\\/(channels\\/\\w+\\/|groups\\/\\w+\\/videos\\/|album\\/\\d+\\/video\\/|video\\/)?([\\d]+|[^\\/]+))(\\S*)?$";
  }

  const onChangeInputUrl = (e) => {
    setUrl(e.target.value);
  }

  const submit = (e) => {
    e.preventDefault();
    confirma(url);

  }

  const onInvalid = (e) => {
    e.target.setCustomValidity(i18n.message("dinamica.mural.publicar.video.linkInvalido", "Somente links do YouTube ou Vimeo são aceitos na plataforma"));
    e.target.focus();
  }

  return (
    <WeexModalDialog  dialogRef={dialogRef} onPressESC={ocultar}>
      <div className={visivel === true ? 'modal is-active' : 'modal'} style={{ animation: animations.popIn, zIndex: '102' }}>
        <div className="modal-background weex-modal-background"></div>
        <div className="modal-card weex-modal-card">
          <form onSubmit={submit} >
            <header className="modal-card-head weex-modal-card-header">
              <div className="titulo-icone titulo-mural-modal">
                <div className='icon-mural-modal'>
                  <span className="icon color-fundo-secundaria">
                    <i className="fa fa-comment-dots"></i>
                  </span>
                </div>
                <h3 className='titulo-modal'>{i18n.message("dinamica.mural.publicar.titulo", "ESCREVER PUBLICAÇÃO")}</h3>
              </div>
              <button
                onClick={ocultar}
                className="close-btn"
                aria-label={i18n.message("aria.label.mural.anexo.video.fechar.modal", "Fecha a janela de publicar vídeo e volta para escrever publicação.")}
              >
                <img
                  src="/close-btn.png"
                  alt={i18n.message("alt.button.imagem.fechar", "ícone em formato de um X indicando o encerramento de uma ação.")}
                />
              </button>
            </header>
            <section className="modal-card-body has-text-centered">
              <div className="weex-modal-card-body__texto">

                <WeexInput
                  aria-label={i18n.message("dinamica.mural.publicar.video", "Cole aqui o link para o vídeo (YouTube/Vimeo)")}
                  id="url"
                  label={i18n.message("dinamica.mural.publicar.video", "Cole aqui o link para o vídeo (YouTube/Vimeo)")}
                  type="text"
                  name="url"
                  onChange={onChangeInputUrl}
                  required={true}
                  pattern={regexYoutubeAndVimeo()}
                  onInvalid={onInvalid}
                ></WeexInput>


              </div>
            </section>
            <footer className="modal-card-foot weex-modal-card-footer" style={{ textAlign: 'center' }}>
              <BotaoPrimario nome="Anexar vídeo" />
            </footer>
          </form>
        </div>
      </div>
    </WeexModalDialog>
  );
 
}

export default WeexModalAnexarVideo;
