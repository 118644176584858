import React, { useEffect, useState } from "react";
import { ReCaptcha } from "react-recaptcha-v3";
import { useStore } from "react-redux";
import { Link } from "react-router-dom";
import WeexInput from "../../comps/WeexInput";
import WeexMessage from "../../comps/WeexMessage";
import BotaoSecundario from "../../comps/botaoSecundario/BotaoSecundario";
import WeexModal from "../../comps/weexModais/WeexModal";

function RegisterCampaignDesktop(props) {
  const i18n = useStore().getState().i18n;
  const [selectsUnidades, setSelectsUnidades] = useState([]);

  const [unit, setUnit] = useState(null);
  const [subUnit, setSubUnit] = useState(null);
  const [subSubUnit, setSubSubUnit] = useState(null);

  useEffect(() => {
    const inputUnidades = () => {
      if (!props || !props.campanha || !props.campanha.client || !props.campanha.client.units) {
        return [];
      }
      let unidades = props.campanha.client.units;
      if (unidades && unidades.length > 0) {
        return unidades;
      }
      return [];
    };
    let unidades = inputUnidades();

    if (unidades) {
      setSelectsUnidades(unidades);
    }
    // TODO: Lint não deixa ser apenas uma array vazia -> Larissa (Tarefa Bug #15192)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const mudarUnidade = (event, nivel) => {
    event.preventDefault();
    const value = event.target.value;
    props.onChange(event);
    switch (nivel) {
    case 0:
      setUnit(value);
      setSubUnit(null);
      setSubSubUnit(null);
      break;
    case 1:
      setSubUnit(value);
      setSubSubUnit(null);
      break;
    case 2:
      setSubSubUnit(value);
      break;
    default:
      console.error("Nível desconhecido");
      break;
    }
  };

  const gerarSelectUnidades = (unidades) => {
    return (
      <>
        <label className="label">
          <WeexMessage
            code="registro.campo.area"
            default="Selecione sua área"
          />
          <div className="select weex-select select-margin-top-control">
            <select
              value={unit}
              name="unit"
              onChange={(e) => mudarUnidade(e, 0)}
              required
            >
              <option value="">
                {i18n.message("select.unidade", "Selecione uma unidade")}
              </option>
              {unidades.map((unidade) => (
                <option key={unidade.uuid} value={unidade.uuid}>
                  {unidade.name}
                </option>
              ))}
            </select>
          </div>
        </label>
      </>
    );
  };

  const gerarSelectSubUnidades = (unidades, uuidUnidade) => {
    const unidade = unidades.find((unidade) => unidade.uuid === uuidUnidade);
    if (!unidade || !unidade.subUnits || unidade.subUnits.length === 0) {
      return null;
    }
    return (
      <>
        <label className="label">
          <WeexMessage
            code="registro.campo.subarea"
            default="Selecione sua subárea"
          />
          <div className="select weex-select select-margin-top-control">
            <select
              value={subUnit}
              name="subUnit"
              onChange={(e) => mudarUnidade(e, 1)}
              required
            >
              <option value="">
                {i18n.message("select.subunidade", "Selecione uma subunidade")}
              </option>
              {unidade.subUnits.map((subUnidade) => (
                <option key={subUnidade.uuid} value={subUnidade.uuid}>
                  {subUnidade.name}
                </option>
              ))}
            </select>
          </div>
        </label>
      </>
    );
  };

  const gerarSelectSubSubUnidades = (unidades, uuidUnidade, uuidSubUnidade) => {
    const unidade = unidades.find((unidade) => unidade.uuid === uuidUnidade);
    if (!unidade || !unidade.subUnits) {
      return null;
    }
    const subUnidade = unidade.subUnits.find(
      (subUnidade) => subUnidade.uuid === uuidSubUnidade
    );
    if (!subUnidade || !subUnidade.subUnits || subUnidade.subUnits.length === 0) {
      return null;
    }
    return (
      <div className="margin-top-3">
        <label className="label">
          <WeexMessage
            code="registro.campo.nivel3"
            default="Selecione sua sub-subárea"
          />
          <div className="select weex-select select-margin-top-control">
            <select
              value={subSubUnit}
              name="level3"
              onChange={(e) => mudarUnidade(e, 2)}
              required
            >
              <option value="">
                {i18n.message(
                  "select.subsubunidade",
                  "Selecione uma sub-subunidade"
                )}
              </option>
              {subUnidade.subUnits.map((subSubUnidade) => (
                <option key={subSubUnidade.uuid} value={subSubUnidade.uuid}>
                  {subSubUnidade.name}
                </option>
              ))}
            </select>
          </div>
        </label>
      </div>
    );
  };

  let link = ""
  if(props.code === 'rededor') {
    link = "https://weex.digital/termos-rede-dor/"
  } else if(props.code === 'lorenzetti' || props.code === 'lorenzettiteste') {
    link = "https://weex.digital/wp-content/uploads/2023/06/02-2023_06_01_Regulamento-Sem-Meio-Amb-Loren-2023_SP-rev-02-SP-e-MG.pdf"
  } else {
    if(props.privacyPolicy) {
      link = props.privacyPolicy
    } else {
      link = "https://weex.digital/politica-de-privacidade-e-uso/"
    }
  }
  
  return (
    <div>
      <form onSubmit={props.registro}>
        <div className="cadastro-desktop">
          <div className="inputs_forms">
            {props.erro != null ? (
              <WeexModal
                fecharModalCallback={props.callbackWarning}
                titulo={props.erro.titulo}
                conteudo={props.erro.mensagem}
              />
            ) : null}
            <div className="field-group">
              <div className="field-cadastro">
                <WeexInput
                  id="firstName"
                  type="text"
                  name="firstName"
                  label={i18n.message(
                    "registro.campo.dadosPessoais",
                    "Dados Pessoais"
                  )}
                  placeholder={i18n.message("registro.campo.nome", "Nome")}
                  onChange={props.onChange}
                  required={true}
                  ariaLabel={i18n.message(
                    "aria.label.campo.first.name",
                    "Insira seu nome"
                  )}
                />
              </div>
              <div className="field-cadastro sem-label">
                <WeexInput
                  id="lastName"
                  type="text"
                  name="lastName"
                  placeholder={i18n.message(
                    "registro.campo.sobrenome",
                    "Sobrenome"
                  )}
                  onChange={props.onChange}
                  required={true}
                  ariaLabel={i18n.message(
                    "aria.label.campo.last.name",
                    "Insira seu sobrenome"
                  )}
                />
              </div>
              {props.expoeDataNascimento ? (
                <div className="field-cadastro sem-label">
                  <WeexInput
                    type="text"
                    name="birthday"
                    placeholder={i18n.message(
                      "registro.campo.nascimento",
                      "Data de nascimento - DD/MM/AAAA"
                    )}
                    onChange={props.onChange}
                    required={props.expoeDataNascimento}
                    mascara="99/99/9999"
                    legenda=""
                    ariaLabel={i18n.message(
                      "aria.label.campo.bithday",
                      "Insira sua data de nascimento"
                    )}
                  />
                </div>
              ) : null}
              {props.expoeIdade ? (
                <div className="field-cadastro sem-label">
                  <WeexInput
                    type="listaNumero"
                    name="age"
                    placeholder={i18n.message("registro.campo.idade", "Idade")}
                    value={props.request.age}
                    min={14}
                    max={100}
                    onChange={props.onChange}
                    required={props.expoeIdade}
                    ariaLabel={i18n.message(
                      "aria.label.campo.idade",
                      "Insira sua idade"
                    )}
                  />{" "}
                </div>
              ) : null}
              {props.campanha && props.registrationUnitMandatory ?
                <>
                  <div class="field-cadastro">
                    <div className="field">
                      {gerarSelectUnidades(selectsUnidades)}
                    </div>
                  </div>
             
                  <div class="field-cadastro">
                    <div className="field">
                      {subUnit &&
                    gerarSelectSubSubUnidades(selectsUnidades, unit, subUnit)}
                    </div>
                  </div>
                </>
                :""}
             
            </div>
            <div className="field-group">
              {props.expoeCampoMatricula ? (
                <div className="field-cadastro sem-label">
                  {" "}
                  <WeexInput
                    id="enrollment"
                    type="text"
                    name="enrollment"
                    placeholder={props.nomeMatricula}
                    onChange={props.onChange}
                    required={true}
                    ariaLabel={i18n.message(
                      "aria.label.campo.enrollment",
                      "Insira sua matrícula"
                    )}
                  />
                </div>
              ) : null}
              {props.expoeCampoEmail && !props.expoeCampoMatricula ? (
                <div className="field-cadastro sem-label">
                  <WeexInput
                    id="email"
                    type="email"
                    name="email"
                    placeholder={i18n.message("registro.campo.email", "E-mail")}
                    onChange={props.onChange}
                    required={true}
                    ariaLabel={i18n.message(
                      "aria.label.campo.email",
                      "Insira seu email"
                    )}
                  />
                </div>
              ) : null}
              {props.expoeCampoEmail && props.expoeCampoMatricula ? (
                <div className="field-cadastro sem-label">
                  <WeexInput
                    id="email"
                    type="email"
                    name="email"
                    placeholder={i18n.message("registro.campo.email", "E-mail")}
                    onChange={props.onChange}
                    required={false}
                    ariaLabel={i18n.message(
                      "aria.label.campo.email",
                      "Insira seu email"
                    )}
                  />
                </div>
              ) : null}
              <div className="field-cadastro sem-label">
                <WeexInput
                  id="password"
                  type="password"
                  name="password1"
                  placeholder={i18n.message("registro.campo.senha", "Senha")}
                  onChange={props.onChange}
                  required={true}
                  ariaLabel={i18n.message(
                    "aria.label.campo.password",
                    "Insira uma senha"
                  )}
                />
              </div>
              <div className="field-cadastro sem-label">
                <WeexInput
                  id="password2"
                  type="password"
                  name="password2"
                  placeholder={i18n.message(
                    "registro.campo.senha2",
                    "Confirme sua senha"
                  )}
                  onChange={props.onChange}
                  required={true}
                  ariaLabel={i18n.message(
                    "aria.label.campo.password.confirm",
                    "Confirme sua senha"
                  )}
                />
              </div>
              {props.campanha && props.registrationUnitMandatory ?
                <div class="field-cadastro">
                  <div className="field">
                    {unit && gerarSelectSubUnidades(selectsUnidades, unit)}
                  </div>
                </div>:""  
              }
              
            </div>
          </div>

          <div className="field-group-checkbox">
            <label
              htmlFor="privacyPolicy"
              className="checkbox template-text-color"
              style={{
                textAlign: "center",
                marginTop: "2.0em",
                marginBottom: "2.0em",
              }}
              aria-label={i18n.message(
                "aria.label.campo.registro.legal.termos",
                " Aceitar termos e condições "
              )}
            >
              <input
                id="privacyPolicy"
                onChange={props.onChangeAccept}
                type="checkBox"
                name="aceito"
              />
              &nbsp;&nbsp;
              {i18n.message("registro.legal.liaceito", "Li e aceito os")}
              <a
                className="link-sublinhado"
                href={link}
                target="_blank"
                rel="noopener noreferrer"
              >
                {i18n.message("registro.legal.termos", " Termos e Condições ")}
              </a>
              {i18n.message("registro.legal.daweex", "da Weex")}
            </label>
          </div>
          <ReCaptcha
            sitekey={props.recaptchaToken}
            action="register"
            verifyCallback={props.verifyCallback}
          />
          <div className="botao-cadastro-content">
            <p
              className="botao-cadastro"
              style={{ textAlign: "center", marginTop: "1.0em" }}
            >
              <BotaoSecundario
                disabled={props.accept || props.captcha ? false : true}
                nome={
                  props.processando
                    ? i18n.message("geral.aguarde", "AGUARDE...")
                    : i18n.message("registro.criar", "CADASTRAR")
                }
              />
              {props.modoLogin ? (
                <button
                  type="button"
                  className="button-link espacamento-inicio"
                  onClick={props.modoLogin}
                >
                  {i18n.message(
                    "registro.posseuiCadastro",
                    "Já possuo cadastro"
                  )}
                </button>
              ) : (
                <Link style={{ marginTop: "1.5rem" }} to="/">
                  {i18n.message(
                    "registro.posseuiCadastro",
                    "Já possuo cadastro"
                  )}
                </Link>
              )}
            </p>
          </div>
        </div>
      </form>
    </div>
  );
}

export default RegisterCampaignDesktop;
