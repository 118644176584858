import styled from "styled-components"

export const FatoFakeStyle = styled.div`
 .desktop-fatoFake-voltar{
            display: none;
        }
 .botao-avanca-concluir{
        display: block;
  }
    @media screen and (min-width: 700px) {
        width: 100%;
        
        .width-card{
            width: 70%;
        }

        .desktop-fatoFake-voltar{
            display: block;
        }
        .dinamica-container{
            display: flex;

        }
        .video-botao-entendi{
            display: none;
        }
        .volta-mobile{
            display: none;
        }

        .fatoFake-desktop-content-left{
            margin-right: 10vw;
            margin-top: 2vw;
            min-width: 25%;
        }
        .image img {
            display: block;
            height: auto;
            width: 70%;
        }
        .marcador-progresso{
            padding-top: 1em;
            justify-content: center;
        }
        
        .marcador{
            margin: 0.3em;
        }

        .botao-avanca-concluir{
            display: none;
        }
        .card{
            margin: 1em;
        }
        .botao-fatofake-desktop{
            margin: 0 10vw;
        }
        
    }
`