import styled from "styled-components";

export const BotaoAsALinkStyle = styled.button`
  width: 100%;
	background: transparent;
	border: none;
	font-size: 1rem;
	letter-spacing: 0.1rem;
	font-weight: 700;
	text-transform: uppercase;
  color: #777777;
  cursor: pointer;

  :active{
    filter: brightness(0.5)
  }

  .disabled{
    cursor: not-allowed;
    filter: opacity(0.5);
  }

  .icon-custom {
    margin-right: 1em;
  }
  
  .icon-custom-right{
    margin-left: 1em;
  }

  .botaoDesktop{
    display: flex;
    align-items: center;
  }

`
