import {BotaoIconeStyle} from "./botaoIconeStyle"
import React from "react";
function BotaoIcone(props) {
  return (
    <BotaoIconeStyle
      className={props.disabled===true? `${props.classeComplementar} 'disabled'`:`is-large ${props.classeComplementar}`}
      onClick={props.funcao}
      aria-label={props.label}
    >
      <span class="icon">
        <i class={props.icon}></i>
      </span>
    </BotaoIconeStyle>
  )
}

export default BotaoIcone
