import styled from "styled-components";

export const FeedbackStyle = styled.div`
.questao_feedback {
    text-align: center;
    width: 100%;
    margin-bottom: 1.5em;
}

.questao_feedback_enunciado {
    font-size: 15px;
    line-height: 14px;
    font-weight: 400;
    color: ${props => { return props.theme.cardLogadoTextoPrimeiro}};
    margin-bottom: 1.5rem;
}

.estrelas {
    display: flex;
    justify-content: center
}

.card-header-feedback {
    width: 100%;
}

.conteudo-card-atividades {
	min-height: 70vh;
	overflow: hidden;
	padding-bottom: 3vh;
}

.card-header-feedback p {
    font-size: 0.5em;
    font-weight: bold;
}

.btn-invisible{
    background-color: transparent;
    border: none;
    margin: 0px;
    padding: 0px;
    cursor: pointer;
}

textarea, .textarea {
    resize: none;
}

@media screen and (min-width: 700px) {
    .feedback-conteiner-width-desktop{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
}

`