import React, { useRef, useState } from 'react';
import { animations } from 'react-animation';
import { useStore } from 'react-redux';
import BotaoPrimario from '../botaoPrimario/BotaoPrimario';
import BotaoSecundario from '../botaoSecundario/BotaoSecundario';
import Spinner from '../publicacao/Spinner';
import { WeexModalDialog } from './WeexModalDialog';

/**
 * @param {Object} props
 * @param {string} props.conteudo
 * @param {boolean} props.isComentario
 * @param {function(): void} props.fecharModalCallback
 * @param {function(): void} props.deletar
 */
function ModalExclusao({
  fecharModalCallback,
  conteudo,
  deletar,
  isComentario
}) {
    
  const i18n = useStore().getState().i18n;
  const [mostrarSpinner, setMostrarSpinner] = useState(false);
  const [botaoIsDesabled, setBotaoIsDesabled] = useState(false);
  const [mostrarModal, setMostrarModal] = useState(true)
  const modalDialogRef = useRef(null)

  const ocultar = () => {
    fecharModalCallback()
    modalDialogRef.current.close()
    setMostrarModal(false)
  }

  const confirmar = () => {
        
    /* TODO:
      Arrumar um modo de não prever quando o processo desse
      escopo irá terminar, ou seja, remover o setTimeout e
      substituir por uma promisse do processo talvez.
    */

    setMostrarSpinner(true)
    deletar()
    setTimeout(() => setMostrarSpinner(false), 500)
    setBotaoIsDesabled(true);
    setMostrarModal(false)
  }

  const styleComentario = { animation: animations.popIn, zIndex: '102', marginLeft:0 };
  const stylePublicacao = { animation: animations.popIn, zIndex: '102'};
  const styleModal = isComentario ? styleComentario : stylePublicacao;
  return (
    mostrarModal ? (
      <WeexModalDialog
        dialogRef={modalDialogRef}
        onPressESC={ocultar}
      >
        <div className={"modal is-active"} style={styleModal} >
          <div className="modal-background weex-modal-background"></div>
          <div className="modal-card weex-modal-card">
            <header className="modal-card-head weex-modal-card-header">
              <button
                onClick={ocultar}
                className="close-btn"
                aria-label={i18n.message("aria.label.mural.fechar.modal", "Fecha a janela e volta para a página mural social.")}
              >
                <img
                  src="/close-btn.png"
                  alt={i18n.message("alt.button.imagem.fechar", "ícone em formato de um X indicando o encerramento de uma ação.")}
                />
              </button>
            </header>
            <section className="modal-card-body has-text-centered">
              <div className="weex-modal-card-body__texto">
                <p>
                  {conteudo}
                  <Spinner isVisivel={mostrarSpinner} />
                </p>
              </div>
            </section>
            <footer className="modal-card-foot weex-modal-card-footer" style={{ textAlign: 'center' }}>
              <BotaoPrimario 
                classeComplementar={botaoIsDesabled ? "weex-mural-button":""}  
                nome="Não" 
                funcao={ocultar} 
                ariaStatusMessage={i18n.message("aria.status.modalexclusao.botaonao", "Botão não clicado.")}
              />
              <BotaoSecundario classeComplementar={botaoIsDesabled ? "weex-mural-button":""} nome="Sim" funcao={confirmar} />
            </footer>
          </div>
        </div>
      </WeexModalDialog>
    ) : <></>
  );
}

export default ModalExclusao;
