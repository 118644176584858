import styled from "styled-components";

export const ButtonPrimarioStyle = styled.button`
  background-color: ${props => { return props.theme.fundoSecundario || `#533888`}};
  text-transform: uppercase;
  width: 100%;
  padding: 1.6em;
  padding-left: 2em;
  padding-right: 2em;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 3px;
  font-weight: 700;
  color: ${props => { return props.theme.textosCorFundoSecundario || `rgb(239, 231, 102)`}};
  box-shadow: ${props => { return props.theme.sombraPrimaria}};
  cursor: pointer;

  .content-botao{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.7em;
    flex-direction: row;
  }

  path {
    fill: ${props => { return props.theme.textosCorFundoSecundario || `rgb(239, 231, 102)`}};;
  } 

  :active{
    filter: brightness(0.5)
  }

  .disabled{
    cursor: not-allowed;
    filter: opacity(0.5);
  }

  @media screen and (max-width: 600px) {
      padding-left: 0em;
      padding-right: 0em;
  }
`