import Vimeo from '@u-wave/react-vimeo';
import React, { useEffect, useState } from 'react';
import { animations } from 'react-animation';
import { useSelector, useStore } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import BotaoAsALinkIconeEsquerda from '../../comps/botaoAsALink/BotaoAsAlinkWithIconLeft';
import DinamicasHeader from '../../comps/dinamicasHeader/DinamicasHeader';
import WeexModal from '../../comps/weexModais/WeexModal';
import AgendaService from '../../services/AgendaService';
import AtividadesService from '../../services/AtividadesService';
import CampanhaService from '../../services/CampanhaService';
import MensagemVideoService from '../../services/MensagemVideoService';
import TemaService from '../../services/TemaService';
import UsuarioService from '../../services/UsuarioService';
import { VideoStyled } from './videoStyled';
import FormVideoFeedback from '../../comps/QuizVideoFeedback/FormVideoFeedback';

function Videos() {

  const i18n = useStore().getState().i18n;

  let location = useLocation();

  const history = useHistory()
  let { codigo, codMensagemDeVideo, codigoAtividade } = useParams()
  const atividadesService = new AtividadesService()
  const mensagemVideoService = new MensagemVideoService()
  const agendaService = new AgendaService()
  const [video, setVideo] = useState()
  const [mensagemDeVideoUuid, setMensagemDeVideoUuid] = useState()
  const [finalizado, setFinalizado] = useState(false)
  const [bloquearBotao, setBloquearBotao] = useState(true)
  const [processando, setProcessando] = useState(false)
  const [erro, setErro] = useState(null)
  const [vimeoCarregado, setVimeoCarregado] = useState(false)
  const [curtiuVideo, setCurtiuVideo] = useState(null);
  const [comentarioVideo, setComentarioVideo] = useState("");
  const [sizeScreen, setSizeScreen] = useState(window.innerWidth);
  const campanhaService = new CampanhaService();
  const isDesktopMode = useSelector((state) => state.isDesktopMode);

  useEffect(() => {
    if (!processando) {
      setProcessando(true)
      if (codigo) {
        atividadesService.iniciarAtividade(codigo, codigoAtividade, (error, atividade) => {
          if (atividade) {
            if (atividade.video.url.indexOf("youtube") !== -1 || atividade.video.url.indexOf("youtu.be") !== -1) {
              let regex = /^.*(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:(?:watch)?\?v(?:i)?=|&v(?:i)?=))([^#&?]*).*/
              let resultadoRegex = atividade.video.url.match(regex)
              atividade.video.urlParseada = "https://www.youtube.com/embed/" + resultadoRegex[1] + "?mute=0&controls=2&origin=" + window.location.origin +
                                "&playsinline=1&showinfo=0&rel=0&iv_load_policy=3&modestbranding=1&enablejsapi=0&fs=1&autohide=1&widget_referrer=" + window.location.origin
            }
            setCurtiuVideo(atividade.feedbackVideo)
            setVideo(atividade.video)
            setFinalizado(atividade.dateFinished !== null)
            setBloquearBotao(atividade.dateFinished == null)
            if (atividade.dateFinished !== null) {
              agendaService.atualizarAgenda(codigo, codigoAtividade, "VIDEO", null, null, (errorAtualizarAgenda, sucesso) => {
                if (error) {
                  setErro({
                    titulo: i18n.message("dinamica.erro.jaexecutada.titulo", "Erro ao exibir atividade já executada"),
                    mensagem: i18n.message("dinamica.erro.jaexecutada.mensagem", "Falha ao atualizar informações de atividade executadas, entre em contato com o suporte"),
                    voltarAgenda: true
                  })
                }
              })
            }
          }
          if (error) {
            if (error.response && error.response.data && error.response.data.message) {
              setErro({
                titulo: i18n.message("dinamica.erro.iniciar.titulo", "Erro ao iniciar atividade"),
                mensagem: error.response.data.message,
                voltarAgenda: true
              })
            } else {
              setErro({
                titulo: i18n.message("dinamica.erro.iniciar.titulo", "Erro ao iniciar atividade"),
                mensagem: i18n.message("dinamica.erro.iniciar.mensagem", "Entre em contato com o suporte ou tente novamente mais tarde."),
                voltarAgenda: true
              })
            }
          }
        })
      }
      if (codMensagemDeVideo) {
        mensagemVideoService.obterMensagemDeVideo((erroMensagemVideo, atividade) => {
          if (erroMensagemVideo) {
            if (erroMensagemVideo.response && erroMensagemVideo.response.data && erroMensagemVideo.response.data.message) {
              setErro({
                titulo: i18n.message("mensagemvideo.erro.iniciar.titulo", "Erro ao iniciar mensagem de vídeo"),
                mensagem: erroMensagemVideo.response.data.message,
                voltarAgenda: true
              })
            } else {
              setErro({
                titulo: i18n.message("mensagemvideo.erro.iniciar.titulo", "Erro ao iniciar mensagem de vídeo"),
                mensagem: i18n.message("mensagemvideo.erro.iniciar.mensagem", "Entre em contato com o suporte ou tente novamente mais tarde."),
                voltarAgenda: true
              })
            }
          }
          if (atividade) {
            if (atividade[0].video.url.indexOf("youtube") !== -1 || atividade[0].video.url.indexOf("youtu.be") !== -1) {
              let regex = /^(?:(?:youtu\.be\/|v\/|vi\/|u\/\w\/|embed\/)|(?:watch)?\?v(?:i)?=|&v(?:i)?=)([^#&?]*).*/
              let resultadoRegex = atividade[0].video.url.match(regex)
              atividade[0].video.urlParseada = "https://www.youtube.com/embed/" + resultadoRegex[1] + "?mute=0&controls=2&origin=" + window.location.origin +
                                "&playsinline=1&showinfo=0&rel=0&iv_load_policy=3&modestbranding=1&enablejsapi=0&fs=1&autohide=1&widget_referrer=" + window.location.origin
            }
            setMensagemDeVideoUuid(atividade[0].uuid)
            setVideo(atividade[0].video)
          }
        });
      }
    }
    let service = new TemaService(campanhaService.campanhaCorrente());
    service.aplicarTema("HOME");
    // TODO: Ao colocar as dependencias solicitadas pelo Lint, a aplicação fica em loop infinito no useEffect --> realacionada tarefa 14797 --> By Renan
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  // TODO: configuração temporária para a campanha da Accenture. Após a campanha este método deve ser removido.
  function validarCampanha() {
    let campanha = campanhaService.campanhaCorrente()
    if(campanha){
      if(campanha.code.toLowerCase().includes('accenture')){
        return true
      }
    }
    return false
  }

  const sizeOfThings = () => {
    return window.screen.width
  }
  window.addEventListener('resize', function () {
    setSizeScreen(sizeOfThings())
  })

  const callbackModal = () => {
    setErro(null)
    if (erro.voltarAgenda) {
      history.push("/atividades")
    }
  }

  const videoCarregado = () => {
    setVimeoCarregado(true)
  }

  const redirecionar = () => {
    finalizar();
    history.push('/missionFinished/' + codigo + '/VIDEO/' + codigoAtividade)
  }

  const finalizar = () => {
    setFinalizado(true)
    let feedbackVideo
    let usuarioCorrente = new UsuarioService().usuarioCorrente();
    if (usuarioCorrente) {
      let uuid = new UsuarioService().usuarioCorrente().participantCode
      feedbackVideo = {
        participanteUuid: uuid,
        videoUuid: video && video.uuid,
        videoRelevante: curtiuVideo,
        comentario: comentarioVideo
      }
    }
    let corpo = {
      feedbackVideo: feedbackVideo
    }
    atividadesService.finalizarAtividade(codigo, codigoAtividade, "VIDEO", corpo, (error, atividade) => {
      if (atividade) {
        return
      }
      if (error) {
        if (error.response && error.response.data && error.response.data.message) {
          setErro({
            titulo: i18n.message("dinamica.erro.finalizar.titulo", "Erro ao finalizar atividade"),
            mensagem: error.response.data.message,
            voltarAgenda: false
          })
        } else {
          setErro({
            titulo: i18n.message("dinamica.erro.finalizar.titulo", "Erro ao finalizar atividade"),
            mensagem: i18n.message("dinamica.erro.finalizar.mensagem", "Entre em contato com o suporte ou tente novamente mais tarde."),
            voltarAgenda: false
          })
        }
      }
    })

  }

  const timeUpdate = (event) => {
    if (event) {
      if (!finalizado) {
        if (event.percent > 0.8) {
          setBloquearBotao(false)
        }
      }
    }
  }

  const marcarComoVistoMensagem = () => {
    mensagemVideoService.marcarComoVisto(mensagemDeVideoUuid, (erroMensagemVideo, sucesso) => {
      if (erroMensagemVideo) {
        if (erroMensagemVideo.response && erroMensagemVideo.response.data && erroMensagemVideo.response.data.message) {
          setErro({
            titulo: i18n.message("mensagemvideo.erro.marcar.titulo", "Erro ao marcar mensagem de vídeo como visualizado"),
            mensagem: erroMensagemVideo.response.data.message,
            voltarAgenda: false
          })
        } else {
          setErro({
            titulo: i18n.message("mensagemvideo.erro.marcar.titulo", "Erro ao marcar mensagem de vídeo como visualizado"),
            mensagem: i18n.message("mensagemvideo.erro.marcar.mensagem", "Entre em contato com o suporte ou tente novamente mais tarde."),
            voltarAgenda: false
          })
        }
      }
      if (sucesso) {
        history.push("/home")
      }
    });
  }

  const proporcao = sizeScreen > 700 ? 0.5 : 0.8

  const getVideo = () => {
    if (video.url.indexOf("vimeo") !== -1) {
      return (
        <div aria-label={video.title} >
          <Vimeo
            video={video.url}
            controls={true}
            onReady={videoCarregado}
            width={sizeScreen * proporcao}
            height={((sizeScreen * proporcao) / 16) * 9}
            style={{ animation: animations.popIn }}
            onTimeUpdate={timeUpdate}
            title={video.title}
          />
        </div>
      )
    } else if (video.url.indexOf("video.weex.digital") !== -1) {
      return (
        <video controls>
          <track kind="captions" />
          <source src={video.url} type="video/mp4"></source>
        </video>)
    } else {
      return (<div className='video-with-desktop'>
        <iframe
          className='video-container'
          title={video.title}
          frameborder='0'
          allow='accelerometer; clipboard-write; encrypted-media; gyroscope;'
          width="100%"
          height="100%"
          allowfullscreen="allowfullscreen"
          mozallowfullscreen="mozallowfullscreen"
          msallowfullscreen="msallowfullscreen"
          oallowfullscreen="oallowfullscreen"
          webkitallowfullscreen="webkitallowfullscreen"
          src={video.urlParseada}
          onLoad={videoCarregado}></iframe>
      </div>)
    }
  }

  const isVideoCarregado = () => {
    return (vimeoCarregado || (video && video.url.indexOf("video.weex.digital") !== -1))
  }

  const onChangeCurtiuVideo = (valor) => {
    setCurtiuVideo(valor)
  }

  const onChangeComentarioVideo = (valor) => {
    setComentarioVideo(valor.target.value)
  }

  const botaoBloqueado = () => {
    return bloquearBotao && (video && video.url && video.url.indexOf("vimeo") !== -1);
  }

  const videoClass = () => {
    if (isDesktopMode) {
      // TODO: este if é uma configuração temporária para a campanha da Accenture.
      if(validarCampanha()){
        return "" // para Accenture
      } else {
        return "column is-offset-2"
      }
    } else {
      return "mobile-video"
    }
  }

  let msgVideoInformacoesGerais = i18n.message("aria.label.video.informacoes.gerais", "Para desbloquear o botão de concluir atividade é necessário assistir o vídeo até o final.")
  
  return (
    <VideoStyled sizeScreen={sizeScreen}>
      <div className='dinamica-content-desktop' >
        {isDesktopMode && (
          <div className='desktop-video-voltar'>
            <BotaoAsALinkIconeEsquerda
              icon="fas fa-arrow-left "
              classeComplementar="header-como-funciona-desktop-botao"
              nome={i18n.message("geral.voltar", "Voltar")}
              ariaLabelMsg={i18n.message("aria.label.geral.mensagem.voltar.atividades", "Volta para a página de atividades.")}
              funcao={() => {
                history.push("/home")
              }}
            />
          </div>
        )}
        {erro != null && (
          <WeexModal 
            fecharModalCallback={callbackModal}
            titulo={erro.titulo} 
            conteudo={erro.mensagem} 
          />
        )}
        <div
          // TODO: configuração temporária para a campanha da Accenture. validarCampanha()
          // className="dinamica-container columns is-desktop is-centered"
          className={`${isDesktopMode && !validarCampanha() ? 'dinamica-container columns is-desktop is-centered' : ''}`}
        >
          <div
            id="informacoes-gerais"
            // TODO: configuração temporária para a campanha da Accenture, incluindo o style. validarCampanha()
            // className="video-desktop-content-left column is-one-third"
            className={`${!validarCampanha() ? 'video-desktop-content-left column is-one-third' : ''}`}
            style={!isDesktopMode ? {paddingBottom: '0.75rem'} : {}}
          >
            <DinamicasHeader
              dinamica={video}
              theme={video && video.theme}
              dinamicaNome={i18n.message("dinamica.video.nome", "Vídeo")}
              dinamicaAlt={i18n.message("alt.dinamica.video", "Ícone de um player de vídeo.")}
              dinamicaImg={location && location.state && location.state.icon ? location.state.icon : "/new_video.svg"}
              type="video"
            />
            <span className='visually-hidden'>{msgVideoInformacoesGerais}</span>
            {/* TODO: !validarCampanha() é uma configuração temporária para a campanha da Accenture */}
            {isDesktopMode && !validarCampanha() && (
              <FormVideoFeedback
                isVideoCarregado={isVideoCarregado}
                codigo={codigo}
                curtiuVideo={curtiuVideo}
                comentarioVideo={comentarioVideo}
                onChangeCurtiuVideo={onChangeCurtiuVideo}
                onChangeComentarioVideo={onChangeComentarioVideo}
                botaoBloqueado={botaoBloqueado}
                redirecionar={redirecionar}
                marcarComoVistoMensagem={marcarComoVistoMensagem}
                classNameDesktopFeedbackDiv="feedback-video"
                classNameDesktopBotaoDiv="video-botao-entendi-desktop"
              />
            )}
          </div>
          {video && video.url && (
            <div id="player-de-video" className='column is-desktop_video'>
              <div className="columns is-centered">
                <div className={videoClass()}>
                  {getVideo()}
                </div>
              </div>
            </div>
          )}
        </div>
        {!isDesktopMode && (
          <FormVideoFeedback
            isVideoCarregado={isVideoCarregado}
            codigo={codigo}
            curtiuVideo={curtiuVideo}
            comentarioVideo={comentarioVideo}
            onChangeCurtiuVideo={onChangeCurtiuVideo}
            onChangeComentarioVideo={onChangeComentarioVideo}
            botaoBloqueado={botaoBloqueado}
            redirecionar={redirecionar}
            marcarComoVistoMensagem={marcarComoVistoMensagem}
          />
        )}

        {/* TODO: todo esse segmento é uma configuração temporária para a campanha da Accenture */}
        {isDesktopMode && validarCampanha() && (
          <FormVideoFeedback
            isVideoCarregado={isVideoCarregado}
            codigo={codigo}
            curtiuVideo={curtiuVideo}
            comentarioVideo={comentarioVideo}
            onChangeCurtiuVideo={onChangeCurtiuVideo}
            onChangeComentarioVideo={onChangeComentarioVideo}
            botaoBloqueado={botaoBloqueado}
            redirecionar={redirecionar}
            marcarComoVistoMensagem={marcarComoVistoMensagem}
            classNameDesktopFeedbackDiv="feedback-video"
            classNameDesktopBotaoDiv="video-botao-entendi-desktop"
            // TODO: método é uma configuração temporária para a campanha da Accenture
            validarCampanha={validarCampanha}
          />
        )}

      </div>
    </VideoStyled>
  )
}

export default Videos;
