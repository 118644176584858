import React, {useState} from "react";
import { useStore } from "react-redux";

/**
 * @param {Object} props
 * @param {boolean} props.valor participaria novamente da campanha ou não
 * @param {function(boolean): void} props.callback onChange onde será setada o valor da participação
 */
function SimNao({valor, callback}) {

  const i18n = useStore().getState().i18n
  const [mensagemDeSelecao, setMensagemDeSelecao] = useState("")
  const clique = (valor) => {
    callback(valor);
    const mensagemPositivo = i18n.message("aria.hidden.feedback.positivo.selecionado", "Ícone de joinha positivo selecionado!");
    const mensagemNegativo = i18n.message("aria.hidden.feedback.negativo.selecionado", "Ícone de joinha negativo selecionado!") 
    setMensagemDeSelecao(`${valor === true ? mensagemPositivo : mensagemNegativo}`)
  };
  return(
    <div>
      <button type="button" className="btn-invisible" onClick={() => {clique(true);}}>
        <img
          alt={`${i18n.message("alt.feedback.positivo", "Ícone de joinha positivo")}`}
          src={valor === true ? "/new_positive_full.svg" : "/new_positive_empty.svg"}
          style={{ marginRight: "1.0em" }}
        />
      </button>
      <button type="button" className="btn-invisible" onClick={() => {clique(false);}}>
        <img
          alt={`${i18n.message("alt.feedback.negativo", "Ícone de joinha negativo")}`}
          src={valor === false ? "/new_negative_full.svg" : "/new_negative_empty.svg"}
        />
      </button>
      <span
        className="visually-hidden"
        role="status"
      >
        {mensagemDeSelecao}
      </span>
    </div>
  );
}

export default SimNao;