import React from 'react';
import { useStore } from "react-redux";
import { Link } from "react-router-dom";
import InfosTextuais from '../../LogosCampanha/InfosTextuais';
import { Section, Div, Paragraph } from './menuStyle';

/**
 * @param {Object} props
 * @param {string} props.title
 * @param {boolean} props.titleVisible
 * @param {string} props.campanhaName
 * @param {string} props.tipoInscricao
 * @param {boolean} props.requiredInterests
 * @param {string} props.locationPathname
 * @param {string} props.versao
 * @param {string} props.inicio
 * @param {function(): void} props.toggle
 * @param {function(): void} props.sair
 * @param {any} props.matricula //TODO: Encontrar a tipagem de "matricula"
 * @param {any} props.legalTerm //TODO: Encontrar a tipagem de "legalTerm"
 * @param {any} props.notLegalTerm //TODO: Encontrar a tipagem de "notLegalTerm"
 * @param {any} props.beginDate //TODO: Encontrar a tipagem de "beginDate"
 * @param {any} props.now //TODO: Encontrar a tipagem de "now"
 * @param {any} props.comoFunciona //TODO: Encontrar a tipagem de "comoFunciona"
 */
function Menu({
  title,
  titleVisible,
  campanhaName,
  matricula,
  tipoInscricao,
  legalTerm,
  notLegalTerm,
  requiredInterests,
  locationPathname,
  beginDate,
  now,
  toggle,
  comoFunciona,
  sair,
  versao,
  inicio
}) {
  const i18n = useStore().getState().i18n;

  return (
    <>
      <Div>
        <InfosTextuais
          campanhaName={campanhaName}
          title={title}
          titleVisible={titleVisible} 
        />	
      </Div>
      <Paragraph style={{pointerEvents:'none',fontSize:'0.75em'}}>
        {`${i18n.message("geral.menu.usuario", "Usuário")} : ${matricula}`}
      </Paragraph>
      { 
        ((
          tipoInscricao === "ELEGIBILIDADE" && 
          legalTerm && 
          !notLegalTerm
        ) || (
          requiredInterests === true && 
          locationPathname === '/interests' && 
          beginDate <= now
        ))
          ? "" 
          : (
            <Section>
              <Link to={inicio} onClick={toggle}>{i18n.message("geral.menu.inicio", "Início")}</Link>  
            </Section>
          )
      }     
      {
        comoFunciona && comoFunciona.helpMessage ?
          (
            <Section>
              <Link to="/help" onClick={toggle}>{i18n.message("geral.menu.comoFunciona", "Como funciona")}</Link>
            </Section>
          ) : ""
      }
      <Section>
        <button className='out button-link' type='button' onClick={sair}>{i18n.message("geral.menu.sair", "Sair")}</button>   
      </Section>
      <Paragraph className='versao'>
        {`v. ${versao}`}
      </Paragraph>
               
    </>
  )
}

export default Menu;