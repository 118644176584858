import React, { useCallback, useEffect } from "react";
import { WeexModalStyle } from "./style";

/**
 * @throws Erro quando onPressESC não é implementado, o forçando a ser implementado
 * 
 * @Aviso Quando o usuário aperta `esc`, a função `onPressESC` é chamada. Use isso para permitir que um componente 
 * pai identifique quando o filho foi fechado através do pressionamento da tecla `esc`.
 * 
 * É recomendado que a condição do operador ternario ou binário responsável pela renderização desse componente
 * seja um estado fornecido através do hook `useState`, permitindo com que através do callback `onPressESC` o
 * componente pai possa controlar adequadamente quando o componente `WeexModalDialog` deve ser renderizado,
 * evitando assim bugs de usabilidade, pois WeexModalDialog utiliza nos bastidores o método da tag `<dialog />` 
 * chamado `.close()`, que faz com que a modal fique apenas invisível, mas continue dentro do html de quem esteja
 * navegando.
 * 
 * @example
 * const [isDialogOpen, setIsDialogOpen] = useState(false)
 * 
 * isDialogOpen && (
 * 		<WeexModalDialog
 * 			onClose={() => {
 * 				setIsDialogOpen(false)
 * 			}}
 * 		>
 * 			<button onClick={() => { 
 * 				setIsDialogOpen(false)
 * 			}}>
 * 				Close
 * 			</button>
 * 		</WeexModalDialog>
 * )
 * 
 * @Aviso Como `WeexModalDialog` permite a passagem de componentes filhos, caso algum componente filho tenha que
 * possuir a funcionalidade de fechar essa modal, é recomendado que esse componente filho set diretamente o estado
 * da condicional que definirá se `WeexModalDialog` deveria ser renderizado ou não, como no botão ao exemplo acima.
 * 
 * @param {Object} props
 * @param {function(): void} props.onPressESC
 * @param {React.MutableRefObject} props.dialogRef
 * @param {JSX.Element} props.children
 */
export function WeexModalDialog({ onPressESC, dialogRef, children }) {

  function onCancel(e) {		
    e.preventDefault();
    const { current } = dialogRef;
    current.close();
    onPressESC();
  }

  const openDialog = useCallback(() => {
    const { current } = dialogRef;
    current.close();
    current.showModal();
  }, [dialogRef])
	
  useEffect(() => { 
    openDialog()
  }, [openDialog]);

  return (
    <WeexModalStyle
      ref={dialogRef}
      onCancel={onCancel}
    >
      {children}
    </WeexModalStyle>
  )
}
