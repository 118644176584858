import React, { useState } from "react";
import { useStore } from 'react-redux';
import { useHistory } from "react-router-dom";
import BotaoPrimario from "../comps/botaoPrimario/BotaoPrimario";
import WeexModal from "../comps/weexModais/WeexModal";
import CampanhaService from '../services/CampanhaService';
import TermosCondicoesService from "../services/TermosCondicoesService";
import UsuarioService from '../services/UsuarioService';

function TermosCondicoes({code, privacyPolicy}){
  const i18n = useStore().getState().i18n
  let link = ""
  if(code === 'rededor') {
    link = "https://weex.digital/termos-rede-dor/"
  } else if(code === 'lorenzetti' || code === 'lorenzettiteste') {
    link = "https://weex.digital/wp-content/uploads/2023/06/02-2023_06_01_Regulamento-Sem-Meio-Amb-Loren-2023_SP-rev-02-SP-e-MG.pdf"
  } else {
    if(privacyPolicy) {
      link = privacyPolicy
    } else {
      link = "https://weex.digital/politica-de-privacidade-e-uso/"
    }
  }
  return(
    <>
      <a
        className="link-sublinhado"
        href={link}
        target="_blank"
        rel="noopener noreferrer"
      >
        <span aria-hidden="true">
          {i18n.message("registro.legal.termos", " Termos e Condições ")}
        </span>
        <span className="visually-hidden">
          {
            `
            ${i18n.message("aceiteTermos.condicao", "Para acessar a campanha, é necessário ler e aceitar os")}
            ${i18n.message("registro.legal.termos", " Termos e Condições ")}
            ${i18n.message("registro.legal.daweex", "da Weex.")}
            `
          }
        </span>
      </a>
    </>
  )
}
function AcceptLegalTerm(props) {
  const campanhaService = new CampanhaService()
  const eventoCorrente = campanhaService.campanhaCorrente()
  const {code} =eventoCorrente;
  const i18n = useStore().getState().i18n
  const termosCondicoesService = new TermosCondicoesService();
  const usuarioService = new UsuarioService()
  const [erro, setErro] = useState({
    isWrong: false,
    titulo: "",
    mensagem: "",
  });
  const history = useHistory();


  const handleLegalTerm = () => {
		
    termosCondicoesService.aceiteTermoObrigatorio(
      (erro, sucesso) => {
        if (erro) {
          setErro({
            isWrong: true,
            titulo: i18n.message("geral.erro.network.titulo", "Há algo errado"),
            mensagem:
							i18n.message("geral.erro.network.mensagem", "Houve um problema no processamento do seu pedido, por favor verifique sua internet ou contate o suporte"),
          });
          return;
        }
        if (sucesso) {
          let usuario = usuarioService.usuarioCorrente();
          usuario.legalTerm = sucesso.acceptLegalTerm
          usuarioService.setUsuarioCorrente(usuario)
          props.callbackLogin(usuario)
          campanhaService.contadorCampanha((erro, sucesso)=>{
            if(erro){
              setErro({
                isWrong: true,
                titulo: i18n.message("atividades.erro.dias.habilitados", "Erro obter lista de dias habilitados")
              });
              return;
            }
            if(sucesso){
              if(sucesso !== null){
                if(sucesso.length > 0 ){
                  history.push('/atividades')
                } else {
                  history.push("/home")
                }
              }
            }
          })
        }
      }
    );
  };

  const callbackModal = () => {
    if (erro.isWrong === true) {
      setErro({ isWrong: false });
    }
  };

	

  return (
    <>
      {erro.isWrong === true ? (
        <WeexModal
          fecharModalCallback={callbackModal}
          titulo={erro.titulo}
          conteudo={erro.mensagem}
        />
      ) : null}

      <div className="accep-legal-term" style={{ marginTop: "5vh" }}>
        <div style={{ width: "100%" }}>
          <div>
            <h3 style={{ marginBottom: "30px" }}>{i18n.message("registro.legal.termos", " Termos e Condições ")}</h3>
            <div>
              <h4 style={{ marginBottom: "50px" }}>
                <span aria-hidden="true">
                  {i18n.message("aceiteTermos.condicao", "Para acessar a campanha, é necessário ler e aceitar os")} {" "}
                </span>
                <TermosCondicoes code={code} privacyPolicy={eventoCorrente.privacyPolicy} />
                {/* <u>
                  {code === 'rededor' ? <a
                    className="link-sublinhado"
                    href="https://weex.digital/termos-rede-dor/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {i18n.message("registro.legal.termos", " Termos e Condições ")}
                  </a>:
                    <a
                      className="link-sublinhado"
                      href={eventoCorrente.privacyPolicy ? eventoCorrente.privacyPolicy : "https://weex.digital/politica-de-privacidade-e-uso/"}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {i18n.message("registro.legal.termos", " Termos e Condições ")}
                    </a>}
                </u> */}
                <span aria-hidden="true">
                  {i18n.message("registro.legal.daweex", "da Weex")}
                </span>			
              </h4>
            </div>
          </div>

          <div className="legalTerm-btn">
            <BotaoPrimario
              nome={i18n.message("aceiteTermos.acao", "Aceitar termos e condições")}
              funcao={handleLegalTerm}
              ariaStatusMessage={i18n.message("aria.status.acceptlegalterm.botaoaceitartermos", "Botão aceitar termos clicado.")}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default AcceptLegalTerm;
