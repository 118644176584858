import React from "react";
import {ButtonVideoMuralStyle} from './style';
import IconVideo from '../svg/video/video';
import { useStore } from 'react-redux';

function BotaoVideoMural(props) {
  const i18n = useStore().getState().i18n
  return (
    <ButtonVideoMuralStyle
      className={props.disabled===true? `${props.classeComplementar} 'disabled'`:`${props.classeComplementar}`}
      onClick={props.funcao}
      disabled={props.disabled}
      type="button"
      aria-label={i18n.message("dinamica.mural.publica.video","Selecione uma url para anexar um vídeo")}
    >
      <span className=' mural-label color-fundo-secundaria'>
        <span className="icon">
          <IconVideo
            label={i18n.message("arial.mural.publicar.video.icone","Ícone de um vídeo")}
          ></IconVideo>
        </span>

        <span className="label-content" >
          {i18n.message("dinamica.mural.publica.video","Selecione uma url para anexar um vídeo")}
        </span>

      </span>

    </ButtonVideoMuralStyle>
  )
}

export default BotaoVideoMural
