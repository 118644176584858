import React, {useState} from 'react';
import {useStore} from 'react-redux';

function SimNao({valor, callback}) {

  /*TODO:
    Esse componente é quase idêntico ao componente exportado em SimNao.js.
    Então esse componente local deve ser removido, a fim de utilizar o componente que já está sendo
    exportado, para não se repetir código.

    Não é recomendado fazer isso em momentos que a tarefa exigir apenas modificações muito simples,
    para não fugir do escopo do que tem que ser feito. Faça isso no momento que o escopo permitir
  */

  const [mensagemDeSelecao, setMensagemDeSelecao] = useState("")
  const i18n = useStore().getState().i18n;

  const clique=(valor) => {
    callback(valor)
    const mensagemPositivo = i18n.message("aria.hidden.video.feedbackPositivo.selecionado", "feedback positivo selecionado!");
    const mensagemNegativo = i18n.message("aria.hidden.video.feedbackNegativo.selecionado", "feedback negativo selecionado!") 
    setMensagemDeSelecao(`${valor === true ? mensagemPositivo : mensagemNegativo}`)
  }
  return (
    <div>
      <button className='close-btn' onClick={() => {clique(true)}}>
        <img
          alt={i18n.message("alt.video.feedbackPositivo","feedback positivo")}
          src={valor === true ? '/new_positive_full.svg':'/new_positive_empty.svg'}
          style={{marginRight: '1.0em'}}
        />
      </button>

      <button className='close-btn' onClick={() => {clique(false)}}>
        <img
          alt={i18n.message("alt.video.feedbackNegativo", "feedback negativo")}
          src={valor===false? '/new_negative_full.svg':'/new_negative_empty.svg'}
        />
      </button>
      <span
        className="visually-hidden"
        role="status"
      >
        {mensagemDeSelecao}
      </span>
    </div>
  )
}

function FeedbackVideo(props) {
  const i18n = useStore().getState().i18n;
  return (
    <div className='video-feedback-desktop' role="form" aria-label="Formulário de feedback sobre o vídeo" style={{textAlign: 'center',marginTop: '30px',marginBottom: '30px'}}>
      <div className='video-quiz-feedback-markup'>
        <p className='video-pergunta'>{props.pergunta}</p>
        <div role="radiogroup" aria-label={i18n.message("aria.label.opcoes.feedback","Opções de feedback sobre o vídeo")}>
          <SimNao valor={props.curtiuVideo} callback={props.onChangeCurtiuVideo} />
        </div>
      </div>
      <div className='video-text-area-desktop'>

        <label className='visually-hidden' htmlFor="comentario-video">{i18n.message("arial.label.comentario.video", "Digite aqui seu comentário sobre o vídeo: ")}</label>
        <textarea
          id="comentario-video"
          value={props.comentarioVideo}
          onChange={props.onChangeComentarioVideo}
          className="textarea text-area-feedback-video"
          placeholder={props.placeholder}
        ></textarea>

      </div>
    </div>

  )
}

export default FeedbackVideo;
