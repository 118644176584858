import styled from "styled-components";


export const CampanhaLogosStyle = styled.div`

.foreground{
  color: ${props => { 
    return props.theme.textosCorFundoSecundario }};
}

.foreground_not_logged{
  color: ${props => { 
    return props.theme.textosCorFundoPrimario }};
}


.campanha.logosTopo {
  width: 100vw;
  min-width: 100vw;
}

.campanha_logosTopo{
  text-align: center;
}

.campanha_logosTopo_left {
  max-width: 25vw;
  float: left;
}

.campanha_logosTopo_right {
  max-width: 25vw;
  float: right;
}

.campanha_logosTopo__centro {
  max-width: 30%;
}

.campanha_logosTopo_name {
  margin-top: 0.5em;
  /* text-align: center; */
  /* text-transform: uppercase; */
  /* font-size: 1.728em; */
  /* font-weight: 700; */
  
}

.campanha_logosTopo_subtitle {
  /* text-align: center; */
  /* font-size: 1.382em; */
  /* font-weight: 400;   */
}

.campanha_logosTopo_client {
  /* text-align: center; */
  /* font-size: 1.44em; */
  /* text-transform: uppercase; */
}


@media screen and (min-width: 700px) {

  .campanha_logosTopo__centro {
    max-width: 10vw;
  }


  

} 

`