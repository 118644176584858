import instance from './Api';
import CampanhaService from './CampanhaService';
import UsuarioService from './UsuarioService'
export default class FeedbackService {

  feedbackHabilitado() {
    let campanha = new CampanhaService().campanhaCorrente()
    if (campanha && campanha.feedbackDate) {
      let agora = new Date()
      let data = campanha.feedbackDate.replace(" 00:00:00", "")
      let componentes = data.split("/")
      let novaData = new Date(componentes[2], componentes[1] - 1, componentes[0],0,0,0,0)
      return novaData < agora
            
    }
    return false
  }

  feedbackRespondido(callback) {
    let usuarioCorrente = new UsuarioService().usuarioCorrente();
    if (usuarioCorrente) {
      let uuid = new UsuarioService().usuarioCorrente().participantCode
      instance.get(this.host() + "/api/v1/participant/" + uuid + "/feedback")
        .then((response) => {callback(null, response.data)})
        .catch((error) => {callback(error,null)})
    }
  }

  enviarFeedback(feedback, callback) {
    let uuid = new UsuarioService().usuarioCorrente().participantCode
    feedback.participanteUuid = uuid
    let body = {
      participanteUuid:uuid,
      relevancia:feedback.conteudoRelevante,
      recomendaria:feedback.recomendaria,
      participariaNovamente:feedback.participariaNovamente,
      message:feedback.mensagem
    }
    instance.post(this.host() + "/api/v1/participant/" + uuid + "/feedback", body)
      .then((response) => {callback(null, response.data)})
      .catch((error) => {callback(error,null)})
  }

  host() {
    return process.env.REACT_APP_HOST_API
  }
}