import React from "react";
import { ButtonVideoStyle } from './style';



function BotaoVideo(props) {
  return (
    <ButtonVideoStyle
      className={props.disabled === true ? `${props.classeComplementar} 'disabled'` : ``}
      onClick={props.funcao}
      disabled={props.disabled}
      aria-label={props.nome}
    >
      {props.nome}
    </ButtonVideoStyle>
  )
}

export default BotaoVideo
