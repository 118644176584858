import instance from "./Api";
export default class MuralService {

  curtirOuDescurtirPublicacao(code, request, callback) {
    instance.post(this.host() + '/api/v1/campaign/mural/' + code + '/like', request)
      .then((response) => {
        callback(null, response.data)
      })
      .catch((error) => {
        callback(error, null)
      })
  }

  criarPublicacao(codeAgenda, codeAtividade, request, callback) {
    instance.post(this.host() + '/api/v1/campaign/schedule/' + codeAgenda + '/activity/' + codeAtividade + '/mural', request)
      .then((response) => {
        callback(null, response.data)
      })
      .catch((error) => {
        callback(error, null)
      })
  }

  fixarPublicacao(codePublicacao, callback){
    instance.put(`${this.host()}/api/v1/campaign/mural/${codePublicacao}/fix`)
      .then((response) => {
        callback(null, response.data)
      })
      .catch((error) => {
        callback(error, null)
      })
  }

  buscarPublicacoes(codeAgenda, codeAtividade, request, callback) {
    let params = "";

    if (request.pageSize) {
      params = params + `pageSize=${request.pageSize}&`;
    }
    if (request.page || typeof request.page === 'number') {
      params = params + `page=${request.page}&`;
    }
    if (request.name) {
      params = params + `name=${request.name}&`;
    }
    instance.get(this.host() + '/api/v1/campaign/schedule/' + codeAgenda + '/activity/' + codeAtividade + '/mural?' + params)
      .then((response) => {
        callback(null, response.data)
      })
      .catch((error) => {
        callback(error, null)
      })
  }

  criarComentario(codePublicacao, request, callback) {
    instance.post(this.host() + '/api/v1/campaign/mural/' + codePublicacao + '/comment', request)
      .then((response) => {
        callback(null, response.data)
      })
      .catch((error) => {
        callback(error, null)
      })
  }

  buscarComentarios(codePublicacao, request, callback) {
    let params = "";
    for (const key in request) {
      const value = request[key];
      if (!value) {
        continue;
      }
      params = params + `${key}=${value}&`;
    }
    instance.get(this.host() + '/api/v1/campaign/mural/' + codePublicacao + '/comment?' + params)
      .then((response) => {
        callback(null, response.data)
      })
      .catch((error) => {
        callback(error, null)
      })
  }

  deleteComentario(codePublicacao, codeComentario, callback) {
    instance.delete(this.host() + '/api/v1/campaign/mural/' + codePublicacao + '/comment/' + codeComentario)
      .then((response) => {
        callback(null, response)
      })
      .catch((error) => {
        callback(error, null)
      })
  }

  deletePublicacao(codePublicacao, callback) {
    instance.delete(this.host() + '/api/v1/campaign/mural/' + codePublicacao)
      .then((response) => {
        callback(null, response)
      })
      .catch((error) => {
        callback(error, null)
      })
  }

  enviarImagem(file, callback) {
    const arquivo = new FormData();
    arquivo.append('file', file);
    instance.post(this.host() + '/api/v1/campaign/mural/image', arquivo, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then((response) => {
        callback(null, response.data)
      })
      .catch((error) => {
        callback(error, null)
      })
  }

  host() {
    return process.env.REACT_APP_HOST_API
  }

}
