import React, { useRef, useState } from "react";
import { animations } from "react-animation";
import { useStore } from "react-redux";
import BotaoPrimario from "../botaoPrimario/BotaoPrimario";
import { WeexModalDialog } from "./WeexModalDialog";

/**
 * @param {Object} props
 * @param {string} props.rota
 * @param {string} props.conteudo_dangerouslySetInnerHTML
 * @param {string} props.imagemSrc
 * @param {string} props.atvName
 * @param {string} props.atvTitle
 * @param {function(): void} props.fecharModalCallback
 * @param {function(): void} props.irParaDinamica
 */
function WeexModalAtividades({
  fecharModalCallback,
  irParaDinamica,
  rota,
  conteudo_dangerouslySetInnerHTML,
  imagemSrc,
  atvName,
  atvTitle
}) {

  const [mostrarModal, setMostrarModal] = useState(true)
  const dialogRef = useRef(null)
  const i18n = useStore().getState().i18n;

  let acaoDoBotao;
  switch (rota) {
  case "video":
    acaoDoBotao = i18n.message("atividades.video.acao.btn", "ASSISTIR!");
    break;
  case "fatoFake":
    acaoDoBotao = i18n.message("atividades.fatoFale.acao.btn", "COMEÇAR!");
    break;
  case "newHabit":
    acaoDoBotao = i18n.message("atividades.newHabit.acao.btn", "COMEÇAR!");
    break;
  case "game":
    acaoDoBotao = i18n.message("atividades.game.acao.btn", "JOGAR!");
    break;
  case "extra":
    acaoDoBotao = i18n.message("atividades.extra.acao.btn", "ACESSAR!");
    break;
  case "mural":
    acaoDoBotao = i18n.message("atividades.mural.acao.btn", "ACESSAR!");
    break;
  default:
    acaoDoBotao = i18n.message(
      "atividades.atividadeDefault.acao.btn",
      "CONTINUAR!"
    );
  }

  function ocultar () {
    fecharModalCallback();
    dialogRef.current.close()
    setMostrarModal(false)
  };

  return (
    mostrarModal ? (
      <WeexModalDialog
        onPressESC={ocultar}
        dialogRef={dialogRef}
      >
        <div
          className={"modal is-active"}
          style={{ animation: animations.popIn, zIndex: "102" }}
        >
          <div className="modal-background weex-modal-background"></div>
          <div className="modal-card weex-modal-card">
            <header className="modal-card-head weex-modal-card-header">
              <button
                onClick={ocultar}
                className="close-btn"
                aria-label={i18n.message("aria.label.fechar.modal", "Fecha a janela e volta para a página atividades.")}
              >
                <img
                  src="/close-btn.png"
                  alt={i18n.message("alt.button.imagem.fechar", "ícone em formato de um X indicando o encerramento de uma ação.")}
                />
              </button>
            </header>
            <header className="weex-modal-card-header wex-modal-card-header-title">
              <p className="nome-atividade">
                {atvTitle ? atvTitle : atvName}
              </p>
            </header>
            <section className="modal-card-body has-text-centered">
              {imagemSrc ? (
                <div className="weex-modal-card-body__texto">
                  <p className="modal-card-title wex-modal-card-img">
                    <img
                      src={imagemSrc}
                      alt={i18n.message(
                        "alt.imagem.apresentacao.atividade",
                        "Imagem apresentação da atividade"
                      )}
                      className="image is-2by1 retirar-padding"
                    />
                  </p>
                </div>
              ) : (
                ""
              )}
              <div className="weex-modal-card-body__texto">
                <div dangerouslySetInnerHTML={{ __html: conteudo_dangerouslySetInnerHTML }}></div>
              </div>
            </section>
            <footer
              className="modal-card-foot weex-modal-card-footer"
              style={{ textAlign: "center" }}
            >
              <div className="modal-card-foot-button-mobile">
                <BotaoPrimario 
                  nome={acaoDoBotao} 
                  funcao={irParaDinamica} 
                  ariaStatusMessage={i18n.message("aria.status.weexmodalatividades.botaoiniciarativida", "Botão para iniciar atividade clicado.")}
                />
              </div>
              <div className="modal-card-foot-button-desktop">
                <div className="modal-button">
                  <BotaoPrimario 
                    nome={acaoDoBotao} 
                    funcao={irParaDinamica} 
                    ariaStatusMessage={i18n.message("aria.status.weexmodalatividades.botaoiniciarativida", "Botão para iniciar atividade clicado.")}
                  />
                </div>
              </div>
            </footer>
          </div>
        </div>
      </WeexModalDialog>
    ) : <></>
  );
}

export default WeexModalAtividades;
