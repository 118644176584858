import React from "react";
import { ButtonSecundarioStyle } from './style';



/**
 * Componente de botão secundário.
 * @param {Object} props - Propriedades do componente.
 * @param {string} props.classeComplementar - Classe CSS adicional para o botão.
 * @param {function} props.funcao - Função de clique do botão.
 * @param {boolean} props.disabled - Indica se o botão está desabilitado.
 * @param {string} props.nome - Texto exibido no botão.
 * @param {string} props.ariaLabelMsg - Texto exibido para leitores de tela.
 * @returns {JSX.Element} - Elemento JSX representando o botão secundário.
 */
function BotaoSecundario({ classeComplementar, funcao, disabled, nome, ariaLabelMsg }) {
  return (
    <ButtonSecundarioStyle
      className={disabled ? `${classeComplementar} disabled` : `${classeComplementar}`}
      onClick={funcao}
      disabled={disabled}
      aria-label={ariaLabelMsg}
      aria-disabled={disabled ? "true" : "false"}
    >
      {nome}
    </ButtonSecundarioStyle>
  );
}


export default BotaoSecundario
