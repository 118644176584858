import React, {Component} from 'react';
import {AnimateOnChange} from 'react-animation';
export default class Notificacao extends Component {

    state = {
      ocultar:false
    }

    fechar = () => {
      this.setState({ocultar:true})
    }

    render() {
      let classes = this.props.sucesso ? "notification is-success is-light" : "notification is-danger is-light"
      let mensagem = this.props.mensagem
        


      if (! mensagem || this.state.ocultar) {
        return (<div>&nbsp;</div>)
      }

      return (
        <AnimateOnChange>
          <div className={classes}>
            <button className="delete" onClick={this.fechar}></button>
            {mensagem}
          </div>
        </AnimateOnChange>
      )
        
    }
}