import React, { useEffect, useState } from "react";
import { AnimateOnChange } from "react-animation";
import { useStore } from 'react-redux';
import { useHistory, useLocation, useParams } from "react-router-dom";
import CardFatoFake from "../comps/CardFatoFake";
import CardRespostaFatoFake from "../comps/CardRespostaFatoFake";
import BotaoAsALink from "../comps/botaoAsALink/BotaoAsALink";
import BotaoAsALinkIconeEsquerda from "../comps/botaoAsALink/BotaoAsAlinkWithIconLeft";
import DinamicasHeader from "../comps/dinamicasHeader/DinamicasHeader";
import WeexModal from "../comps/weexModais/WeexModal";
import AgendaService from "../services/AgendaService";
import AtividadesService from "../services/AtividadesService";
import CampanhaService from "../services/CampanhaService";
import LoaderService from "../services/LoaderService";
import TemaService from "../services/TemaService";
import { FatoFakeStyle } from "./FatoFakerStyle";

function FatoFake() {

  const i18n = useStore().getState().i18n
  const isdesktop = useStore().getState().isDesktopMode
  const history = useHistory();
  const location = useLocation();
  let { codigo, codigoAtividade } = useParams();
  const atividadesService = new AtividadesService();
  const agendaService = new AgendaService();
  const loaderService = new LoaderService();
  const [processando, setProcessando] = useState(false);
  const [erro, setErro] = useState(null);
  const [fatoFake, setFatoFake] = useState();
  const [indiceFatoFake, setIndiceFatoFake] = useState(0);
  const [marcadorProgresso, setMarcadorProgresso] = useState();
  const [status, setStatus] = useState({
    respondido: false,
    resposta: null,
    acertos: 0,
  });
  const [primeiraExecucao, setPrimeiraExecucao] = useState(true);
  const [atividade, setAtividade] = useState();
  const campanhaService = new CampanhaService();
  useEffect(() => {
    if (!processando) {
      setProcessando(true);
      atividadesService.iniciarAtividade(
        codigo,
        codigoAtividade,
        (erro, atividade) => {
          if (atividade) {
            if (atividade.dateFinished !== null) {
              agendaService.atualizarAgenda(
                codigo,
                codigoAtividade,
                "FACT_OR_FAKE",
                atividade.factOrFake.questions.length,
                atividade.score,
                (erro, sucesso) => {
                  if (sucesso) {
                    setPrimeiraExecucao(false);
                  } else if (erro) {
                    setErro({
                      titulo: i18n.message("dinamica.erro.jaexecutada.titulo", "Erro ao exibir atividade já executada"),
                      mensagem:
												i18n.message("dinamica.erro.jaexecutada.mensagem", "Falha ao atualizar informações de atividade executadas, entre em contato com o suporte"),
                      voltarAgenda: true,
                    });
                  }
                }
              );
            }
            setFatoFake(atividade.factOrFake);
            setAtividade(atividade);
            if (atividade.factOrFake.questions.length > 1) {
              loaderService.load(atividade.factOrFake.questions[1].picture);
            }
            let dimensoes =
							(atividade.factOrFake.questions.length < 10
							  ? 4
							  : 40 / atividade.factOrFake.questions.length) ;
            if(isdesktop){
              dimensoes = dimensoes / 4;
            }
						
            dimensoes = dimensoes + "vw"

            let progresso = atividade.factOrFake.questions.map((a, i) => {
              return i <= indiceFatoFake ? (
                <div
                  className="marcador marcador-concluido"
                  style={{ width: dimensoes, height: dimensoes }}
                  key={i}
                />
              ) : (
                <div
                  className="marcador"
                  style={{ width: dimensoes, height: dimensoes }}
                  key={i}
                />
              );
            });
            setMarcadorProgresso(progresso);
          }
          if (erro) {
            if (
              erro.response &&
							erro.response.data &&
							erro.response.data.message
            ) {
              setErro({
                titulo: i18n.message("dinamica.erro.iniciar.titulo", "Erro ao iniciar atividade"),
                mensagem: erro.response.data.message,
                voltarAgenda: true,
              });
            } else {
              setErro({
                titulo: i18n.message("dinamica.erro.iniciar.titulo", "Erro ao iniciar atividade"),
                mensagem:
									i18n.message("dinamica.erro.iniciar.mensagem", "Entre em contato com o suporte ou tente novamente mais tarde."),
                voltarAgenda: true,
              });
            }
          }
        }
      );
    }
    let service = new TemaService(campanhaService.campanhaCorrente());
    service.aplicarTema("HOME");
    // TODO: Ao colocar as dependencias solicitadas pelo Lint, a aplicação fica em loop infinito no useEffect --> realacionada tarefa 14797 --> By Renan
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const callbackModal = () => {
    setErro(null);
    if (erro.voltarAgenda) {
      history.push("/atividades");
    }
  };

  const finalizar = () => {
    let acertos = status.acertos;
    let perguntas = fatoFake.questions.length;
    let percentual = 100 * (acertos / perguntas).toFixed(2);
    let corpo = {
      score: status.acertos,
      scoreTextual: acertos + " de " + perguntas + " (" + percentual + "%)",
      numeroQuestoes: fatoFake.questions.length,
      actionId: atividade.actionId,
    };
    if (primeiraExecucao) {
      atividadesService.finalizarAtividade(
        codigo,
        codigoAtividade,
        "FACT_OR_FAKE",
        corpo,
        (erro, atividade) => {
          if (atividade) {
            history.push("/missionFinished/" + codigo + "/FACT_OR_FAKE/" + codigoAtividade);
          }
          if (erro) {
            if (
              erro.response &&
							erro.response.data &&
							erro.response.data.message
            ) {
              setErro({
                titulo: i18n.message("dinamica.erro.finalizar.titulo", "Erro ao finalizar atividade"),
                mensagem: erro.response.data.message,
                voltarAgenda: false,
              });
            } else {
              setErro({
                titulo: i18n.message("dinamica.erro.finalizar.titulo", "Erro ao finalizar atividade"),
                mensagem:
									i18n.message("dinamica.erro.finalizar.mensagem", "Entre em contato com o suporte ou tente novamente mais tarde."),
                voltarAgenda: false,
              });
            }
          }
        }
      );
    } else {
      atividadesService.finalizarAtividade(
        codigo,
        codigoAtividade,
        "FACT_OR_FAKE",
        corpo,
        (erro, sucesso) => {
          if (sucesso) {
            history.push("/missionFinished/" + codigo + "/FACT_OR_FAKE/" + codigoAtividade);
          } else if (erro) {
            setErro({
              titulo: "Erro ao finalizar atividade",
              mensagem:
								"Falha ao atualizar informações de atividade executadas, entre em contato com o suporte",
              voltarAgenda: false,
            });
          }
        }
      );
    }
  };

  const proximoFatoFake = () => {
    let indice = indiceFatoFake + 1;
    if (indice === fatoFake.length) {
      return;
    }
    if (fatoFake.questions.length > indice + 1) {
      loaderService.load(fatoFake.questions[indice + 1].picture);
    }
    setIndiceFatoFake(indice);
    let situacao = {
      resposta: null,
      respondido: false,
      acertos: status.acertos,
    };
    setStatus(situacao);
    let dimensoesCalc =
			(atividade.factOrFake.questions.length < 10
			  ? 4
			  : 40 / fatoFake.questions.length);
		
    if(isdesktop){
      dimensoesCalc = dimensoesCalc/4
    }

    let dimensoes = dimensoesCalc + "vw"
				
    let progresso = fatoFake.questions.map((a, i) => {
      return i <= indice ? (
        <div
          className="marcador marcador-concluido"
          style={{ width: dimensoes, height: dimensoes }}
          key={i}
        ></div>
      ) : (
        <div
          className="marcador"
          style={{ width: dimensoes, height: dimensoes }}
          key={i}
        ></div>
      );
    });
    setMarcadorProgresso(progresso);
  };

  const responderFatoFake = (resp) => {
    let pontuacao = status.acertos;
    if (resp === fatoFake.questions[indiceFatoFake]?.fact) {
      pontuacao++;
    }
    let situacao = { respondido: true, resposta: resp, acertos: pontuacao };
    setStatus(situacao);
  };

  return (
    <FatoFakeStyle>
      <div className="dinamica-content-desktop">
        <div className='desktop-fatoFake-voltar'>
          <BotaoAsALinkIconeEsquerda
            icon="fas fa-arrow-left "
            classeComplementar="header-como-funciona-desktop-botao"
            nome={i18n.message("geral.voltar", "Voltar")}
            ariaLabelMsg={i18n.message("aria.label.geral.mensagem.voltar.atividades", "Volta para a página de atividades.")}
            funcao={() => { history.push("/home") }}
          />
        </div>
        {erro != null && (
          <WeexModal
            fecharModalCallback={callbackModal}
            titulo={erro.titulo}
            conteudo={erro.mensagem}
          />
        )}
        <AnimateOnChange style={{width: "100%"}}>
          {fatoFake != null && (
            <div className="dinamica-container">
              <div className="fatoFake-desktop-content-left">
                <DinamicasHeader
                  dinamica={fatoFake}
                  theme={fatoFake.theme}
                  dinamicaNome={i18n.message("dinamica.fatofake.nome", "Fato ou Fake?")}
                  dinamicaAlt={i18n.message("alt.dinamica.icone.fatofake", "Ícone de uma mão escolhendo entre o certo e o errado.")}
                  dinamicaImg={location && location.state && location.state.icon ? location.state.icon : "/new_ff.svg"}
                />
              </div>
              <div className="width-card">
                {status.respondido 
                  ? (
                    <CardRespostaFatoFake
                      ff={fatoFake}
                      fatoFake={fatoFake.questions[indiceFatoFake]}
                      finalizar={finalizar}
                      proximoFatoFake={proximoFatoFake}
                      ultimo={indiceFatoFake >= fatoFake.questions.length - 1}
                      resposta={status.resposta}
                    />
                  ) : (
                    <CardFatoFake
                      i18n={i18n}
                      ff={fatoFake}
                      fatoFake={fatoFake.questions[indiceFatoFake]}
                      responderFatoFake={responderFatoFake}
                      theme={fatoFake.theme}
                    />
                  )
                }
                <div className="marcador-progresso">{marcadorProgresso}</div>
                <div className="volta-mobile" style={{marginBottom: '2rem'}}>
                  <BotaoAsALink
                    nome={i18n.message("missaoCumprida.repetir", "Voltar")}
                    ariaLabelMsg={i18n.message("aria.label.geral.mensagem.voltar.atividades", "Volta para a página de atividades.")}
                    funcao={() => history.push("/")}
                  />
                </div>
              </div>
            </div>
          )}
        </AnimateOnChange>
      </div>
    </FatoFakeStyle>

  );
}

export default FatoFake;
