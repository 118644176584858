import React, { Component } from "react";
import { connect } from "react-redux";
import BotaoAsALinkIconeEsquerda from "../../comps/botaoAsALink/BotaoAsAlinkWithIconLeft";
import GameRankingList from "../../comps/gameRanking/GameRankingList";
import LeadershipRanking from "../../comps/gameRanking/LeadershipRanking";
import PontuacaoUsuarioCorrente from "../../comps/gameRanking/PontuacaoUsuarioCorrente";
import RankingSelect from "../../comps/gameRanking/RankingSelect";
import CampanhaService from "../../services/CampanhaService";
import RankingGameService from "../../services/RankingGameService";
import TemaService from "../../services/TemaService";

import AtividadeEntity from '../../comps/DiaSemanaAgenda/AtividadeEntity';
import { GameRankingsStyled } from "./styled";

class GameRankings extends Component {
    i18n = this.props.i18n;
    campanhaService = new CampanhaService();
    rankingGameService = new RankingGameService();
    gameUuid = this.props.gameUuid;
    constructor(props) {
      super(props);
      this.state = {
        rankings: [],
        rankingTop3: [],
        rankingWithFormat: [],
        erro:null,
        pontuacaoUsuario:{
          name: '',
          rating: null,
          unit: '',
          uuidParticipante: ''
        },

      };
    }

    componentDidMount() {

      let temaService = new TemaService(this.campanhaService.campanhaCorrente())
      temaService.aplicarTema('HOME')
      const games = this.rankingGameService.getGamesAvailable();
      if (games.length > 0) {
        const selectedGame = games.find(g => new AtividadeEntity(g).uuid === this.gameUuid);
        const gameToSearch = selectedGame || games[0];
        this.searchRankingGame(gameToSearch);
      }

    }

    searchRankingGame = (game) =>{
      this.rankingGameService.buscarRanking(game.uuid, game.scoreType, 0).then((ranking) => {
        this.setState({rankings: ranking.data.topRanking});
        this.setState({rankingTop3: [...ranking.data.topRanking.slice(0,3)]});
        this.formaterNumber();
        this.searchRankingUsuario(game, ranking.data.topRanking)
      }).catch((error) => {
        this.setState({erro:"Erro ao obter ranking, tente novamente mais tarde"})
      });
    }

    searchRankingUsuario = (game, ranking) =>{
      this.rankingGameService.getPontuacaoAtividadeUsuario(game.uuid, game.scoreType).then((pontuacao) => {
        let encontrei = false

        ranking.forEach((rank) => {
          if(rank.uuidParticipante === pontuacao.data.uuidParticipante){
            this.setState({pontuacaoUsuario: rank});
            encontrei = true;
          }
        });
        if(encontrei === false){
          this.setState({pontuacaoUsuario: pontuacao.data})
        }

      }).catch((error) => {
        this.setState({erro:"Erro ao obter pontuação do participante, tente novamente mais tarde"})
      });
    }

    formaterNumber = () => {
      let ranking = [...this.state.rankings];
      let rankingWithFormat = ranking.map((ranking) => {
        if(ranking.position < 10){
          ranking.position = "0"+ranking.position;
        }
        return ranking;
      });
      rankingWithFormat.splice(0,3);
      this.setState({rankingWithFormat: rankingWithFormat});
    }


    render() {

      if (this.state.erro) {
        return (<div>{this.state.erro}</div>)
      }
      return (
        <GameRankingsStyled>
          <div className='dinamica-content-desktop' >
            <span className="header-ranking">
              <span className="size-content">
                <BotaoAsALinkIconeEsquerda
                  classeComplementar="header-como-funciona-desktop-botao"
                  icon="fas fa-arrow-left "
                  nome={this.i18n.message("geral.voltar", "Voltar")}
                  ariaLabelMsg={this.i18n.message("aria.label.geral.mensagem.voltar.trofeus", "Volta para a página de troféus.")}
                  funcao={()=> this.props.history.push("/trophy")}
                />
              </span>



            </span>
            <div className="dinamica-container">
              <div className="ranking-page">
                <div className="ranking-top">
                  <div className="label-select"><p>{this.i18n.message("selecione.game", "Selecione o game")}</p></div>
                  <RankingSelect
                    gameSelected = {this.searchRankingGame}
                  ></RankingSelect>
                  <div>
                    <div className="isMobile pontuacao-usuario-corrente-mobile">
                      <PontuacaoUsuarioCorrente
                        pontuacaoUsuario={this.state.pontuacaoUsuario}
                        ranking = {this.state.rankings}
                      />
                    </div>
                    <LeadershipRanking
                      topRanking = {this.state.rankingTop3}
                    ></LeadershipRanking>
                  </div>
                </div>

                <div className="ranking-list">
                  <GameRankingList
                    pontuacaoUsuario = {this.state.pontuacaoUsuario}
                    ranking = {this.state.rankings}
                    rankingFormat = {this.state.rankingWithFormat}
                  ></GameRankingList>
                </div>

              </div>
            </div>
          </div>
        </GameRankingsStyled>


      );

    }

}

const mapStateToProps = function (state) {
  return {
    i18n: state.i18n,
    gameUuid: state.gameUuid
  }
}

export default connect(mapStateToProps)(GameRankings)
