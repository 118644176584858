import styled from "styled-components";
export const Div = styled.div`
/* #region DESKTOP */
@media screen and (min-width: 700px) {   
    width: 30%;
    margin-left: 1%;
    color: ${props => { return props.theme.textosCorFundoSecundario }};
    h1, h2{
        text-align: left;
    }
}
/* #endregion DESKTOP */
`
export const Paragraph = styled.p`
/* #region DESKTOP */
    @media screen and (min-width: 700px) {
        text-transform: uppercase;
        text-align: center;
        font-size: 16px;
        line-height: 14px;
        font-weight: 700;
        color: ${props => { return props.theme.textosCorFundoSecundario }};
    }
/* #endregion DESKTOP */
`
export const Section = styled.section`
/* #region DESKTOP */
    @media screen and (min-width: 700px) {
        a, .button-link{
            text-transform: uppercase;
            text-align: center;
            font-size: 16px;
            line-height: 14px;
            font-weight: 700;
            color: ${props => { return props.theme.textosCorFundoSecundario }};
            :hover{
                filter: opacity(0.5);
            }
        }

        .out {
            text-transform: capitalize;
            filter: opacity(0.7);
            :hover{
                filter: opacity(0.9);
            }
        }
    }
/* #endregion DESKTOP */
`