import React, { useState } from "react";
import { useStore } from 'react-redux';
import { useHistory } from "react-router-dom";
import AgendaService from "../../../services/AgendaService";
import PreSelecaoInteressesService from "../../../services/PreSelecaoInteressesService";
import UsuarioService from "../../../services/UsuarioService";
import BotaoPrimario from "../../botaoPrimario/BotaoPrimario";
import InteressesConfirmacaoModal from '../../weexModais/InteressesConfirmacaoModal';
import WeexModal from "../../weexModais/WeexModal";
import { InteresseFormStyle } from "./style";

const Interesse = (props) => {
  const history = useHistory();
  const preSelecaoInteressesService = new PreSelecaoInteressesService();
  const agendaService = new AgendaService();
  const i18n = useStore().getState().i18n;
  const [interesse, setInteresse] = useState([""]);
  const [processando, setProcessando] = useState(false);
	

  const [erro, setErro] = useState({
    isWrong: false,
    titulo: "",
    mensagem: "",
    voltar: false,
  });
  const [salvando, setSalvando] = useState({
    isSaved: false,
    titulo: "",
    mensagem: "",
  });

  const [confirmando, setConfirmando] = useState({
    isConfirm: false,
    titulo: "",
    mensagem: "",
    voltar: false,
  });

	
  const usuarioService = new UsuarioService();
  const usuarioCorrente = usuarioService.usuarioCorrente();
	

  const handleChangeInteresse = (event) => {
    if (props.canSelectInterets) {
      const { name, value, checked } = event.target;

      if (checked) {
        setInteresse([
          ...interesse,
          {
            name: name,
            uuid: value,
          },
        ]);
      } else {
        setInteresse(
          interesse.filter(function (val) {
            return val.uuid !== value;
          })
        );
      }
    }
  };

  const confirmacaoDeEnvioDeInteresses = () =>{
    setConfirmando({
      isConfirm: true,
      titulo: i18n.message("interesse.selecaoObrigatoria.mensagemConfirmaTitulo", "Confirma seleção de interesses?"),
      mensagem: i18n.message("interesse.selecaoObrigatoria.mensagemConfirmaConteudo", "Você não poderá alterar mais tarde seus interesses. Deseja Continuar?")
    })
  }

  const voltarInteresse = () => {
    if (confirmando.isConfirm === true) {
      setConfirmando({ isConfirm: false });
    }
  }

  const submitInteresse = (event) => {
    event.preventDefault();

    if(!interesse.length){
      return
    }

    if(interesse.length > props.daysOfEvent ){
      setErro({
        isWrong: true,
        titulo: i18n.message("interesse.erro.aoAdicionarInteresse.titulo", "Erro ao adicionar interesse"),
        mensagem: i18n.message("interesse.erro.quantidadeDeInteresses.mensagem", "Você deve selecionar no máximo " + props.daysOfEvent + " temas de interesse para continuar."),
        voltar: true,
      });
      return
    }
    preSelecaoInteressesService.selecaoDeInteresses(
      interesse,
      (erro, sucesso) => {
        if (erro) {
          setErro({
            isWrong: true,
            titulo: i18n.message("interesse.erro.aoAdicionarInteresse.titulo", "Erro ao adicionar interesse"),
            mensagem: i18n.message("interesse.erro.quantidadeDeInteresses.mensagem", "Você deve selecionar no máximo " + props.daysOfEvent + " temas de interesse para continuar."),
            voltar: true,
          });
          setSalvando({ isSaved: false });
        }
        if (sucesso) {
          setInteresse(sucesso);
          agendaService.atualizarInteresse(true);
          setProcessando(false);
          setErro({ isWrong: false });
          setSalvando({
            isSaved: true,
            titulo: i18n.message("interesse.sucesso.interesseAdicionado.titulo", "Interesses adicionados"),
            mensagem: i18n.message("interesse.sucesso.interessesSalvos.mensagem", "Os interesses foram salvos com sucesso"),
          });
        }
      }
    );
  };

  const callbackModal = () => {
    if (erro.isWrong === true) {
      setErro({ isWrong: false });
      history.push("/interests");
    }
  };

  const callbackModalSuccess = () => {
    if (salvando.isSaved === true) {
      setSalvando({ isSaved: false });
      history.push("/");
    }
  };

  if (!processando) {
    setProcessando(true);
    setSalvando({ isSaved: false });
    agendaService.agendaCorrente((erro, interesseJaSelecionado) => {
      if (interesseJaSelecionado) {
        let interesseEditado = interesseJaSelecionado.interests;
        setInteresse(interesseEditado);
      }
      if (erro) {
        setErro(erro);
      }
    });
  }


  const osInteresses = props.campanha.map((i) => {
    let clicked = false;
    let isInteresseUsuario = false;
    if(i.units !=null){
      isInteresseUsuario = i.units.find((is)=> is===usuarioCorrente.unit)
    }
		
    if(i.units===null || isInteresseUsuario){
      return (
        <div className="container-interesse" key={i.uuid}>
          {interesse.forEach((e) => {
            if (i.uuid === e.uuid) {
              clicked = !clicked;
            }
          })}
          <label className="title-interesse" htmlFor={i.uuid} style={{flexShrink: '10'}}>
            {i.name}
          </label>
          <label className="switch-interesse">
            <input
              id={i.uuid}
              className="checkbox-interesse"
              type="checkbox"
              name={i.name}
              value={i.uuid}
              checked={clicked}
              onChange={handleChangeInteresse}
            />
            <span className="slider-interesse round" style={{width: "4rem"}}></span>
          </label>
        </div>
      );
    } else {
      return null
    }
  });

  const osInteressesDesabilitados = props.campanha.map((i) => {
    let clicked;

    return (
      <div className="container-interesse" key={i.uuid}>
        {interesse.forEach((e) => {
          if (i.uuid === e.uuid) {
            clicked = !clicked;
          }
        })}
        <label className="title-interesse" htmlFor={i.uuid}>
          {i.name}
        </label>
        <label className="switch-interesse">
          <input
            id={i.uuid}
            className="checkbox-interesse"
            type="checkbox"
            name={i.name}
            value={i.uuid}
            checked={clicked}
            disabled={!props.canSelectInterets}
          />
          <span className="slider-interesse round"></span>
        </label>
      </div>
    );
  });

  return (
    <>
      {
        confirmando.isConfirm === true ?
          (
            <InteressesConfirmacaoModal
              confirmarCallback={submitInteresse}
              cancelarCallback={voltarInteresse}
              titulo={confirmando.titulo}
              conteudo={confirmando.mensagem}
            />
          ) : null
      }
      {salvando.isSaved === true ? (
        <WeexModal
          fecharModalCallback={callbackModalSuccess}
          titulo={salvando.titulo}
          conteudo={salvando.mensagem}
        />
      ) : null}
      {erro.isWrong === true ? (
        <WeexModal
          fecharModalCallback={callbackModal}
          titulo={erro.titulo}
          conteudo={erro.mensagem}
        />
      ) : null}
      <InteresseFormStyle>
        <form>
          <div className="interesse-container">
            {props.canSelectInterets ? osInteresses : osInteressesDesabilitados}
          </div>
					
          <hr />
        </form>
        {
          props.requiredInterests === true && props.beginDate <= props.now ? 
            (
              <div style={{marginBottom:'2rem'}} className="interesse-btn-container">
                <BotaoPrimario
                  nome={salvando.isSaved === false ? (i18n.message("interesse.salvar", "SALVAR")) : (i18n.message("interesse.salvando", "SALVANDO..."))}
                  disabled={props.canSelectInterets ? false : true}
                  funcao={confirmacaoDeEnvioDeInteresses}
                  ariaStatusMessage={i18n.message("aria.status.mensagemreutilizavel.botaosalvarclicado", "Botão salvar clicado.")}
                />
              </div>
            )
            :
            (
              <div style={{marginBottom:'2rem'}} className="interesse-btn-container">
                <BotaoPrimario
                  nome={salvando.isSaved === false ? (i18n.message("interesse.salvar", "SALVAR")) : (i18n.message("interesse.salvando", "SALVANDO..."))}
                  disabled={!props.canSelectInterets}
                  funcao={submitInteresse}
                  ariaStatusMessage={i18n.message("aria.status.mensagemreutilizavel.botaosalvarclicado", "Botão salvar clicado.")}
                />
              </div>
            )
        }
      </InteresseFormStyle>
    </>
  );
};

export default Interesse;
