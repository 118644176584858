import React, { Component } from 'react';
import InputMask from 'react-input-mask';

export default class WeexInput extends Component {

    screenWidth = () => {
      return window.screen.width;
    }

    classImputMask = () => {
      if (parseInt(this.screenWidth()) > parseInt(768)) {
            
        return "input input-size is-medium"
      }
      return "input"
    }


    inputNumber = () => {
      return (<input step="1" id={this.props.id} className="input input-size is-medium" min="14" max="100" type="number" name={this.props.name} placeholder={this.props.placeholder} onChange={this.props.onChange} required={this.props.required}/>)
    }

    inputPadrao = () => {
      return (<input id={this.props.id} className="input input-size is-medium" type={this.props.type} name={this.props.name} placeholder={this.props.placeholder} onChange={this.props.onChange} required={this.props.required}/>)
    }

    inputPadraoWithIcone = (password) => {
      return (
        <div className="control has-icons-left has-icons-right">
          <input id={this.props.id} className="input input-size is-medium" type={password} name={this.props.name} placeholder={this.props.placeholder} onChange={this.props.onChange} required={this.props.required}/>
          <span className='icon is-small is-right'>
            <i className={this.props.icon}></i>
          </span>
        </div>
      )
    }

    inputListaNumero = () => {
      let min = this.props.min
      let max = this.props.max
      let itens = []
      for (var i = min; i <= max; i++) {
        itens.push((<option key={"value_option_" + i} value={i} selected={this.props.value && this.props.value === i}>{i}</option>))
      }
      return (
        <select className="select-input input is-medium" required={this.props.required} name={this.props.name} onChange={this.props.onChange}>
          {itens}
        </select>
      )
    }

    render() {
      let input = null
      switch (this.props.type) {
      case 'listaNumero':
        input = this.inputListaNumero()
        break
      case 'number':
        if (this.props.max && this.props.min) {
          input = this.inputNumber()
        } else {
          input = this.inputPadrao()
        }
        break
      case 'textIcon':
        input = this.inputPadraoWithIcone()
        break
      case 'passwordIcon':
        input = this.inputPadraoWithIcone('password')
        break
      case 'emailIcon':
        input = this.inputPadraoWithIcone('email')
        break
      default:
        input = this.inputPadrao()
            
      }

      return (
        <div className="field" style={{marginBottom: '1rem'}}>
          <label htmlFor={this.props.id} className="label" aria-label={this.props.ariaLabel}>{this.props.label}</label>
          <div className="control">
            {this.props.mascara ? (
              <div>
                <InputMask placeholder={this.props.placeholder} className={this.classImputMask()} mask={this.props.mascara} maskChar={null} name={this.props.name} onChange={this.props.onChange} required={this.props.required} aria-describedby={this.props.ariaDescribedby}/>
                {this.props.legenda ? (
                  <div>
                    <span className="legenda-input">{this.props.legenda}</span>
                  </div>
                ) : (null)}
              </div>
            ) : (
              input
            )}
          </div>
        </div>
      )
    }
}
