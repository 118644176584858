import React, { Component } from 'react';
import { animations } from 'react-animation';
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { connect } from 'react-redux';
import { Link } from "react-router-dom";
import BotaoPrimario from '../../comps/botaoPrimario/BotaoPrimario';
import CampanhaService from '../../services/CampanhaService';
import TemaService from '../../services/TemaService';
import TrofeuService from '../../services/TrofeuService';
import ChangingProgressProvider from "./ChangingProgressProvider";
import { EstanteStyle, MedalhaAtividadeStyle, TrofeuDaCampanhaContainerStyle, TrofeusStyle } from './style';


class TrofeuCampanha extends Component {
  render() {
    let i18n = this.props.i18n
    if (this.props.url === null) {
      return (
        <div className="trofeu__sala trofeu__sala__campanha">
        </div>    
      );
    }

    return (
      <div className='trofeu-width-campanha' style={{ marginBottom: '45px', animation: animations.popIn}}>
        <ChangingProgressProvider values={[0, this.props.porcentagemFinal]} repeat={false}>
          {porcentagemFinal => (
            <CircularProgressbarWithChildren
              value={porcentagemFinal}
              strokeWidth={2}
              styles={{
                path: {
                  stroke: porcentagemFinal === '100' ? '#00E291' : `#847D92`,
                  strokeLinecap: 'round',
                  transition: 'stroke-dashoffset 0.5s ease 0s',
                },
                trail: {
                  stroke: '#DFDFDF',
                },
              }}
            >
              <div className="trofeu__sala__campanha">
                <img src={this.props.url} alt={i18n.message("alt.trofeu.campaign", "Imagem da taça de conclusão da campanha")}/>                 
                <div aria-hidden="true" style={{display:'flex', flexDirection: 'column', alignItems:'center', justifyContent:'center', marginTop: '10px'}}>
                  <p>{`${porcentagemFinal}%`}</p>
                  <p>{this.props.concluido}</p>
                </div>
                <span className='visually-hidden'>{`${porcentagemFinal}% ${this.props.concluido}`}</span>
              </div>
            </CircularProgressbarWithChildren>
          )}
        </ChangingProgressProvider>
      </div>
    )
  }
}

class Trofeu extends Component {
    bgImage = {
      backgroundImage: 'url(' + this.props.url + ')'
    };

    render() {
      let i18n = this.props.i18n
        
      if (this.props.url === null) {
        return (
          <div>
          </div>    
        );
      }

      let nomeAtividade = "";

      switch (this.props.type){
      case 'VIDEO':
        nomeAtividade = i18n.message("dinamica.video.nome", "Vídeo")
        break;
      case 'FACT_OR_FAKE':
        nomeAtividade = i18n.message("dinamica.fatofake.nome", "Fato ou Fake?")
        break;
      case 'NEW_HABIT':
        nomeAtividade = i18n.message("dinamica.novoshabitos.nome", "Novos Hábitos")
        break;
      case 'GAME':
        nomeAtividade = i18n.message("dinamica.game.nome", "Game")
        break;
      case 'EXTRA':
        nomeAtividade = i18n.message("dinamica.extra.nome", "Extra")
        break;
      case 'MURAL':
        nomeAtividade = i18n.message("dinamica.mural.nome", "Mural")
        break;
      default:
        nomeAtividade = ""
                
      }
      let altImageTrofeuDia = this.props.achieved ? i18n?.message("alt.trofeu.day.able", "Imagem do troféu diário da campanha concluído"): i18n?.message("alt.trofeu.day.disable", "Imagem do troféu diário da campanha não concluído")
      let altImageAtividade = this.props.activityAchieved && this.props.activityAchieved === true ? i18n?.message("alt.trofeu.atividade.able", "Completou") : i18n?.message("alt.trofeu.atividade.disable", "Não completou")
      return (
        <>
          {
            this.props.maior ?
              (
                <div className={`${ `trofeu__sala__semana ${this.props.achieved ? 'trofeu__sala__semana_able' : 'trofeu__sala__semana_disable'}`}`}>
                  <img src={this.props.url} alt={altImageTrofeuDia}/>
                </div>
              ) :
              (
                <MedalhaAtividadeStyle activityAchieved={this.props.activityAchieved} style={this.props.size}>
                  <Link to="/atividades">
                    <div className='check'>
                      <img src='/positivo_tres.svg' alt={altImageAtividade}></img>
                    </div>
                    <div className='medalha'>
                      <div style={this.bgImage} className="trofeu__sala card-atividades-trofeu__imagem">
                      </div>
                    </div>
                    <div style={{marginTop: '5px', marginBottom: '5px', marginRight: '2px', marginLeft: '2px'}} aria-describedby={`${nomeAtividade}`}>
                      <p className="label-atividades">
                        {nomeAtividade}
                      </p>
                    </div>
                  </Link>
                </MedalhaAtividadeStyle>
              )
          }
        </>
      )
    }
}

class Estante extends Component {

    act = {
      activities: []
    }

    constructor(props){
      super(props)
      let acts = []
      this.props.dia.activities.forEach(element => {
        let act = this.buscarTrofeu(element.type, element.activityId)
        if(act){
          acts.push(act)
        }
      })
      this.act.activities = acts;
    }

    buscarTrofeu(tipo, activityId){
      let atividade = null;
      for (var i = 0; i < this.props.dia.activities.length; i++) {
        if (this.props.dia.activities[i].type === tipo && this.props.dia.activities[i].activityId === activityId) {
          atividade = this.props.dia.activities[i]
          break;
        }
      }
      return atividade
    }

    trofeusChunks = (activities, chunks) =>{
      let final = [];
      let n = activities.length;
      for( let i = 0; i < n; i += chunks) {
        final = [...final, activities.slice(i, i + chunks)];
      }
      return final;
    }

      css = {
        backgroundImage: 'url(' + this.props.dia.prize + ')'
      };

    
      render() {
        const dia = this.props.dia

        let i18n = this.props.i18n

        let dataTextual = dia.day.split(" ");
        let componentesData = dataTextual[0].split("-");
        let componentesDataFinal = componentesData[2] + "." + componentesData[1]

        const  mesTextual = i18n.getNomeMesInteiro(componentesData[1])

        let trofeusResults = this.trofeusChunks(this.act.activities, 5);
        let finalTrofeus = trofeusResults.map((items, key, index) => {
          let primeiroOuNao = items.length === 1 ? 21.5 : 20
          return(
            <div
              key={key}
              className='trofeus-estante'
              style={{ width: primeiroOuNao * items.length + "%"}}
            >
              {
                items.map(function (el, index) {
                  return (
                    <Trofeu
                      i18n={i18n}
                      key={index}
                      url={el.prize}
                      activityAchieved={el.achieved}
                      type={el.type}
                      size={{ width: 100 / items.length -3 + "%"}}
                    />
                  );
                })
              }
            </div>
          )
        })

        return (
          <div className='trofeu-agenda-desktop' style={{marginTop: '50px', marginBottom: '50px'}}>
            <EstanteStyle achieved={dia.achieved} style={{animation: animations.popIn}}>
              <div className='header-trofeu-container'>   
                <div className="trofeuDia">
                  <Trofeu url={dia.prize} maior={true} achieved={dia.achieved} i18n={i18n}/>
                  <p aria-hidden="true">{componentesDataFinal}</p>
                  <span className='visually-hidden'>{`${componentesData[2]} ${mesTextual}`}</span>
                </div>
              </div>
              <div style={{width: "82%", display: 'inline', textAlign: 'center'}}>
                {finalTrofeus}
              </div>
            </EstanteStyle>
          </div>
        );
        
      }
}

class Trofeus extends Component {

    state = {
      trofeus:null,
      erro:null,
      campanha:null
    }

    componentDidMount() {
      new TrofeuService().obterTrofeus((erro, dados) => {
        if (erro) {
          this.setState({erro:"Erro ao obter sala de troféus, tente novamente mais tarde"})
          return
        }
            
        let campanhaService = new CampanhaService()
        this.setState({trofeus:dados, campanha:campanhaService.campanhaCorrente()})
        let temaService = new TemaService(campanhaService.campanhaCorrente())
        temaService.aplicarTema('HOME')
      })
    }

   svg =() => {
     return (
       <svg width="27" height="18" viewBox="0 0 27 18" fill="none" xmlns="http://www.w3.org/2000/svg">
         <path d="M8.54417 7.06465H7.43087V5.9514C7.43087 5.52709 7.08684 5.18311 6.66248 5.18311C6.23812 5.18311 5.89408 5.52709 5.89408 5.9514V7.06465H4.78083C4.35647 7.06465 4.01244 7.40863 4.01244 7.83294C4.01244 8.25725 4.35647 8.60123 4.78083 8.60123H5.89413V9.71443C5.89413 10.1387 6.23817 10.4827 6.66253 10.4827C7.08689 10.4827 7.43092 10.1387 7.43092 9.71443V8.60128H8.54423C8.96859 8.60128 9.31262 8.2573 9.31262 7.83299C9.31257 7.40863 8.96853 7.06465 8.54417 7.06465Z" fill="#FE001A"/>
         <path fillRule="evenodd" clipRule="evenodd" d="M16.9234 7.83298C16.9234 6.2236 18.2328 4.91431 19.8423 4.91431C21.4517 4.91431 22.7611 6.22355 22.7612 7.83298C22.7612 9.4423 21.4518 10.7516 19.8423 10.7516C18.2328 10.7516 16.9234 9.4423 16.9234 7.83298ZM18.4602 7.83293C18.4602 8.59497 19.0802 9.21491 19.8423 9.21491C20.6044 9.21491 21.2244 8.59497 21.2244 7.83293C21.2244 7.07088 20.6044 6.45089 19.8423 6.45089C19.0802 6.45089 18.4602 7.07088 18.4602 7.83293Z" fill="#FE001A"/>
         <path fillRule="evenodd" clipRule="evenodd" d="M26.1512 11.9689L24.6743 3.9479C24.3246 2.04904 22.6728 0.670898 20.7465 0.670898H18.0339C17.8493 0.670898 17.6708 0.73739 17.5312 0.85813L15.9707 2.20799H10.2571L8.69658 0.85813C8.55693 0.737339 8.37851 0.670898 8.19389 0.670898H5.48131C3.55499 0.670898 1.90315 2.04904 1.55353 3.94784L0.0766239 11.9689C-0.37427 14.4174 1.2098 16.7751 3.64407 17.2756C5.35119 17.6266 7.07839 16.9898 8.15173 15.6136L9.83882 13.4506H16.3891L18.0762 15.6136C19.143 16.9814 20.8662 17.6287 22.5838 17.2756C25.0124 16.7763 26.603 14.4226 26.1512 11.9689ZM22.2743 15.7705C21.1438 16.003 19.9993 15.5807 19.2879 14.6686L17.3701 12.2098C17.2245 12.0231 17.001 11.9139 16.7642 11.9139H9.46359C9.22682 11.9139 9.00327 12.0231 8.85768 12.2098L6.93987 14.6686C6.22844 15.5807 5.0841 16.003 3.95353 15.7705C2.34062 15.4389 1.28833 13.8744 1.58795 12.2472L3.06486 4.22605C3.28021 3.0564 4.29644 2.20748 5.48125 2.20748H7.90759L9.46815 3.55735C9.60779 3.67814 9.78621 3.74458 9.97083 3.74458H16.2568C16.4414 3.74458 16.6199 3.67809 16.7595 3.55735L18.3201 2.20748H20.7464C21.9312 2.20748 22.9474 3.0564 23.1628 4.22605L24.6398 12.2471C24.9392 13.8729 23.8886 15.4386 22.2743 15.7705Z" fill="#FE001A"/>
       </svg>
     )
   }

   render() {
     let i18n = this.props.i18n
     if (this.state.erro) {
       return (<div>{this.state.erro}</div>)
     }
     if (! this.state.trofeus) {
       return (<div style={{display:'flex', alignItems:'center', justifyContent:'center', minHeight:'100vh' }}><div>{i18n.message("geral.aguarde", "Aguarde...")}</div></div>)
     }
     const trofeus = this.state.trofeus

     let numeroTotalDeAtividades = 0;
     let numeroDeAtividadesFeitas = 0;
     for(let i = 0; i < trofeus.schedule.length; i++) {
       let activitiesSize = trofeus.schedule[i]
       numeroTotalDeAtividades += activitiesSize.activities.length;
       for(let j = 0; j < activitiesSize.activities.length; j++){
         if(activitiesSize.activities[j].achieved === true) {
           numeroDeAtividadesFeitas++
         }
       }
     }

     let porcentagemAtividadesFeitas = numeroDeAtividadesFeitas * 100 / numeroTotalDeAtividades
     let porcentagemFinal = porcentagemAtividadesFeitas.toFixed(0)
     let completedColor = porcentagemFinal === '100' ? '#00E291' : '#847D92';
     return (
       <TrofeusStyle className='full-width-desktop'>
         <section className="dinamica-content-desktop">
           <h3>{i18n.message("trofeus.titulo", "TROFÉUS")}</h3>
           <h4>{i18n.message("trofeus.subtitulo", "Aqui você encontra todas as suas conquistas!")}</h4>
           <div className='trofeu-content-desktop'>
             <TrofeuDaCampanhaContainerStyle completedColor={completedColor}>
               <TrofeuCampanha
                 url={trofeus.prize}
                 porcentagemFinal={porcentagemFinal}
                 concluido={i18n.message("geral.porcentagem.concluido", "Concluído")}
                 i18n={i18n}
               />
               {this.state.campanha.showRankingGame ? (
                 <BotaoPrimario
                   ariaDisabled="true"
                   ariaHidden="true"
                   tabIndex="-1"
                   nome={i18n.message("trofeu.ranking.game", "VER RANKING DO GAME")}
                   funcao = {() => this.props.history.push('/gameRankings')}
                   ariaStatusMessage={i18n.message("aria.status.trofeus.botaoverrankinggame", "Botão ver ranking game clicado.")}
                 >
                   {this.svg()}
                 </BotaoPrimario>):null
               }
                                

             </TrofeuDaCampanhaContainerStyle>
                        
             <div className='trofeu-agenda'>
               {
                 trofeus.schedule.map((est, index) => {return (<Estante key={index} dia={est} i18n={i18n} />)})
               }
             </div>

           </div>
                    
         </section>

       </TrofeusStyle>
     )
   }
    
}
const mapStateToProps = function(state) {
  return {
    i18n:state.i18n
  }
}

export default connect(mapStateToProps)(Trofeus)

