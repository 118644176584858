import instance from './Api';
import AgendaService from './AgendaService'

export default class AtividadesService {

    agendaService = new AgendaService()

    iniciarAtividade(codigo, tipo, callback) {
      instance.post(this.host() + '/api/v1/campaign/schedule/' + codigo + '/activity/' + tipo)
        .then((response) => {
          callback(null, response.data)
        })
        .catch((error) => {
          if(error.status === 500){
            instance.post(this.host() + '/api/v1/campaign/schedule/' + codigo + '/activity/' + tipo)
              .then((response) => {
                callback(null, response.data)
              })
              .catch((error) => {
                callback(error, null)
              })
          }
          callback(error, null)
        })
    }

    finalizarAtividade(codigo, codigoAtividade, tipo, corpo, callback) {
      instance.put(this.host() + '/api/v1/campaign/schedule/' + codigo + '/activity/' + codigoAtividade, corpo)
        .then((response) => {
          this.agendaService.atualizarAgenda(codigo, codigoAtividade, tipo, corpo.numeroQuestoes, corpo.score, (erro, sucesso) => {
            if(sucesso){
              if(tipo === 'FACT_OR_FAKE'){
                this.registrarPontuacao(codigo, tipo, corpo, (erro, sucesso) => {
                  if(sucesso){
                    callback(null, response.data)
                  } else if(erro){
                    callback("Falha ao atualizar informações de atividade executadas, entre em contato com o suporte", null)
                  }
                })
              }else{
                callback(null, response.data)
              }
            } else if(erro){
              callback("Falha ao atualizar informações de atividade executadas, entre em contato com o suporte", null)
            }
          })
        })
        .catch((error) => {
          callback(error, null)
        })
    }

    registrarPontuacao(codigo, tipo, corpo, callback) {
      instance.post(this.host() + '/api/v1/score', corpo)
        .then((response) => {
          callback(null, response.data)
        })
        .catch((error) => {
          callback(error, null)
        })
    }

    host() {
      return process.env.REACT_APP_HOST_API
    }

}
