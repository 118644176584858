import styled from "styled-components";

export const DinamicasHeaderStyle = styled.header`
    margin-top: 3em;

    h3{
        color: ${props => { return props.theme.cardLogadoTextoTerceiro }};
    }

	h3{
		color: ${props => { return props.theme.cardLogadoTextoTerceiro }};
		text-align: center;
		text-transform: uppercase;
		font-weight: 700;
		font-size: 16px;
		line-height: 15px;
		margin-top: 20px;
		margin-bottom: 10px;
	}

    .color-fundo-secundaria{
	color: ${props => { return props.theme.fundoSecundario || `#533888` }};
}


.input-mural{
    border: none;
    padding: 0.8em;
    width: -webkit-fill-available;
}


.display-none{
    display: none;
}

.span-icon{
    position: absolute;
    display: inline !important;
    margin-left: 0.5em;
    margin-top: 0.3em;
}

.input-wrap{
    position: relative;
}

.icone-lupa{


}



.input-mural::placeholder{

    text-align: center;

    background-image: "lupa-busca.jpg";
}


    .dinamica-nome {
        text-transform: none;
        display: flex;
        justify-content: center;
        align-items: center;
        color: ${props => { return props.theme.cardLogadoTextoPrimeiro }};

        span{
            margin-left: 10px;
            margin-top: 5px;
        }
    }

    .dinamica-img{
        max-height: 23px;
        max-width: 29px;
    }


    @media screen  and (min-width: 700px) {

        .dinamica-img{
            max-height: 10vh;
            max-width: 16vw;
        }

        .dinamica-nome {
            justify-content: center;
            margin-left: 1em;
        }

        .dinamica-title-desktop{
            font-size: 1.3em;
            line-height: 20px;
            text-align: center;
        }

    }
`
