import React from "react";
import { BotaoFeedbackStyle } from './style';

function BotaoFeedback(props) {
  return (
    <div>
      <BotaoFeedbackStyle
        className={props.disabled === true ? `${props.classeComplementar} 'disabled'` : `${props.classeComplementar}`}
        onClick={props.funcao}
        aria-disabled={props.disabled === true ? "true":"false"}
      >
        {props.nome}
      </BotaoFeedbackStyle>
    </div>
  )
}

export default BotaoFeedback