import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useStore } from 'react-redux';
import { Link, useHistory, useLocation } from "react-router-dom";
import BotaoAsALinkIconeEsquerda from '../../comps/botaoAsALink/BotaoAsAlinkWithIconLeft';
import BotaoPrimario from "../../comps/botaoPrimario/BotaoPrimario";
import BotaoSecundario from '../../comps/botaoSecundario/BotaoSecundario';
import DinamicasHeader from "../../comps/dinamicasHeader/DinamicasHeader";
import { DinamicasHeaderStyle } from "../../comps/dinamicasHeader/style";
import Publicacao from '../../comps/publicacao/PublicacaoMural';
import EscreverPublicacao from '../../comps/weexModais/EscreverPublicacao.js';
import ModalComentarios from '../../comps/weexModais/ModalComentarios.js';
import WeexSpinner from "../../comps/WeexSpinner/WeexSpinner";
import AgendaService from '../../services/AgendaService';
import GameService from '../../services/GameService';
import MuralService from '../../services/MuralService';
import { MuralStyled } from './MuralStyled';
function Mural(props) {

  const i18n = useStore().getState().i18n;
  const muralService = new MuralService();
  let location = useLocation();
  const pageSize = 30;
  const codigoAgenda = props.match.params.codigo;
  const codigoAtividade = props.match.params.codigoAtividade;
  const codigo = props.match.params.codigo;
  const history = useHistory()
  const mensagemExcluir = i18n.message("dinamica.mural.publicacao.excluir", "Tem certeza que deseja excluir essa publicação?")
  const dropdown = i18n.message("dinamica.mural.publicacao.dropdown", "Excluir publicação")
  const mensagemErroImagem = i18n.message("dinamica.mural.publicacao.imagem.erro", "Não foi possível processar a imagem enviada. Em caso de dúvida entre em contato com o suporte.")
  const mensagemProcessando = i18n.message("dinamica.mural.publicacao.imagem.processando", "Estamos processando sua imagem, aguarde alguns instantes e atualize esta página.")
  const ariaTextAreaPublicacao = i18n.message("aria.label.escrever.publicacao", "Escreva sua publicação")
  const ariaButtonComentario = i18n.message("aria.label.mural.button.comentario", "Ao clicar abre o modal de comentários")
  const ariaLabelButtonCurtida = i18n.message("aria.label.mural.button.curtida", "clique aqui para curtir ou descurtir a publicação")
  const imagemPublicacaoMural = i18n.message("alt.mural.imagem.publicada", "Imagem da publicação")
  const [publicacao, setPublicacao] = useState([]);
  const [publicacoesExibidas, setPublicacoesExibidas] = useState([]);
  const [processando, setProcessando] = useState(false);
  const [paginaAtual, setPaginaAtual] = useState(0);
  const [totalPaginas, setTotalPaginas] = useState(10);
  const [totalPaginasCarregadas, setTotalPaginasCarregadas] = useState(0);
  const [name, setName] = useState("");
  const [modalEscrita, setModalEscrita] = useState(false);
  const [modalComentarios, setModalComentarios] = useState(false);
  const [, setComentarios] = useState([]);
  const [uuidPublicacao, setUuidPublicacao] = useState("");
  const [timeout, setTimeout] = useState(false);
  const isDesktopMode = useSelector((state) => state.isDesktopMode);


  const buscar = useCallback(() => {
    let request = {
      pageSize: pageSize
    };

    setProcessando(true);

    muralService.buscarPublicacoes(codigo, codigoAtividade, request, (_erro, publicacao) => {
      if (publicacao) {
        setPublicacao(publicacao.publicacoes);
        setPublicacoesExibidas(publicacao.publicacoes);
        setTotalPaginas(Math.ceil(publicacao.totalElements / pageSize));
        setPaginaAtual(0);
        setTotalPaginasCarregadas(0);
        window.scroll(0, 0);
        setProcessando(false);
      }
    });
  }, [codigo, codigoAtividade, muralService]);


  useEffect(() => {
    new GameService().iniciarGame(codigo, codigoAtividade, (erro, action) => {
      if (action) {
        new AgendaService().atualizarAgenda(
          codigo,
          codigoAtividade,
          "MURAL",
          null,
          null,
          (errorAtualizarAgenda, _sucesso) => {
            if (errorAtualizarAgenda) {
              alert(
                i18n.message(
                  "dinamica.erro.jaexecutada.mensagem",
                  "Falha ao atualizar informações de atividade executadas, entre em contato com o suporte"
                )
              );
            }
          }
        );
      }
      if (erro) {
        alert("Não foi possível fechar a ação do mural. Entre em contato com o suporte!");
      }
    });

    if (publicacao.length === 0) {
      buscar();
    }
    // TODO: Ao colocar as dependencias solicitadas pelo Lint, a aplicação fica em loop infinito no useEffect --> realacionada tarefa 15106 --> By Fabio
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const irParaOTopoDoMural = () => {
    let elementsWithScroll = document.querySelectorAll('.publicacoes-content');
    elementsWithScroll.forEach((element) => {
      if (isDesktopMode) {
        element.scrollTop = 0;
      } else {
        element.scrollIntoView({ behavior: 'auto', block: 'start' });
      }
    });
  };
  const abrirModalEscrita = () => {
    setModalEscrita(true);
  };

  const fecharModalEscrita = () => {
    setModalEscrita(false);
  };

  const abrirModalComentarios = () => {
    setModalComentarios(true);
  };

  const fecharModalComentarios = () => {
    setModalComentarios(false);
  };

  const buscarComentarios = (uuid, request) => {
    const service = new MuralService();

    service.buscarComentarios(uuid, request, (erro, sucess) => {
      if (sucess) {
        setComentarios(sucess);
        setUuidPublicacao(uuid);
        abrirModalComentarios();
      }
      if (erro) {
        alert(JSON.stringify(erro));
      }
    });
  };

  const curtir = (like, uuid) => {
    const service = new MuralService();
    let tipo;
    if (like) {
      tipo = "ADD";
    } else {
      tipo = "REMOVE";
    }
    let request = {
      tipo: tipo,
    };

    service.curtirOuDescurtirPublicacao(uuid, request, (erro, _sucess) => {
      if (erro) {
        console.log(erro);
      }
    });
  };

  const coluna1 = (publicacoes) => {
    return publicacoes.map((pub, index) => {
      if (index % 2 === 0) {
        return pub;
      }
      return null;
    });
  };

  const coluna2 = (publicacoes) => {
    return publicacoes.map((pub, index) => {
      if (index % 2 === 1) {
        return pub;
      }
      return null;
    });
  };

  const gerarColunas = (publicacoes) => {
    return (
      <>
        <div className="coluna-1-publicacao">{coluna1(publicacoes)}</div>
        <div className="coluna-2-publicacao">{coluna2(publicacoes)}</div>
      </>
    );
  };

  const gerarPublicacaoDesktop = () => {
    let publicacoes = [];

    publicacoesExibidas.forEach((publicacao) => {
      let publicacaoComponent = {
        ...publicacao,
        name: publicacao.autor,
        data: publicacao.data,
        imagem: publicacao.urlImagemCode,
        conteudo: publicacao.postagem,
        isPublicacao: true,
        tempo: publicacao.tempoPublicacao,
        uuid: publicacao.uuid,
        qtdComentarios: publicacao.comentarios,
        curtiu: publicacao.curtida,
        doUsuario: publicacao.publicacaoDoUsuario,
        erroImagem: publicacao.erroImagem,
        processando: publicacao.processando,
        qtLikes: publicacao.qtLikes,
        fix: publicacao.fix,
        urlVideo: publicacao.urlVideo
      };
      publicacoes.push(
        <div className="publicacoes-conteudo" key={publicacaoComponent.uuid}>
          <Publicacao
            key={publicacaoComponent.uuid}
            teste={publicacao}
            elemento={publicacaoComponent}
            comentarios={buscarComentarios}
            curtir={curtir}
            deletar={deletarPublicacao}
            msgExcluir={mensagemExcluir}
            dropdown={dropdown}
            msgErroImagem={mensagemErroImagem}
            msgProcessando={mensagemProcessando}
            i18n={i18n}
            ariaLabelButtonComentario={ariaButtonComentario}
            ariaLabelButtonCurtida={ariaLabelButtonCurtida}
            imagemPublicacaoMural={imagemPublicacaoMural}
            isPublicacao={publicacaoComponent.isPublicacao}
          />
        </div>
      );
    });

    return gerarColunas(publicacoes);
  };


  const gerarPublicacaoMobile = () => {
    return publicacoesExibidas.map((publicacao) => {
      let publicacaoComponent = {
        ...publicacao,
        name: publicacao.autor,
        data: publicacao.data,
        imagem: publicacao.urlImagemCode,
        conteudo: publicacao.postagem,
        isPublicacao: true,
        tempo: publicacao.tempoPublicacao,
        uuid: publicacao.uuid,
        qtdComentarios: publicacao.comentarios,
        curtiu: publicacao.curtida,
        doUsuario: publicacao.publicacaoDoUsuario,
        erroImagem: publicacao.erroImagem,
        processando: publicacao.processando,
        qtLikes: publicacao.qtLikes,
        urlVideo: publicacao.urlVideo
      };

      return (
        <div className="publicacoes-conteudo" key={publicacaoComponent.uuid}>
          <Publicacao
            key={publicacaoComponent.uuid}
            teste={publicacao}
            elemento={publicacaoComponent}
            comentarios={buscarComentarios}
            curtir={curtir}
            deletar={deletarPublicacao}
            msgExcluir={mensagemExcluir}
            dropdown={dropdown}
            msgErroImagem={mensagemErroImagem}
            msgProcessando={mensagemProcessando}
            i18n={i18n}
            ariaLabelButtonComentario={ariaButtonComentario}
            ariaLabelButtonCurtida={ariaLabelButtonCurtida}
            imagemPublicacaoMural={imagemPublicacaoMural}
            isPublicacao={publicacaoComponent.isPublicacao}
          />
        </div>
      );
    });
  };


  const publicacoesMobileOrDesktop = () => {
    if (isDesktopMode) {
      return (
        <div className="publicacao-mural-content-page">
          {gerarPublicacaoDesktop()}
        </div>
      );
    } else {
      return gerarPublicacaoMobile();
    }
  };

  const deletarPublicacao = (uuid) => {
    let publicacoes = publicacao.filter(
      (publicacao) => publicacao.uuid !== uuid
    );
    setPublicacao(publicacoes);
    setPublicacoesExibidas(publicacoes);
  };


  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      alterarNomeBusca(event);
    }
  };

  const alterarNomeBusca = (e) => {
    setName(e.target.value);
    if (timeout) {
      clearTimeout(timeout);
      setTimeout(false);
    }

    const newTimeout = setTimeout(() => {
      setPaginaAtual(0);
      const request = {
        name: name,
        page: paginaAtual,
      };
      setProcessando(true);
      muralService.buscarPublicacoes(
        codigoAgenda,
        codigoAtividade,
        request,
        (erro, publicacao) => {
          if (publicacao) {
            setPublicacao(publicacao.publicacoes);
            setPublicacoesExibidas(publicacao.publicacoes);
            setTotalPaginas(Math.ceil(publicacao.totalElements / pageSize));
            setTotalPaginasCarregadas(1);
            setProcessando(totalPaginas);
            console.log(publicacao);
          } else {
            alert(JSON.stringify(erro));
          }
        }
      );
    }, 1000);

    setTimeout(newTimeout);
  };


  const proximaPagina = () => {
    const codigo = props.match.params.codigo;
    const codigoAtividade = props.match.params.codigoAtividade;
    const service = new MuralService();
    let resquest = {
      name: name,
      page: paginaAtual + 1,
      pageSize: pageSize
    };

    if (totalPaginasCarregadas >= paginaAtual + 1) {
      let ultimoElementoExibido = publicacoesExibidas[publicacoesExibidas.length - 1];
      let indexUltimoElementoExibido = publicacao.findIndex((publicacao) => publicacao.uuid === ultimoElementoExibido.uuid);
      setPublicacoesExibidas(publicacao.slice(indexUltimoElementoExibido + 1, indexUltimoElementoExibido + 1 + pageSize));
      setPaginaAtual(prevPaginaAtual => prevPaginaAtual + 1);
      irParaOTopoDoMural();
    } else {
      setProcessando(true);
      service.buscarPublicacoes(codigo, codigoAtividade, resquest, (_erro, publicacao) => {
        if (publicacao) {
          setPublicacao(prevPublicacao => [...prevPublicacao, ...publicacao.publicacoes]);
          setPublicacoesExibidas(publicacao.publicacoes);
          setTotalPaginas(Math.ceil(publicacao.totalElements / pageSize));
          setPaginaAtual(prevPaginaAtual => prevPaginaAtual + 1);
          setTotalPaginasCarregadas(prevTotalPaginasCarregadas => prevTotalPaginasCarregadas + 1);
          irParaOTopoDoMural();
          setProcessando(false);
        }
      });
    }
  };

  const paginaAnterior = () => {
    console.log(paginaAtual);
    if (paginaAtual > 1) {
      irParaOTopoDoMural();
      const primeiroElementoListaExibida = publicacoesExibidas[0];
      const index = publicacao.indexOf(primeiroElementoListaExibida);
      console.log('index', index);
      console.log('publicacao', publicacao);
      if (index > 0) {
        setPaginaAtual((prevPaginaAtual) => prevPaginaAtual - 1);
        const indexInicial = publicacao.length > pageSize ? index - pageSize : 0;
        setPublicacoesExibidas(publicacao.slice(indexInicial, index));
      }
    } else {
      buscar();
      setPaginaAtual(0);
    }
  };



  const createPublicacao = (request) => {
    const service = new MuralService();

    service.criarPublicacao(codigoAgenda, codigoAtividade, request, (erro, sucess) => {
      if (sucess) {
        buscar();
        fecharModalEscrita();
        window.scrollTo(0, 0);
      }
      if (erro) {
        alert(JSON.stringify(erro));
      }
    });
  };


  return (
    <MuralStyled>
      <div className="full-width-desktop">
        <div className="dinamica-content-desktop">
          <div className="div-close">
            <Link
              to="/"
              className="close-btn img-close"
              aria-label={i18n.message("aria.label.fechar.modal", "Fecha a janela e volta para a página atividades.")}
            >
              <img
                src="/close-btn.png"
                alt={i18n.message("alt.button.imagem.fechar", "ícone em formato de um X indicando o encerramento de uma ação.")}
              />
            </Link>
          </div>
          <div className='botao-voltar-desktop'>
            <div className="botao-voltar">
              <BotaoAsALinkIconeEsquerda
                icon="fas fa-arrow-left "
                classeComplementar="header-como-funciona-desktop-botao"
                nome={i18n.message("geral.voltar", "Voltar")}
                ariaLabelMsg={i18n.message("aria.label.geral.mensagem.voltar.atividades", "Volta para a página de atividades.")}
                funcao={() => {
                  history.push("/home")
                }}
              />
            </div>
          </div>
          {modalEscrita && (
            <EscreverPublicacao
              ariaTextArea={ariaTextAreaPublicacao}
              fecharModalCallback={fecharModalEscrita}
              create={createPublicacao}
              isPublicacao={true}
            />
          )}

          {modalComentarios ?
            (<ModalComentarios
              fecharModalCallback={fecharModalComentarios}
              publicacao={uuidPublicacao}
              imagemPublicacaoMural={imagemPublicacaoMural}
              titulo="COMENTÁRIOS"
            >
            </ModalComentarios>) : ""}
          <div className="desktop-content">
            <div className="desktop-content-left">
              <DinamicasHeaderStyle>
                <DinamicasHeader
                  dinamica={{}}
                  theme={{}}
                  dinamicaNome={i18n.message("dinamica.mural.nome", "Mural Social")}
                  dinamicaAlt={i18n.message("alt.dinamica.mural", "Ícon de balões de conversao estilo gibi.")}
                  dinamicaImg={location && location.state && location.state.icon ? location.state.icon : "/new_novos_habitos.svg"}
                />
                <div className="input-wrap ">
                  <div className="field">
                    <div className="control has-icons-left has-icons-right">
                      <input className="input" value={name} onKeyPress={handleKeyPress} onChange={alterarNomeBusca} placeholder={i18n.message("dinamica.mural.busca.placeholder", "Nome do autor da publicação")} />
                      <span className="icon is-small is-left">
                        <i className="fas fa-search" aria-label={i18n.message("aria.label.icone.busca.publicacao", "Icone de buscar publicação")}></i>
                      </span>
                    </div>
                  </div>
                </div>
              </DinamicasHeaderStyle>
              <div className="botao-publicacao-desktop">
                <BotaoPrimario
                  className="my-4 "
                  nome={i18n.message("dinamica.mural.publicar", "ESCREVER PUBLICAÇÃO")}
                  funcao={abrirModalEscrita}
                  ariaStatusMessage={i18n.message("aria.status.mural.botaoescreverpublicacao", "Botão escrever publicação clicado.")}
                />
              </div>
            </div>
            {processando === true ? (
              <div className="spinner">
                <WeexSpinner
                  aguarde={i18n.message(
                    "geral.carregando.publicações",
                    "Carregando  publicações..."
                  )}
                />
              </div>

            ) : (
              <div className="desktop-content-right">
                <div className="publicacoes-content">
                  {publicacoesMobileOrDesktop()}
                </div>
                {
                  publicacao.length > 0 ? (<div className="buttom-page" >

                    <div className="buttom-page__buttom">
                      <BotaoSecundario
                        nome={i18n.message("dinamica.mural.anterior", "Anterior")}
                        funcao={paginaAnterior}
                        disabled={paginaAtual === 0}
                        classeComplementar="button"
                        ariaLabelMsg={i18n.message("aria.label.geral.mensagem.pagina.anterior", "Página anterior.")}

                      />
                    </div>
                    <div className="buttom-page__buttom">
                      <p>
                        {paginaAtual + 1} / {totalPaginas}
                      </p>
                    </div>

                    <div className="buttom-page__buttom">
                      <BotaoSecundario
                        nome={i18n.message("dinamica.mural.proxima", "Próxima")}
                        funcao={proximaPagina}
                        disabled={paginaAtual + 1 === totalPaginas}
                        classeComplementar="button"
                        ariaLabelMsg={i18n.message("aria.label.geral.mensagem.proxima.pagina", "Próxima página.")}

                      />
                    </div>
                  </div>) : ""
                }
                <div className="botao-publicacao-mobile" >
                  <BotaoPrimario
                    className="my-4 "
                    nome={i18n.message("dinamica.mural.publicar", "ESCREVER PUBLICAÇÃO")}
                    funcao={abrirModalEscrita}
                  />
                </div>
              </div>)}

          </div>
        </div>

      </div>
    </MuralStyled>
  );

}


export default Mural;
