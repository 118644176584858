import React from 'react';

function Clean(props) {

  caches.keys().then((names) => {
    for (let name of names) {
      caches.delete(name)
    }
  })

  return (<div>Cache limpo</div>)
}

export default Clean;