import React from 'react';
import { useStore } from "react-redux";
import { Link } from "react-router-dom";

function SuspendedMenuMobile({
  matricula,
  tipoInscricao,
  legalTerm,
  notLegalTerm,
  requiredInterests,
  locationPathname,
  beginDate,
  now,
  toggle,
  comoFunciona,
  sair,
  versao,
}) {
  const i18n = useStore().getState().i18n;

  return (
    <>
      <div className='weex-header-menu'>
        <div className='weex-header-menu-content'>
          <ul>
            <li> 
              <p style={{pointerEvents:'none',fontSize:'0.75em'}}>
                {`${i18n.message("geral.menu.usuario", "Usuário")}  : ${matricula}`}
              </p>
            </li>

            { 
              (tipoInscricao === "ELEGIBILIDADE" && legalTerm && !notLegalTerm)
                            || (requiredInterests === true && locationPathname === '/interests' && beginDate <= now) ? null :
                (
                  <li>
                    <Link to="/" onClick={toggle}>{i18n.message("geral.menu.inicio", "Início")}</Link>
                  </li>
                )
            }
            <li>
              {
                comoFunciona && comoFunciona.helpMessage ?
                  (
                    <Link to="/help" onClick={toggle}>{i18n.message("geral.menu.comoFunciona", "Como funciona")}</Link>
                  )
                  :
                  ""
              }
                            
            </li>
            <li>
              <button className='button-link' type='button' onClick={sair}>{i18n.message("geral.menu.sair", "Sair")}</button>
            </li>
            <li>
              <p className='versao'>{`v. ${versao}`}</p>
            </li>
          </ul>
        </div>
      </div>
    </>
  )
}

export default SuspendedMenuMobile;