import styled from "styled-components";

export const ComoFuncionaStyle = styled.div`

@media screen  and (min-width: 700px) {
    .header-como-funciona{
		display: flex;
    	align-items: flex-start;
		background-size: 30% 70%;
		background-repeat: no-repeat;
		background-position: center bottom;
		padding: 3vw 0;
	}

}

`