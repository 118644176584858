import React from "react";

function WeexProgressBar(props) {
  const { bgColor, completed } = props;

  const containerStyles = {
    marginTop: '10px',
    height: 2,
    width: '100%',
    backgroundColor: "#e0e0de",
    borderRadius: 50,
  }
  
  const fillerStyles = {
    height: '100%',
    width: `${completed}%`,
    backgroundColor: bgColor,
    borderRadius: 'inherit',
    textAlign: 'right',
    transition: 'width 2s ease-in-out',
  }
  
  return (
    <div style={containerStyles}>
      <div style={fillerStyles}>
      </div>
    </div>
  );
}

export default WeexProgressBar;