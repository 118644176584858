import React from 'react';
import { connect } from 'react-redux';
import AtividadeEntity from '../../comps/DiaSemanaAgenda/AtividadeEntity';
import Gamer from '../../comps/svg/gamer';
import { GameAgendaBlockStyle, GameAgendaStyle } from './GameAgendaStyle';

class GameAgenda extends React.Component {

    i18n = this.props.i18n;
    altImageCompletou = this.i18n.message("alt.atividade.completou","completou");
    altImageNaoCompletou = this.i18n.message("alt.atividade.nao.completou","Não completou");

    gamesRender() {
      let gamesRender = [];
      let games = [];
      if (this.props.games.length > 0) {
        games = this.props.games;
        gamesRender = games.map((game, index) => {
          const gameRender = new AtividadeEntity(game);
          return (
            <div key={gameRender.uuid} aria-hidden="true">
              <div style={{ opacity: game.executed ? `1` : `0`, position: 'relative', top: '12px', left: '0px', right: '0px'}}>
                <img src='/positivo_tres.svg' alt={game.executed ? this.altImageCompletou : this.altImageNaoCompletou}></img>
              </div>
              <GameAgendaStyle
                style={{ border: game.executed ? '1px solid #00E291' : '' }}
                onClick={gameRender.navegar}
                title='Clique para jogar o jogo'
                tabIndex="-1"
                aria-hidden="true"
                aria-disabled="true"
              >
                <span className='icon is-large'>
                  <Gamer
                    label={this.i18n.message("aria.game.icone","Ícone de um controle de videogame.")}
                  />
                </span>
                <span className='texto-habilitado'>
                  {this.i18n.message("game.jogar.habilitado", "Que tal uma dose de diversão?")}
                </span>
              </GameAgendaStyle>
            </div>
          );
        });

      }
      return gamesRender;
    }

    gamesBlockRender() {
      return (
        <GameAgendaBlockStyle
          disabled
          aria-disabled="true"
          aria-hidden="true"
        >
          <span
            className='icon is-large icon-disabled'
            aria-disabled="true"
            aria-hidden="true"
          >
            <Gamer/>
          </span>
          <p
            className='texto-habilitado'
            aria-disabled="true"
            aria-hidden="true"
          >
            {this.i18n.message("game.jogar.desabilitado", "Conclua as atividades para desbloquear")}
          </p>
        </GameAgendaBlockStyle>
      );

    }

    gamesHeaderRender() {
      if (this.props.atividades.length > 0) {
        let atividades = [...this.props.atividades];
        const isEnable = atividades.reduce((isEnable, a) => {
          const atividade = new AtividadeEntity(a);
          return atividade.executed && isEnable;
        }, true);
        if (isEnable) {
          return this.gamesRender();
        } else {
          return this.props.games.length > 0 ? this.gamesBlockRender() : null;
        }
      }
    }

    render() {
      return (
        <div className='games-apresentacao'>
          {this.gamesHeaderRender()}
        </div>
      );
    }
}


const mapStateToProps = function (state) {
  return {
    i18n: state.i18n
  }
}

export default connect(mapStateToProps)(GameAgenda)
