import styled from "styled-components";
export const ExtraStyle = styled.div`
	width: 100%;
	iframe#iframe-extra{
		width: 90vw;
		height: 68vh;
		margin: auto;
		text-align: center;
    }

    .card-content{
        padding-left: 0px;
        padding-right: 0px;
    }
    .columns{
        margin: 0px;
    }
    .weex-container{
        padding-left: 0px ;
        padding-right: 0px;
    }

    .text-align{
        text-align: center;
    }

    extra-iframe {
        margin-top: 2em;
		text-align: center;
    }

	.react-pdf__Page__canvas{
		margin: auto;
	}

	.is-full{
		width: 100%;
	}

    @media (min-width: 768px) {
        .desktop-voltar{
            display: block;
            margin: 1em;
            min-width:${props => props.tamanho || null}px;
        }
        .card-content{
            padding: 0;
        }
		.extra-iframe {
		text-align: center;
    }
	iframe#iframe-extra{
		width: 55vw;
		height: 68vh;
		margin: auto;
    }
    }
`
