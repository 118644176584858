import React, { useRef, useState } from "react";
import { animations } from "react-animation";
import { useStore } from "react-redux";
import { WeexModalDialog } from "./WeexModalDialog";
import BotaoAsALinkIconeEsquerda from "../botaoAsALink/BotaoAsAlinkWithIconLeft";

/**
 * @param {Object} props
 * @param {JSX.Element} props.diaSelecionadoAtividades
 * @param {function(): void} props.fecharModalCallback
 * @param {function(event): void} props.onClick_BotaoDiaAnterior
 * @param {function(event): void} props.onClick_BotaoProximoDia
 */
function WeexModalAtividadesDia({
  fecharModalCallback,
  diaSelecionadoAtividades,
  onClick_BotaoDiaAnterior,
  onClick_BotaoProximoDia
}) {
  const [mostrarModal, setMostrarModal] = useState(true)
  const dialogRef = useRef(null)
  const i18n = useStore().getState().i18n;

  function fecharModal() {
    fecharModalCallback();
    dialogRef.current.close()
    setMostrarModal(false)
  };

  function diaAnterior(event) {
    onClick_BotaoDiaAnterior(event)
  }

  function proximoDia(event) {
    onClick_BotaoProximoDia(event)
  }

  return (
    mostrarModal ? (
      <WeexModalDialog
        onPressESC={fecharModal}
        dialogRef={dialogRef}
      >
        <div
          className={"modal is-active"}
          style={{
            animation: animations.popIn, 
            zIndex: "102" 
          }}
        >
          <div className="modal-background weex-modal-background"></div>
          <div className="modal-card weex-modal-card">
            <header className="modal-card-head weex-modal-card-header header-atividades">
              <button
                onClick={fecharModal}
                className="close-btn"
                aria-label={i18n.message("aria.label.fechar.modal", "Fecha a janela e volta para a página atividades.")}
              >
                <img
                  src="/close-btn.png"
                  alt={i18n.message("alt.button.imagem.fechar", "ícone em formato de um X indicando o encerramento de uma ação.")}
                />
              </button>
            </header>
            <section 
              className="modal-card-body has-text-centered atividade-section"
              role="alert"
            >
              {diaSelecionadoAtividades}
            </section>
            <BotoesNavegacaoAtividadeDia 
              onClick_BotaoDiaAnterior={diaAnterior}
              onClick_BotaoProximoDia={proximoDia}
            />
          </div>
        </div>
      </WeexModalDialog>
    ) : <></>
  );
}

/**
 * @param {Object} props
 * @param {function(event): void} props.onClick_BotaoDiaAnterior
 * @param {function(event): void} props.onClick_BotaoProximoDia
 */
function BotoesNavegacaoAtividadeDia({
  onClick_BotaoDiaAnterior,
  onClick_BotaoProximoDia
}) {

  const i18n = useStore().getState().i18n

  return (
    <div 
      style={{
        display: "flex",
        gap: "3.6rem",
        margin: "10px"
      }}
    >
      <BotaoAsALinkIconeEsquerda
        nome={i18n.message("weexmodalatividadedia.botao.diaanterior","Dia Anterior")}
        disabled={false}
        ariaDisabled={false}
        ariaHidden={false}
        classeComplementar=""
        icon="fas fa-chevron-left"
        ariaLabelMsg={i18n.message("arialabel.botao.weexmodalatividadedia.diaanterior", "ir para o dia anterior")}
        ariaStatusMessage="Botão clicado"
        funcao={onClick_BotaoDiaAnterior}
      />
      <BotaoAsALinkIconeEsquerda
        nome={i18n.message("weexmodalatividadedia.botao.proximodia","Próximo dia")}
        disabled={false}
        ariaDisabled={false}
        ariaHidden={false}
        classeComplementar=""
        iconRight="fas fa-chevron-right"
        ariaLabelMsg={i18n.message("arialabel.botao.weexmodalatividadedia.proximodia", "ir para o proximo dia")}
        ariaStatusMessage="Botão clicado"
        funcao={onClick_BotaoProximoDia}
      />
    </div>
  )
}

export default WeexModalAtividadesDia;
