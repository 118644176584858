import CampanhaService from './CampanhaService';

const campanhaService = new CampanhaService();
export default class TemaService{

  constructor(campanha) {
    if (campanha && campanha.styles) {
      this.styles = campanha.styles
    } else {
      this.styles = []
    }
    this.isDesktopModel = campanhaService.isDesktopModel();
    this.imagemDeFundo = null;
  }

  getStyle(pagina) {
    if (! pagina) {
      return null
    }
    if (this.styles && this.styles.length > 0) {
      for (var i = 0; i < this.styles.length; i++) {
        if (this.styles[i].page === pagina) {
          return this.styles[i]
        }
      }
    }
    return null
  }

  temBackGround(){
    return this.imagemDeFundo
  }

  aplicarTema(pagina) {
    let tema = this.getStyle(pagina)
    var element = document.querySelector(".weex")
    if (tema) {

      if (!this.isDesktopModel){

        if (tema.backgroundImage) {
          if (element) {
            element.style.backgroundImage = "url(" + tema.backgroundImage + ")";
            element.style.backgroundSize = "100% auto";
            element.style.backgroundRepeat = 'no-repeat';
            if(tema.backgroundColor) {
              element.style.backgroundColor = tema.backgroundColor
            }
          }
          this.imagemDeFundo = true
        } else if (!tema.backgroundImage && tema.backgroundColor) {
          if (element) {
            element.style.background = tema.backgroundColor
          }
        }
      }else{
        if (tema.backgroundImageDesktop) {
          if (element) {
            element.style.backgroundImage = "url(" + tema.backgroundImageDesktop + ")";
            element.style.backgroundSize = "100% auto";
            element.style.backgroundRepeat = 'no-repeat';
            if(tema.backgroundColor) {
              element.style.backgroundColor = tema.backgroundColor
            }
          }
          this.imagemDeFundo = true
        } else if(!tema.backgroundImageDesktop && tema.backgroundColor) {
          if (element) {
            element.style.background = tema.backgroundColor
          }
        }
      }
    } else {
      element.style.removeProperty('background-image')
    }
  }

  removerTema(){
    var element = document.querySelector(".weex")
    element.style.removeProperty('background-image')
  }

}
