import instance from './Api';

export default class ExtraService {

  iniciarConteudoExtra(code, codigoAtividade, callback) {
    //finalize=true indica que a API deve iniciar e na sequência já finalizar a campanha
    //isso é importante para as seguintes atividades: game e extras
    //devido as suas características específicas essas atividades tem de ser finalizadas logo após iniciadas
    instance.post(this.host() + '/api/v1/campaign/schedule/' + code + '/activity/' + codigoAtividade + "?finalize=true")
      .then((response) => {
        callback(null, response.data)
      })
      .catch((error) => {
        callback(error, null)
      })
  }

  /**
 * Obtém o conteúdo extra para um participante e atividade específicos.
 *
 * @param {string} participant - O UUID do participante.
 * @param {string} atividade - O UUID da atividade.
 * @returns {Promise} Uma Promise contendo o resultado da requisição HTTP.
 */
  getConteudoExtra(participant, atividade){
    return instance.get(`${this.host()}/api/v1/campaign/schedule/${participant}/extra/${atividade}`, { responseType: 'blob' } )
  }

  host() {
    return process.env.REACT_APP_HOST_API
  }

}
