import React from 'react';
import { useStore, useSelector } from "react-redux";
import Menu from '../menu/Menu';
import SuspendedMenuMobile from '../menu/SuspendedMenuMobile';
import Burguer from './Burguer';
import NavbarElement from './NavbarElement';

function Navbar({
  style,
  styleElement,
  toggle,
  toggleMenu,
  logoCentroAreaLogada,
  logoCentro,
  logoCentroAreaLogadaDesktop,
  logoCentroDesktop,
  notLogoCentroAreaLogada,
  notLogoCentroAreaLogadaDesktop,
  title,
  titleVisible,
  campanhaName,
  showTrophy,
  usuarioLogado,
  matricula,
  tipoInscricao,
  legalTerm,
  notLegalTerm,
  requiredInterests,
  locationPathname,
  beginDate,
  now,
  comoFunciona,
  sair,
  versao,
}) {
  const i18n = useStore().getState().i18n;
  const isDesktopMode = useSelector((state) => state.isDesktopMode);
  return (
    <>
      <nav style={style}>
        { 
          !isDesktopMode && (
            <Burguer
              toggle={toggle}
              toggleMenu={toggleMenu}
              altOpen={i18n.message("alt.menu.burguer.mobile.open", "Menu suspenso, aberto.")}
              altClose={i18n.message("alt.menu.burguer.mobile.close", "Menu suspenso, fechado.")}
            />)
        }

        { isDesktopMode ? (
          <>
            {logoCentroAreaLogadaDesktop ?
              (
                <NavbarElement
                  to={usuarioLogado ? "/home":"/"}
                  className="campanha_logosTopo__centro"
                  src={logoCentroAreaLogada}
                  alt={i18n.message("alt.logo.principal.mobile", "Logo principal da empresa")}
                  style={styleElement}
                  srcDesktop={logoCentroAreaLogadaDesktop}
                  altDesktop={i18n.message("alt.logo.principal.desktop", "Logo principal da empresa")}
                />
              ) :
              (
                <>
                  {
                    logoCentroDesktop && notLogoCentroAreaLogadaDesktop ?
                      (
                        <NavbarElement
                          to={usuarioLogado ? "/home":"/"}
                          className="campanha_logosTopo__centro"
                          src={logoCentro}
                          alt={i18n.message("alt.logo.principal.mobile", "Logo principal da empresa")}
                          srcDesktop={logoCentroDesktop}
                          altDesktop={i18n.message("alt.logo.principal.desktop", "Logo principal da empresa")}
                        />
                      ) : ""
                  }
                </>
              )
            }

          </>
        ):(
          <>
            {logoCentroAreaLogada ?
              (
                <NavbarElement
                  to={usuarioLogado ? "/home":"/"}
                  className="campanha_logosTopo__centro"
                  src={logoCentroAreaLogada}
                  alt={i18n.message("alt.logo.principal.mobile", "Logo principal da empresa")}
                  style={styleElement}
                  srcDesktop={logoCentroAreaLogadaDesktop}
                  altDesktop={i18n.message("alt.logo.principal.desktop", "Logo principal da empresa")}
                />
              ) :
              (
                <>
                  {
                    (logoCentro && notLogoCentroAreaLogada)  ?
                      (
                        <NavbarElement
                          to={usuarioLogado ? "/home":"/"}
                          className="campanha_logosTopo__centro"
                          src={logoCentro}
                          alt={i18n.message("alt.logo.principal.mobile", "Logo principal da empresa")}
                          srcDesktop={logoCentroDesktop}
                          altDesktop={i18n.message("alt.logo.principal.desktop", "Logo principal da empresa")}
                        />
                      ) : ""
                  }
                </>
              )}
          </>
        )

        }
        {
          isDesktopMode ?
            (
              <Menu
                title={title}
                titleVisible={titleVisible}
                campanhaName={campanhaName}
                matricula={matricula}
                tipoInscricao={tipoInscricao}
                legalTerm={legalTerm}
                notLegalTerm={notLegalTerm}
                requiredInterests={requiredInterests}
                locationPathname={locationPathname}
                beginDate={beginDate}
                now={now}
                toggle={toggle}
                comoFunciona={comoFunciona}
                sair={sair}
                versao={versao}
                inicio={usuarioLogado ? "/home":"/"}
              />
            ) : (
              ""
            )
        }
        {
          showTrophy && (
            <NavbarElement
              to="/trophy"
              className="sala-de-trofeus"
              src="/trofeu_weex3.png"
              alt={i18n.message("alt.menu.sala.de.trofeus", "Ícone com acesso para a sala de troféus")}
            />
          )
        }
      </nav>
      {
        !isDesktopMode && toggleMenu === true ?
          (
            <SuspendedMenuMobile
              matricula={matricula}
              tipoInscricao={tipoInscricao}
              legalTerm={legalTerm}
              notLegalTerm={notLegalTerm}
              requiredInterests={requiredInterests}
              locationPathname={locationPathname}
              beginDate={beginDate}
              now={now}
              toggle={toggle}
              comoFunciona={comoFunciona}
              sair={sair}
              versao={versao}
            />
          ) : ""
      }

    </>
  )
}

export default Navbar;
