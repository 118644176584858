import React, { useEffect, useState } from "react";
import { animations } from "react-animation";
import { useStore } from "react-redux";
import { useHistory } from "react-router-dom";
import BotaoFeedback from "../../comps/botaoFeedback/BotaoFeedback";
import DiaSemanaAgenda from "../../comps/DiaSemanaAgenda/DiaSemanaAgenda";
import MensagemVideoModal from "../../comps/weexModais/MensagemVideoModal";
import WeexModalAtividades from "../../comps/weexModais/WeexModalAtividades";
import WeexModalAtividadesDia from "../../comps/weexModais/WeexModalAtividadesDia";
import WeexSpinner from "../../comps/WeexSpinner/WeexSpinner";
import AgendaService from "../../services/AgendaService";
import CampanhaService from "../../services/CampanhaService";
import FeedbackService from "../../services/FeedbackService";
import LoaderService from "../../services/LoaderService";
import MensagemVideoService from "../../services/MensagemVideoService";
import TemaService from "../../services/TemaService";
import UsuarioService from "../../services/UsuarioService";
import { AtividadesStyle } from "./style";

function Atividades() {
  const i18n = useStore().getState().i18n;
  const isDesktop = useStore().getState().isDesktopMode;
  const [feedback, setFeedback] = useState(null);
  const usuarioService = new UsuarioService();
  const campanhaService = new CampanhaService();
  const agendaService = new AgendaService();
  const mensagemVideoService = new MensagemVideoService();
  const history = useHistory();
  const [campanha] = useState(campanhaService.campanhaCorrente());
  const [diasSemana, setDiasSemana] = useState();
  const [processando, setProcessando] = useState(false);
  const [mensagemDeVideo, setMensagemDeVideo] = useState([]);
  const [erro, setErro] = useState(null);
  const [apresentacao, setApresentacao] = useState("");
  const [apresentacaoCapa, setApresentacaoCapa] = useState("");
  const [rota, setRota] = useState("");
  const [codigoAgenda, setCodigoAgenda] = useState("");
  const [codigoAtividade, setCodigoAtividade] = useState("");
  const [atvName, setAtvName] = useState("");
  const [atvTitle, setAtvTitle] = useState("");
  const [iconImg, setIconImg] = useState("");
  const [mostrarModal, setMostrarModal] = useState(false)
  const [mostrarModalVideo, setMostrarModalVideo] = useState(false)
  const [diaSelecionado, setDiaSelecionado] = useState(false);
  const [diaSelecionadoAtividades, setDiaSelecionadoAtividades] = useState(null);
  const usuario = usuarioService.usuarioCorrente();

  /* TODO:
    esses dois estados abaixo são consequência do débito tecnológico causado pela função
    jsxDia, que pode ser abstraída para um componente react com o nome de por exemplo CardDia.
    Devido ao alto acoplamento dessa função (Que não é nem a menos um componente react), não
    foi possível realizar essa refatoração na tarefa 15062.

    Componentizar a página atividades já é crucial e importante, pois o componente se já encontra enorme e
    bem rígido para novas implementações.import UsuarioService from '../../services/UsuarioService';

  */
  const [agendaSchedule, setAgendaSchedule] = useState([])
  const [diaSelecionadoIndex, setDiaSelecionadoIndex] = useState(null)

  const callbackFeedback = (error, result) => {
    if (error) {
      setFeedback(null);
    } else {
      setFeedback(result);
    }
  };

  function navegar(
    rota,
    codigoAgenda,
    codigoAtividade,
    imagem,
    apresentacao,
    apresentacaoCapa,
    atvName,
    atvTitle,
    iconImg
  ) {
    if (apresentacao != null) {
      setApresentacao(apresentacao);
      setApresentacaoCapa(apresentacaoCapa);
      setRota(rota);
      setCodigoAgenda(codigoAgenda);
      setCodigoAtividade(codigoAtividade);
      setAtvName(atvName);
      setAtvTitle(atvTitle);
      setIconImg(iconImg);
      //TODO: setar minha boolean pra verdadeiro
      setMostrarModal(true)
    } else {
      history.push("/" + rota + "/" + codigoAgenda + "/" + codigoAtividade);
    }
    new LoaderService().load(imagem);
  }

  function irParaDinamica() {
    history.push({
      pathname: "/" + rota + "/" + codigoAgenda + "/" + codigoAtividade,
      state: {
        icon: iconImg,
      },
    });
  }

  function adicionarHistoricoNavegacao() {
    if (mensagemDeVideo && mensagemDeVideo[0]?.video.code) {
      history.push("/video-message/" + mensagemDeVideo[0].video.code);
    }
  }

  function handleMensagemDeVideo() {
    setMensagemDeVideo(false)
  }

  function ocultarPopup() {
    //TODO: setar minha boolean pra falso
    setMostrarModal(false)
    // setApresentacao("");
  }

  useEffect(() => {
    setProcessando(true);

    if(!usuario.legalTerm && campanha.legalTerm){
      history.push("/accept");
    }
    let service = new TemaService(campanhaService.campanhaCorrente());
    service.aplicarTema("HOME");

    mensagemVideoService.obterMensagemDeVideo((error, sucesso) => {
      if (error) {
        setErro(
          i18n.message(
            "atividades.erro.video.prefixo",
            "Erro ao obter mensagem de video da campanha: "
          ) + erro
        );
        return;
      }
      if (sucesso) {
        setMensagemDeVideo(sucesso);
        sucesso.length > 0
          ? setMostrarModalVideo(true)
          : setMostrarModalVideo(false)
      }
    });
    const feedbackService = new FeedbackService();
    if (feedbackService.feedbackHabilitado()) {
      feedbackService.feedbackRespondido(callbackFeedback);
    }
    agendaService.agendaCorrente((erro, agenda) => {
      if (agenda && !diasSemana) {
        campanhaService.contadorCampanha((erro, sucesso) => {
          if (erro) {
            setErro(
              i18n.message(
                "atividades.erro.dias.habilitados",
                "Erro ao obter a lista de dias habilitados da campanha: "
              ) + erro
            );
            history.push("/");
            return;
          }
          if (sucesso) {
            setProcessando(false);

            let dias = agenda.schedule.map((dia, index) => {
              return jsxDia(agenda.schedule, index)
            });
            setDiasSemana(dias);
            setAgendaSchedule(agenda.schedule)
            if (campanha.requiredInterests && !agenda.pickedInterests) {
              history.push("/interests");
            }
          }
        });
      }
      if (erro) {
        history.push("/");
      }
    });
    // TODO: Ao colocar as dependencias solicitadas pelo Lint, a aplicação fica em loop infinito no useEffect --> realacionada tarefa 14797 --> By Renan
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function mostrarAtivididadesModal(listaDias, indexDia) {
    let numeroItensPorLinha = getNumeroItensPorLinha(listaDias[indexDia]);
    setDiaSelecionado(true);
    setDiaSelecionadoAtividades(jsxDia(listaDias, indexDia, true, numeroItensPorLinha));
    setDiaSelecionadoIndex(indexDia)
  }

  function getNumeroItensPorLinha(dia) {
    let numeroItensPorLinha = 0;
    let maiorNumeroDeAtividades = 0;

    dia.subtitles.forEach((subtitle) => {
      const atividades = dia.activities.filter((atividade) => atividade.type !== 'GAME' && atividade.subtitle.uuid === subtitle.uuid);
      const numAtividades = atividades.length;

      numeroItensPorLinha = Math.max(numeroItensPorLinha, numAtividades > 4 ? 5 : 0);
      maiorNumeroDeAtividades = Math.max(maiorNumeroDeAtividades, numAtividades);
    });

    if (dia.subtitles.length === 0) {
      const numAtividades = dia.activities.length;
      numeroItensPorLinha = numAtividades > 4 ? 5 : numAtividades;
    }

    return numeroItensPorLinha > 0 ? numeroItensPorLinha : maiorNumeroDeAtividades;
  }



  function jsxDia(listaDias, indexDia, padrao, numeroItensPorLinha) {
    let diaEnabled;
    const checkStartDaCampanha = campanhaService.obterCheckStartDaCampanha();
    let atividadesDiferentesDeGame = listaDias[indexDia].activities.filter((atividade) => atividade.type !== 'GAME');
    //configuração temporária para validar campanha, se for campanha accenture e tiver mais de 4 atividades diferentes de game, mostra o modal
    if (atividadesDiferentesDeGame.length > 4 && !padrao && isDesktop && validarCampanha()) {
      return (
        <button onClick={() => mostrarAtivididadesModal(listaDias, indexDia)} className="button-link" aria-label={i18n.message("aria.label.modal.atividade", "Abra o modal que contém todas as atividades.")} >
          <div key={listaDias[indexDia].scheduleCode} className="atv-card-desktop">
            {checkStartDaCampanha?.forEach((diaHab) => {
              if (diaHab === listaDias[indexDia].day) {
                diaEnabled = true;
              }
            })}
            <div style={{ animation: animations.popIn }}>
              <DiaSemanaAgenda
                dia={listaDias[indexDia]}
                diaEnabled={diaEnabled}
                navegar={navegar}
                key={listaDias[indexDia].scheduleCode}
                mensagemDeVideo={mensagemDeVideo}
                evento={campanha}
                desabilitarButton={true}
                numeroItensPorLinha={numeroItensPorLinha}
              />
            </div>
          </div>
        </button>
      );
    } else {
      return (
        <div key={listaDias[indexDia].scheduleCode} className="atv-card-desktop">
          {checkStartDaCampanha?.forEach((diaHab) => {
            if (diaHab === listaDias[indexDia].day) {
              diaEnabled = true;
            }
          })}
          <div style={{ animation: animations.popIn }}>
            <DiaSemanaAgenda
              dia={listaDias[indexDia]}
              diaEnabled={diaEnabled}
              navegar={navegar}
              key={listaDias[indexDia].scheduleCode}
              mensagemDeVideo={mensagemDeVideo}
              evento={campanha}
              numeroItensPorLinha={numeroItensPorLinha}
            />
          </div>
        </div>
      );
    }
  }

  //configuração temporária para validar campanha
  function validarCampanha() {
    if(campanha){
      if(campanha.code.toLowerCase().includes('accenture')){
        return true
      }
    }
    return false
  }


  let botaoFeedback = null;
  if (feedback && !feedback.answered) {
    botaoFeedback = (
      <div
        className="botao-feedback"
      >
        <BotaoFeedback
          nome={i18n.message(
            "feedback.acao",
            "Conte-nos sobre sua experiência"
          )}
          funcao={() => {
            history.push("/feedback");
          }}
        />{" "}
      </div>
    );
  }
  return (
    <AtividadesStyle>
      {mostrarModalVideo && (
        <MensagemVideoModal
          fecharModalCallback={handleMensagemDeVideo}
          adicionarHistoricoNavegacao={adicionarHistoricoNavegacao}
          uuid={mensagemDeVideo[0]?.uuid}
          titulo={mensagemDeVideo[0]?.title}
          imagemSrc={mensagemDeVideo[0]?.image}
          mensagem={mensagemDeVideo[0]?.message}
          tipo={mensagemDeVideo[0]?.type}
        />
      )}{" "}
      {mostrarModal ? (
        <WeexModalAtividades
          fecharModalCallback={ocultarPopup}
          irParaDinamica={irParaDinamica}
          rota={rota}
          conteudo_dangerouslySetInnerHTML={apresentacao}
          imagemSrc={apresentacaoCapa}
          atvName={atvName}
          atvTitle={atvTitle}
        />
      ) : null}{" "}
      {
        diaSelecionado && (
          <WeexModalAtividadesDia
            fecharModalCallback={() => setDiaSelecionado(false)}
            diaSelecionadoAtividades={diaSelecionadoAtividades}
            onClick_BotaoDiaAnterior={() => {
              diaSelecionadoIndex < 1
                ? mostrarAtivididadesModal(agendaSchedule, agendaSchedule.length - 1)
                : mostrarAtivididadesModal(agendaSchedule, diaSelecionadoIndex -1)
            }}
            onClick_BotaoProximoDia={() => {
              diaSelecionadoIndex >= agendaSchedule.length - 1
                ? mostrarAtivididadesModal(agendaSchedule, 0)
                : mostrarAtivididadesModal(agendaSchedule, diaSelecionadoIndex + 1)
            }}
          />
        )
      }
      {processando === true ? (
        <WeexSpinner
          aguarde={i18n.message(
            "geral.carregando.atividades",
            "Carregando atividades..."
          )}
        />
      ) : (
        <>
          <div className={`atividades-header`}>
            <h3> {i18n.message("atividades.titulo", "Atividades")} </h3>{" "}
            <h4>
              {" "}
              {i18n.message(
                "atividades.subtitulo",
                "Para realizar as atividades, clique nas dinâmicas dos dias que já estão disponíveis"
              )}
              :{" "}
            </h4>{" "}
          </div>{" "}
          <div className="agenda-container"> {diasSemana} </div> {botaoFeedback}{" "}
        </>
      )}{" "}
    </AtividadesStyle>
  );
}

export default Atividades;
